import Routing from "./Routing"
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import { Toaster } from 'sonner';
import "./App.css"

const App = () => {

  if (process.env.NODE_ENV === 'production') {
    disableReactDevTools();
  }
  
  return (
    <>
    {
      <Routing/>  
    }
        
        <Toaster position="top-center" richColors />
    </>
  )
}

export default App