import { configureStore } from "@reduxjs/toolkit";
import  friendsLedgerSlice  from "./slices/FriendsLedgerSlice"; 
import shoppingLedgerSlice from "./slices/ShoppingLedgerSlice";
import StickyNotesSlice from "./slices/StickyNotesSlice";
import billTrackerSlice from "./slices/BillTrackerSlice";
import getAuthSlice from "./slices/auth";
import getUsersSlice from "./slices/users";

const store = configureStore({
    reducer: {
        friendsLedgers: friendsLedgerSlice,
        shoppingLedgers: shoppingLedgerSlice,
        stickyNotes: StickyNotesSlice,
        billTracker: billTrackerSlice,
        userAuth: getAuthSlice,
        allUsers: getUsersSlice,
    },
    devTools: false,  
})

export default store;