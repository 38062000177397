import { useLayoutEffect } from "react";
import "./CSS/About.css"
import { Link } from "react-router-dom";
import {  GoArrowRight  } from "react-icons/go";
import { FaGithub, FaLinkedin, FaInstagram, FaFacebook , FaSquareXTwitter    } from "react-icons/fa6";
import { Helmet } from 'react-helmet';
import { useDispatch } from "react-redux";
import {getUser} from "../../store/slices/auth"

const About = () => {
    const dispatch = useDispatch()

    useLayoutEffect(() =>{
        dispatch(getUser())
  },[dispatch]) 

  return (
    <div className="about_container px-lg-5 px-md-4 mb-5">
        <Helmet>
            <link rel="canonical" href="https://friendsledger.com/about"/>
            <title>About</title>
        </Helmet>

        {/* what is friends ledger */}
        <div className="container-fluid about_headings mt-lg-4 mb-5" >
            <h1 className="text-center" > What is Friends ledger? </h1>  
            <div className="underline_sap" ></div>
        </div>
        <div className="container-fluid px-3 px-lg-5 text-center my-4 fs-5">
            <p className="">
                A "friends ledger" is a digital or modern system for tracking transactions when lending or borrowing money from friends. It's a way to maintain a record of who owes money to whom and can help in keeping financial matters clear between friends.
            </p>
        </div>

        {/* why friends ledger */}
        <div className="container-fluid about_headings mt-5 mb-4" >
            <h1 className="text-center" > Why Friends ledger? </h1>  
            <div className="underline_sap" ></div>
        </div>
        <div className="container-fluid px-4 mb-lg-0 mb-5 d-flex justify-content-around align-items-center flex-wrap">

            {/* <div className="reasons_card my-lg-5 my-4 p-4">
            </div> */}

            <div className="reasons_cards d-lg-flex p-2">
                <div className="col-lg-2 col-sm-12">
                    <img src="/images/ledger-logo.png"
                      alt="" />
                </div>
                <div className="col-lg-10 ps-2 col-sm-12">
                    <h4>Maintain Clarity</h4>
                    <p> 
                        It helps maintain clarity in financial matters among friends. It eliminates misunderstandings and keeps a record of who owes money to whom.
                    </p>
                </div>
            </div>

            <div className="reasons_cards d-lg-flex p-2">
                <div className="col-lg-2  col-sm-12">
                    <img src="/images/Convenience.png"
                      alt="convenience" />
                </div>
                <div className="col-lg-10 ps-2 col-sm-12">
                    <h4>Convenience</h4>
                    <p> 
                    It offers a convenient and digital way to manage these transactions. Users can access their ledger from anywhere with an internet connection.
                    </p>
                </div>
            </div>

            <div className="reasons_cards d-lg-flex p-2">
                <div className="col-lg-2 col-sm-12">
                    <img src="/images/customization.png"
                      className=" "  alt="customization" />
                </div>
                <div className="col-lg-10 ps-2 col-sm-12">
                    <h4>Customization</h4>
                    <p> 
                    Users can customize their ledgers by adding descriptions, specifying reasons for transactions, and choosing themes, making the process more personal.
                    </p>
                </div>
            </div>

            <div className="reasons_cards d-lg-flex p-2">
                <div className="col-lg-2 col-sm-12">
                    <img src="/images/share.png"
                      alt="shareing" />
                </div>
                <div className="col-lg-10 ps-2 col-sm-12">
                    <h4>Sharing and Collaboration</h4>
                    <p> 
                    Friends Ledger enables sharing of ledgers with others. It promotes transparency and collaboration.
                    </p>
                </div>
            </div>

            <div className="reasons_cards d-lg-flex  p-2">
                <div className="col-lg-2 col-sm-12 mb-sm-4">
                    <img src="/images/shake_hands.png"
                     alt="user friendly" />
                </div>
                <div className="col-lg-10 ps-2 col-sm-12">
                    <h4>User-Friendly</h4>
                    <p> 
                    The app is designed to be user-friendly, making it accessible for people who may not be finance-savvy.
                    </p>
                </div>
            </div>

            <div className="reasons_cards d-lg-flex p-2">
                <div className="col-lg-2  col-sm-12 mb-sm-4">
                    <img src="/images/privacy.png"
                      className=" "  alt="privacy" />
                </div>
                <div className="col-lg-10 ps-2 col-sm-12">
                    <h4>Privacy</h4>
                    <p> 
                    While promoting transparency, Friends Ledger also respects user privacy. Users can control who sees their ledgers and share them only with trusted individuals.
                    </p>
                </div>
            </div>

        </div>

        {/* Vision, mission and goal */}
        <div className="container-fluid about_headings mt-lg-3 mt-5 mb-lg-5 mb-0" >
            <h1 className="text-center" > Our Vision And Mission </h1>  
            <div className="underline_sap" ></div>
        </div>
        <div className="container-fluid px-lg-5 px-md-3 d-flex justify-content-around align-items-center flex-wrap">

            <div className=" vision_mission_card text-center my-4 col-lg-4">
                <img src="/images/Vision.png" alt="vision" />
                <h3 className="my-3 fw-bold text-secondary" >Vision</h3>
                <p className="fs-5 px-4 px-md-5" >At Friends Ledger, we envision a digitally harmonious world where shared finances thrive. Our mission is to seamlessly manage financial interactions, promoting transparency, trust, and ease. We empower friends to effortlessly track transactions, eliminating misunderstandings, and enriching connections.</p>
            </div>

            <div className=" vision_mission_card text-center my-4 col-lg-4">
                <img src="/images/mission.png" alt="mission" />
                <h3 className="my-3 fw-bold text-secondary" >Mission</h3>
                <p className="fs-5 px-4 px-md-5" >At Friends Ledger, we're on a mission to revolutionize how friends manage finances. Our user-friendly platform simplifies tracking, promotes open communication, and strengthens bonds. Through innovation and financial clarity, we enhance overall well-being by modernizing shared financial management.</p>
            </div>

            <div className=" vision_mission_card text-center my-4 col-lg-4">
                <img src="/images/goal.png" alt="mission" />
                <h3 className="my-3 fw-bold text-secondary" >Goal</h3>
                <p className="fs-5 px-4 px-md-5 " >At Friends Ledger, we aim to be the go-to platform for friends managing shared finances. Empowering users with an intuitive solution, we strive for transparent, stress-free financial interactions, setting a new standard for collaborative management. Our vision is to nurture stronger friendships and contribute to overall well-being.</p>
            </div>

        </div>

        {/* about Me */}
        {/* <div className="container-fluid mb-5 about_headings" >
            <h1 className="text-center" > About Me </h1>  
            <div className="underline_sap" style={{width:"100px"}} ></div>
        </div> */}

        <div className="container-fluid px-lg-5 about_me d-lg-flex ">
            <div className="col-lg-3 col-sm-12 d-flex about_me_img_con justify-content-center">
                <div className="my_img"></div>
                <div className="img_background"></div>
            </div>
            <div className="col-lg-9 ms-lg-5 col-sm-12  ">
                <h2 className="fw-bold" >Meet Monish B K</h2>
                <p className="fs-5 text-secondary fw-bold mb-0" >Developer</p>
                <div className="py-2 mb-2 d-flex links_container" >
                    <Link className="btn social_links" 
                    to="https://github.com/MonishBK" target="_blank" >
                        <FaGithub  />
                    </Link>
                    <Link className="btn social_links" 
                    to="https://www.linkedin.com/in/monish-b-k-61726818a/" target="_blank" >
                        <FaLinkedin  />
                    </Link>
                    <Link className="btn social_links"
                     to="https://www.instagram.com/monish.b.k_/" target="_blank" >
                        <FaInstagram />
                    </Link>
                    <Link className="btn social_links"
                     to="https://www.facebook.com/profile.php?id=100008324416789" target="_blank" >
                        <FaFacebook />
                    </Link>
                    <Link className="btn social_links" 
                    to="https://twitter.com/monish_b_k" target="_blank" >
                        <FaSquareXTwitter />
                    </Link>
                </div>
                <Link to="https://monish-portfolio.vercel.app/" target="_blank" 
                className="btn btn-outline-primary rounded-pill px-5" > Know More  <GoArrowRight/>  </Link>
            </div>
        </div>

        {/* <footer className="container py-4 my-5 fs-5 d-flex ">
            <Link onClick={ () => navigate(-1)} className="col-6 text-decoration-none"> <GoArrowLeft/> Back</Link>
            <Link to="/getting-started" className="col-6 text-decoration-none text-end ">Quick Demo <GoArrowRight  /> </Link>
        </footer> */}


    </div>
  )
}

export default About