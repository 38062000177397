import React,{useState, useEffect, useLayoutEffect, Suspense} from 'react';
import { BsArrowRight, BsCurrencyRupee } from "react-icons/bs";
import "./CSS/FriendsLedgerDetails.css"; 
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { MdEast } from "react-icons/md";
import { TransactionModels } from '../../components/Modals/Modals';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import {getLedgers} from "../../store/slices/FriendsLedgerSlice"
import { getUser } from "../../store/slices/auth";
import { getAllUsers } from "../../store/slices/users";
import { MdDeleteOutline } from "react-icons/md";
import { toast } from 'sonner';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { IoNotifications } from "react-icons/io5";

// import { motion } from 'framer-motion';
import { MdErrorOutline } from "react-icons/md";
import Spinners, { LoadingName } from '../../components/Spinners/Spinners';


const FriendsLedgerDetails = () => { 
 
    const [show, setShow] = useState(false);
    const [showTransactionHistory, setShowTransactionHistory] = useState(false);
    const [widthSize, setWidthSize] = useState();
    const {id} = useParams()
    const dispatch = useDispatch()
    const [data1, setData1] = useState([]);
    const [data2, setData2] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [deleteShow, setDeleteShow] = useState(false);
    const [remainderShow, setRemainderShow] = useState(false);
    const [remainderMsg, setRemainderMsg] = useState("IT'S TIME TO GIVE THE MONEY BACK AND SHOW WHAT A GREAT FRIEND YOU ARE");
    const [IDs, setIDs] = useState({
        senderID: '', 
        receiverID: ''
    });
    const [remainderMsgError, setRemainderMsgError] = useState(false);


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    
    const handleTransactionHistoryClose = () => setShowTransactionHistory(false);
    const handleTransactionHistoryShow = () => {
        setRemainderMsgError(false)
        setShowTransactionHistory(true)   
    };

    const userData = useSelector((state) =>{
        return state.userAuth
    })

    const ledgers = useSelector((state) =>{
        return state.friendsLedgers
    })

    const allUsers = useSelector((state) =>{
        return state.allUsers
    })

    const deleteTransactionPost = async (e) =>{
        e.preventDefault();
        handleDeleteClose()
        setLoading(true);

        if(data?.transactionData?.length > 1){
            let amount = data?.transactionData[1]?.new_amount 
            let balance = data?.transactionData[1]?.balance 
            // console.log(amount, balance)

            try {
                
                const res = await fetch(`${process.env.REACT_APP_BASE_URL}/delete-friends-ledger-transaction/${id}`, {
                  method: "DELETE",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                  },
                  credentials: "include",
                  body: JSON.stringify({
                    amount, balance  
                  })
                });
                // console.log(res.status)
                // console.log(res.body) 
                if (res.status === 201) {
                    const data =  await res.json();
                    // console.log("from the checking component==>",data);
                    // dispatch(getUser())
                    toast.success(data.message,{duration: 1500})
                    dispatch(getLedgers(userData?.user?._id))
                    setLoading(false)
                    // status? toast.success(data.message) : toast.success("Last transaction added successfully!!..")
                }else if(res.status === 422){
                    toast.error("Oops something went wrong try again",{duration: 1500})
                }else {
                    //   throw new Error(res.error);
                        toast.error("Oops something went wrong",{duration: 1500})
                }
          
              } catch (err) {
                // console.log(err);
                setLoading(false)
                dispatch(getLedgers(userData?.user?._id))
                toast.error("Oops something went wrong!!..",{duration: 1500})
              }

        }else{
            // console.log("No transaction data found")
        }

        setLoading(false)
    }

    const handleDeleteShow = () => setDeleteShow(true)
    const handleDeleteClose = () => setDeleteShow(false)

    const sendRemainder = async (e) =>{
        e.preventDefault();
        setLoading(true)

        try {
            const res = await fetch(`${process.env.REACT_APP_BASE_URL}/delete-friends-ledger-remainder/${id}`, {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("token")}`
                },
                credentials: "include",
                body: JSON.stringify({
                    msg:remainderMsg, senderID: IDs.senderID, receiverID: IDs.receiverID
                })
              });
            //   console.log(res.status)
            //   console.log(res.body) 
              if (res.status === 200) {
                  const data =  await res.json();
                  toast.success(data.message,{duration: 1500})
                  setLoading(false)
                  handleRemainderClose()
              }else {
                  //   throw new Error(res.error);
                      toast.error("Oops something went wrong",{duration: 1500})
              }
            
        } catch (err) {
                toast.error("Oops something went wrong!!..",{duration: 1500})
        }
        setLoading(false)
    }

    useEffect(() => {

        let res = ledgers?.data?.filter((item) => {
            return item._id === id ;
        }); 

        if(res[0]){

            var {title ,desc, person_name, UName, UID, SUID, amount, balanceAmount, transactionType, status, transactionData, share} = res[0]
        
            let data1 = allUsers?.users?.filter((item) => {
                return item._id === res[0]?.UID ;
            });
        
            let data2 = allUsers?.users?.filter((item) => {
                return item._id === res[0]?.SUID ;
            });
    
            setData({
                title ,desc, person_name, UName, UID, SUID, amount, balanceAmount, transactionType, status, transactionData, share
            })
            setData1(data1)
            setData2(data2)
        }


    }, [ledgers?.data,allUsers?.users,id]);

    useEffect(() => {
        // console.log(ledgers)
        setWidthSize(window.innerWidth)
    }, [setWidthSize]);


    const handleRemainderClose = () => {
        setRemainderShow(false)
        setRemainderMsg("IT'S TIME TO GIVE THE MONEY BACK AND SHOW WHAT A GREAT FRIEND YOU ARE")
        setIDs({
            senderID: '', 
            receiverID: ''
        })
    }

    const handleRemainderShow = (choice) => {
        setRemainderShow(true)
        if(choice) {
            setIDs({
                senderID: data1[0]?._id, 
                receiverID: data2[0]?._id
            })
        }else{
            setIDs({
                senderID: data2[0]?._id, 
                receiverID: data1[0]?._id
            })
        }
    }
    
    const hideValidate = (ele) =>{
        ele.style.border = "1px solid rgb(206, 206, 206)";
      }
    const showValidate = (ele) =>{
        ele.style.border = "1px solid red";
      }

    const HandelOnBlur = (name, value) =>{
        let desc_input = document.getElementById("desc_msg_input");
    
        // validate description
        if(name === "desc_msg"){
           if(value?.trim().length > 130 || value.trim().length < 1){
            showValidate(desc_input)
            setRemainderMsgError(true)
        }else {
            setRemainderMsgError(false)
            hideValidate(desc_input)
          }
    
        }
    
    }

    useLayoutEffect(() => {
        if(userData?.user?.length === 0 ){
            dispatch(getUser())
            dispatch(getAllUsers())
        }
    }, [userData,dispatch]);
    
    useLayoutEffect(() => {
        dispatch(getLedgers(userData?.user?._id))
    }, [dispatch,userData?.user?._id]);

    console.log(data)

  return (
    <>
            <div>

                <Suspense fallback={<LoadingName/>} >
                    <div className='main_ledger_component px-lg-3 px-sm-1'>
                        <div className="ledger_header_block ">
                            <h2 className='px-2'> {data?.title} </h2>
                            <p className='px-2 text-secondary'> {data?.desc} </p>
                        </div>


                        {
                            <div className='w-100 body_details  d-flex  align-items-center justify-content-center  ' >
                                <div className=' inner_body'>

                                {
                                    data && data1 && data2 && 
                                    <>
                                        { 
                                        data?.transactionType === "Lend" ? 

                                                <div className='logo_container' >
                                                        <div>
                                                            {
                                                                !data.amount ? <div> loading.. </div>  :
                                                                data?.amount > 0 ? 
                                                                <>
                                                                    <img className='img_1 shadow bg-light' loading="lazy" src={`/images/profile_pics/${data?.SUID === ""? "default.png" : data2[0]?.Profile_pic !== undefined ? data2[0]?.Profile_pic : "default.png" }`} alt="profile pic" width="120px" height="120px" />
                                                                    <img className='img_2 shadow bg-light' loading="lazy" src={`/images/profile_pics/${data1[0]?.Profile_pic ? data1[0]?.Profile_pic : "default.png"}`} alt="profile pic" width="120px" height="120px" />
                                                                </>
                                                                : 
                                                                <>
                                                                    <img className='img_1 shadow bg-light' loading="lazy" src={`/images/profile_pics/${data1[0]?.Profile_pic ? data1[0]?.Profile_pic : "default.png"}`} alt="profile pic" width="120px" height="120px" />
                                                                    <img className='img_2 shadow bg-light' loading="lazy" src={`/images/profile_pics/${data?.SUID === ""?"default.png" :data2[0]?.Profile_pic !== undefined ? data2[0]?.Profile_pic : "default.png" }`} alt="profile pic" width="120px" height="120px" />
                                                                </> 
                                                            }
                                                        </div>
                                                        <p className='position-relative '  > 
                                                            {data?.amount > 0 ? data?.person_name : data?.UName }  <BsArrowRight/>  {data?.amount > 0 ? data?.UName : data?.person_name }
                                                        <span 
                                                        className={`${data?.transactionType === "Lend"? 'text-success': 'text-danger'}  position-absolute  ms-2 `} > 
                                                        </span> </p> 

                                                </div>
                                                                
                                            : 
                                                <div className='logo_container' >
                                                        <div>
                                                        {
                                                                data?.amount > 0 ? 
                                                                <>
                                                                    {/* <div className='img_1 pro_pics_con shadow rounded-circle' style={{backgroundImage:`url(/images/profile_pics/${data1[0]?.Profile_pic})`, width:"120px", height:"120px"}}  ></div> */}
                                                                    {/* <div className='img_2 pro_pics_con shadow rounded-circle' style={{backgroundImage:`url(/images/profile_pics/${data?.SUID === ""? "default.png" :data2[0]?.Profile_pic })`, width:"120px", height:"120px"}}  ></div> */}
                                                                    <img className='img_1 shadow bg-light' loading="lazy" src={`/images/profile_pics/${data1[0]?.Profile_pic !== undefined ? data1[0]?.Profile_pic : "default"}`} alt="profile pic" width="120px" height="120px" />
                                                                    <img className='img_2 shadow bg-light' loading="lazy" src={`/images/profile_pics/${data?.SUID === ""?"default.png" : data2[0]?.Profile_pic !== undefined ? data2[0]?.Profile_pic : "default.png" }`} alt="profile pic" width="120px" height="120px" />
                                                                </>
                                                                : 
                                                                <>
                                                                    {/* <div className='img_1 pro_pics_con shadow rounded-circle' style={{backgroundImage:`url(/images/profile_pics/${data?.SUID === ""? "default.png" :data2[0]?.Profile_pic})`, width:"120px", height:"120px"}}  ></div>
                                                                    <div className='img_2 pro_pics_con shadow rounded-circle' style={{backgroundImage:`url(/images/profile_pics/${data1[0]?.Profile_pic})`, width:"120px", height:"120px"}}  ></div> */}
                                                                    <img className='img_1 shadow bg-light' src={`/images/profile_pics/${data?.SUID === ""? "default.png" : data2[0]?.Profile_pic !== undefined ? data2[0]?.Profile_pic : "default.png" }`} alt="profile pic" width="120px" height="120px" />
                                                                    <img className='img_2 shadow bg-light' src={`/images/profile_pics/${data1[0]?.Profile_pic ? data1[0]?.Profile_pic : "default.png"}`} alt="profile pic" width="120px" height="120px" />
                                                                </> 
                                                            }
                                                        </div>
                                                        <p className='position-relative ' >  
                                                        {data?.amount > 0 ? data?.UName : data?.person_name }  <BsArrowRight/>  {data?.amount > 0 ? data?.person_name : data?.UName }
                                                        <span 
                                                        className={`${data?.transactionType === "Lend"? 'text-success': 'text-danger'}  position-absolute  ms-2 `} > 
                                                        </span> </p>

                                                </div>
                                        }
                                    </>
                                }

                                    <div className=' details_con fs-6'>
                                        <div className='blocks py-2' >
                                            <h4 className='principal_amt amt_name ' style={{width:"60%"}}   >Total Amount   </h4>
                                            {
                                                data?.transactionType === "Lend"?
                                                    <h4
                                                    className={` ${userData?.user?._id === data?.UID ? data?.amount > 0 ? 'text-success': 'text-danger' : "text-dark"} amt_num`}
                                                    style={{width:"40%"}}
                                                    > <BsCurrencyRupee/> {data?.amount ? Math.abs(data?.amount) : 0} </h4>
                                                :
                                                    <h4
                                                    className={` ${userData?.user?._id === data?.UID ? data?.amount < 0 ? 'text-success': 'text-danger' : "text-dark"} amt_num`}
                                                    style={{width:"40%"}}
                                                    > <BsCurrencyRupee/> {data?.amount ? Math.abs(data?.amount) : 0} </h4>

                                            }
                                        </div>
                                        <div className='blocks py-2' >
                                            <h4 className='amt_name'  style={{width:"60%"}}  >Balance</h4>
                                            <h4 className=' text-warning  amt_num'  style={{width:"40%"}}   >
                                                <BsCurrencyRupee/> {data?.balanceAmount ? Math.abs(data?.balanceAmount) : 0} </h4>
                                        </div>
                                        <div className=' btn_block py-3' >
                                            <div className='mt-2'>
                                                <button className={`btn py-2 add_trans shadow-sm fs-5 ${data?.SUID === userData?.user?._id ? "d-none": "" }`}
                                                disabled={data?.status? false : true}  onClick={handleShow}  >Add transaction</button>
                                            </div>
                                            <div className='mt-2'>
                                                <button className='btn py-2 view_history shadow-sm fs-5'
                                                onClick={handleTransactionHistoryShow}
                                                >Transaction history</button>
                                            </div>
                                            {
                                                data?.UID === userData?.user?._id ?

                                                data?.share && ((data?.transactionType === "Lend" && data?.amount > 0) || (data?.transactionType === "Borrow" && data?.amount < 0)) && data?.balanceAmount !== 0 &&
                                                <div className='mt-2'>
                                                    <button className='btn py-2 send_remainder shadow-sm fs-5'
                                                    onClick={() => handleRemainderShow(true)}
                                                    >Send Remainder <IoNotifications /> </button>
                                                </div>
                                                :
                                                data?.share && ((data?.transactionType === "Lend" && data?.amount < 0) || (data?.transactionType === "Borrow" && data?.amount > 0)) && data?.balanceAmount !== 0 &&
                                                <div className='mt-2'>
                                                    <button className='btn py-2 send_remainder shadow-sm fs-5'
                                                    onClick={() => handleRemainderShow(false)}
                                                    >Send Remainders <IoNotifications /> </button>
                                                </div>
                                                
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                        }

                            <Modal show={showTransactionHistory} 
                            onHide={handleTransactionHistoryClose}
                            dialogClassName="modal-90w"
                            size="lg"
                            fullscreen={'sm-down'}
                            aria-labelledby="contained-modal-title-vcenter"
                            backdrop="static"
                            centered
                            keyboard={false}
                            scrollable
                            >
                                <Modal.Header closeButton className='border-bottom-0 py-1 ' >
                                <Modal.Title>Transaction History</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>

                            <div className="ledger_body_block mt-0"  style={{overflowY: "scroll", maxHeight: widthSize >= 575 ?"50vh" :"100vh"}} >
                                {
                                    loading ? <Spinners/> :
                                    <Table className= "table table-striped table-hover" >
                                        <Thead className="table-dark position-sticky top-0"   > 
                                            <Tr>
                                            {/* <Th>Sl.No</Th> */}
                                            <Th>Transaction</Th>
                                            <Th>Amount</Th>
                                            <Th>Balance</Th>
                                            <Th>Mode</Th>
                                            <Th>Description</Th>
                                            <Th>Date</Th>
                                            <Th></Th>
                                            </Tr>
                                        </Thead> 
                                        <Tbody >

                                            {
                                            data?.transactionData?.map((ele,index)=>{
                                                    const {amount, balance, date, desc, from, mode, to} = ele
                                                    let amt_color
                                        
                                                    if(data?.transactionType === "Lend"){
                                                        if(data?.transactionType === "Lend" && to ===  data?.UName){
                                                            amt_color = true
                                                        }else{
                                                            amt_color = false
                                                        }
                                                    }else{
                                                        if(data?.transactionType === "Borrow" && to === data?.UName){
                                                            amt_color = true
                                                        }else{
                                                            amt_color = false
                                                        }

                                                    }

                                                    return(
                                                            <Tr className="border-0 border-bottom" key={index} >
                                                            {/* <Td>{index+1}</Td> */}
                                                            <Td> {from} <MdEast/> {to} </Td> 
                                                            <Td ><span className={` ${ userData?.user?._id === data?.UID ? amt_color? "text-success": "text-danger" : "" } `}>{amount}</span> </Td>
                                                            <Td>{balance} </Td>
                                                            <Td>{mode} </Td>
                                                            <Td> {desc} </Td>
                                                            <Td>{date}</Td>
                                                            <Td>
                                                                {
                                                                    userData?.user?._id === data?.UID && data?.status ? index === 0 ?
                                                                    <>
                                                                        {/* <button className='btn p-0' onClick={handleShow} > <MdOutlineModeEditOutline className='text-warning' /> </button> */}
                                                                        {
                                                                            data?.transactionData?.length > 1 ?
                                                                            <button className='btn p-0 ms-1' disabled={loading} onClick={handleDeleteShow} > <MdDeleteOutline className='text-danger' /> </button>
                                                                            :""
                                                                        }
                                                                    </> : "" : ""
                                                                }
                                                            </Td>
                                                            </Tr>
                                                    )
                                                })
                                            }
                                            
                                            
                                            
                                        
                                        </Tbody>
                                    </Table>
                                }

                                </div>

                                </Modal.Body>
                            </Modal>

                        {/* delete confirmation modal */}
                        <Modal show={deleteShow} 
                            onHide={handleDeleteClose}
                            backdrop="static"
                            centered
                            keyboard={false}
                            scrollable>
                                <Modal.Body className="text-center fs-5  border-0">Are you sure do you want to delete this transaction.</Modal.Body>
                                <Modal.Footer className="border-0" >
                                <Button variant="secondary" onClick={handleDeleteClose}>
                                    Close
                                </Button>
                                <Button variant="danger" onClick={deleteTransactionPost} >
                                    Delete
                                </Button>
                                </Modal.Footer>
                        </Modal>


                        {/* remainder message modal */}
                        <Modal show={remainderShow} 
                            onHide={handleRemainderClose}
                            backdrop="static"
                            centered
                            keyboard={false}
                            scrollable>
                                <Modal.Body className="text-center fs-5  border-0">
                                    {
                                        loading ? <Spinners/> :
                                        <form>
                                            <div className="mb-3">
                                                <label htmlFor="message-text" className="col-form-label">Remainder Message</label>
                                                <div className=" position-relative">
                                                    <textarea className="form-control remainder_msg" style={{resize:"none"}} 
                                                    name="desc"
                                                    onBlur={() => HandelOnBlur("desc_msg", remainderMsg)}
                                                    value={remainderMsg} 
                                                    onChange={(e) => setRemainderMsg(e.target.value)}
                                                    maxLength="130"
                                                    id="desc_msg_input"></textarea>
                                                    <span className={`position-absolute top-0 end-0 me-2 text-danger ${!remainderMsgError?"d-none":"" } fs-5`}
                                                        data-tooltip-id="my-tooltip"
                                                        data-tooltip-content="Please write the message in 130 characters."
                                                        data-tooltip-variant="error"
                                                    > <MdErrorOutline/> </span>
                                                </div>
                                            </div>
                                        </form>
                                    }
                                </Modal.Body>
                                <Modal.Footer className="border-0" >
                                <Button variant="secondary" onClick={handleRemainderClose}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={sendRemainder} >
                                    Send
                                </Button>
                                </Modal.Footer>
                        </Modal>

                        {/* add traction modal */}
                        <TransactionModels closeFun={handleClose} show={show} id={id} />

                    </div>
                </Suspense>

            </div>
    </>
  )
}

export default FriendsLedgerDetails