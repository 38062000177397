import React,{useState, useLayoutEffect, Suspense} from "react";
import { lazily } from 'react-lazily';
import "./CSS/Profile.css"
import { toast } from 'sonner';
import { useNavigate } from "react-router-dom";
// import { loginFunc } from "../../services/Apis";
import { BsFillCameraFill } from "react-icons/bs";
import { GiCheckMark } from "react-icons/gi";
import { MdErrorOutline } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import { getUser } from '../../store/slices/auth';
import { getAllUsers } from "../../store/slices/users";

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

import Modal from 'react-bootstrap/Modal';
import { Tooltip } from 'react-tooltip'
import Spinners, { LoadingName } from "../../components/Spinners/Spinners";

import { motion } from "framer-motion";
import {profileAvatar} from "../../components/ProfilePictures/ProfilesPicture"
const  {DatePicker} = lazily( () => import('@mui/x-date-pickers/DatePicker')) 


const EditProfile = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch()

  
  const userData = useSelector((state) =>{
    return state.userAuth
})

  const [DOB, setDOB] = useState("");
  const [DOBError, setDOBError] = useState(false);
  const [genderError, setGenderError] = useState(false);
  const [show, setShow] = useState(false);
  const [signUpBtn, setSignUpBtn] = useState(false);
  const [disBtn, setDisBtn] = useState(true); 
  const [avatar, setAvatar] = useState("");
  

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


//   let [userRegisterData, setUserRegisterData] = useState({
//     First_Name: userData?.user?.firstName,
//     Last_Name: userData?.user?.lastName,
//     Profile_Picture: userData?.user?.Profile_pic,
//     gender:  userData?.user?.gender,
//     dob: userData?.user?.DOB,

// });
  let [userRegisterData, setUserRegisterData] = useState({
    First_Name: "",
    Last_Name: "",
    Profile_Picture: "",
    gender:  "",
    dob: "",

});

  let [ErrorStatus, setErrorStatus] = useState({
    First_Name:false,
    Last_Name:false,
    dob:false,
    gender:false,
});

const inputEvent = (e) => {
    let { name, value } = e.target;
    // console.log(name, value);

    setUserRegisterData({ ...userRegisterData, [name]: value })

    if(name === "gender"){
      setErrorStatus({...ErrorStatus, gender: false})
      setGenderError(false)
    }

}

const hideValidate = (ele) =>{
  ele.style.border = "1px solid gray";
}
const showValidate = (ele) =>{
  ele.style.border = "1px solid red";
}

const RegisterUser = async (e) => {
    e.preventDefault();
    setSignUpBtn(true)
     
    let { First_Name,Last_Name,Profile_Picture, gender, dob } = userRegisterData;
    DOB !== null ? dob = DOB.$D+"/"+(DOB.$M+1)+"/"+DOB.$y : dob = null
    Profile_Picture = avatar
    // console.log(typeof(dob),dob)

    First_Name = First_Name.trim()
    Last_Name = Last_Name.trim()

    // validation
    if(First_Name === "" &&  Last_Name === "" && gender === "" && dob === null ){
    
      document.getElementById("f_name").focus()
      toast.error("please fill all the fields.",{duration: 1500})
      setSignUpBtn(false)

    }else if(First_Name === "" ||  Last_Name === "" ||  gender === "" || dob === null){

       HandelOnBlur("First_Name", First_Name)
      HandelOnBlur("Last_Name", Last_Name)

      ///validate Gender
      if (gender === "") {
        setGenderError(true)
      }else{
        setGenderError(false)
      }

      // validate DOB
      if (dob === null) {
        setDOBError(true)
      } else {
        setDOBError(false);
      }

      toast.error("Invalid Credentials!!..",{duration: 1500})
      setSignUpBtn(false)

    }else{
      // console.log(ErrorStatus)
      let validated = Object.keys(ErrorStatus).every(function(k){ return ErrorStatus[k] === false });

      if(validated){
        // console.log(First_Name,Last_Name,Profile_Picture, gender, dob )
        try {
                
          const res = await fetch(`${process.env.REACT_APP_BASE_URL}/update-profile/${userData?.user?._id}`, {
            method: "PATCH",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
              Profile_pic:Profile_Picture, firstName:First_Name, lastName:Last_Name, DOB:dob, gender:gender
            })
          });
          // console.log(res.status)
          // console.log(res.body)
          if (res.status === 400) {
          //   throw new Error(res.error);
              toast.error("Oops something went wrong",{duration: 1500})
          }else{
              const data =  await res.json();
              // console.log("from the checking component==>",data);
              dispatch(getUser())
              toast.success(data.message,{duration: 1500})
          }
    
        } catch (err) {
          // console.log(err);
          toast.error("Oops something went wrong!!..",{duration: 1500})
        //   history.push('/');
        }
           setTimeout(() => {
            toast.success("Updated Successfully!!..",{duration: 1500});
            setSignUpBtn(false)
                navigate(-1);
              }, 2000);
      }else{
        toast.error("Invalid Credentials!!..",{duration: 1500})
        setSignUpBtn(false)
      }


    }

}

const HandelOnBlur = (name, value) =>{
    // alert(`On Blur value=> ${value}, id=> ${id}`)

    let f_name = document.getElementById("f_name");
    let l_name = document.getElementById("l_name");

    
    // validate First Name
    if(name === "First_Name"){
      if (value.trim() === "") {
        showValidate(f_name)
        setErrorStatus({...ErrorStatus, [name] : true})
      }else {
        setErrorStatus({...ErrorStatus, [name] : false})
        hideValidate(f_name)
      }

    }

    // validate Last Name
    if(name === "Last_Name"){
      if (value.trim() === "") {
        showValidate(l_name)
        setErrorStatus({...ErrorStatus, [name] : true})
      }else {
        setErrorStatus({...ErrorStatus, [name] : false})
        hideValidate(l_name)
      }

    }
    //validate DOB
    if(name === "DOB"){
      console.log("onblur test")
      if (value === null) {
        setDOBError(true)
      } else {
        setDOBError(false);
      }

    }



}

const animation = {
  initial: {opacity: 0, x: -100},
  animate: {opacity: 1, x:0},
  exit: {opacity:0, x: 100}

}

// useEffect(() => {
//   setDOB(dayjs(userData?.user?.DOB.split('/').reverse().join('/')))
// }, []);

useLayoutEffect(() => {
  if(userData?.user?.length === 0 ){
      dispatch(getUser())
      dispatch(getAllUsers())
  }else{
    setDOB(dayjs(userData?.user?.DOB.split('/').reverse().join('/')))
    setUserRegisterData({
      First_Name: userData?.user?.firstName,
      Last_Name: userData?.user?.lastName,
      Profile_Picture: userData?.user?.Profile_pic,
      gender:  userData?.user?.gender,
      dob: userData?.user?.DOB,
    })
    setAvatar(userData?.user?.Profile_pic)
  }
}, [userData,dispatch]);

useLayoutEffect(() =>{ 
  let dob
  DOB !== null ? dob = DOB.$D+"/"+(DOB.$M+1)+"/"+DOB.$y : dob = null
  if(userData?.user?.firstName !== userRegisterData?.First_Name || userData?.user?.lastName !== userRegisterData?.Last_Name || avatar !== userRegisterData?.Profile_Picture || userData?.user?.gender !== userRegisterData?.gender || dob !== userRegisterData?.dob){
    setDisBtn(false)
  }else{
    setDisBtn(true)
  }
},[DOB, userData?.user, userRegisterData, avatar, setDisBtn])


  return (
    <motion.div 
    variants={animation}
    initial="initial"
    animate="animate"
    exit="exit"
    transition={{duration: 0.2}}
    >
       <Suspense fallback={<LoadingName/>} >
          <div className='row container-fluid login_div m-0  p-0 '  >
          <div className=" col-sm-12 " style={{height:"100%",width:"100%",padding:"0px", marginTop:"60px"}}>

          <form className='col-sm-6 col-lg-6 pb-3  px-4 fs-4  rounded-4 m-auto ' >
            <div className="mb-3 d-flex justify-content-center   " >
              {
                <div className=" rounded-circle profile_pic_con position-relative" style={{height: "120px", width:"120px" }} >
                  {/* <img className=" " src={imgPreview? imgPreview :"/images/user-logo.png"} alt="Profile "  width={"120px"} height={"120px"} style={{borderRadius: "50%"}} />   */}
                  <img className=" " src={avatar !== ""? `/images/profile_pics/${avatar}` :"/images/profile_pics/default.png"} alt="Profile "  width={"120px"} height={"120px"} style={{borderRadius: "50%"}} />  

                    <div className="position-absolute overlay_profile_pic "  onClick={handleShow} >
                      <BsFillCameraFill className="me-2"/> Image
                    </div>
                </div>
              }

                <Modal
                  show={show}
                  onHide={handleClose}
                  backdrop="static"
                  centered
                  keyboard={false}
                  scrollable
                >
                  <Modal.Header closeButton className='border-bottom-0 pb-0' >
                    {/* <Modal.Title>Modal title</Modal.Title> */}
                  </Modal.Header>
                  <Modal.Body >

                    <div className="avatar_options">
                      {
                        
                        profileAvatar?.map(ele =>{
                            const {ID, imageName} = ele
                          return(
                            <div key={ID} className="avatar_pic " onClick={()=>{ 
                              setAvatar(imageName) 
                                handleClose()
                                } }  >
                                <Suspense fallback={<LoadingName/>} >
                                  <img src={`/images/profile_pics/${imageName}`} width="50px" height="50px" alt="" />
                                </Suspense>
                                  {
                                    avatar === imageName?
                                    <div className="selected_avatar"  ><GiCheckMark/></div>
                                    : ""
                                  }
                              </div>
                          )
                        })

                      }

                    </div>

                  </Modal.Body>
                </Modal>
            
            </div>

            <div className="row  ">
              <div className="col-sm-12 mb-3 col-lg-6 input_con">
                <input type="text" className="form-control fs-5 rounded-2" 
                onBlur={() => HandelOnBlur("First_Name", userRegisterData.First_Name)}
                name="First_Name"
                value={userRegisterData.First_Name}
                onChange={inputEvent}
                id="f_name"
                placeholder="First name" aria-label="First name" required />
                <span  className={`error_icon text-danger ${!ErrorStatus.First_Name?"d-none":"" } fs-5`}
                data-tooltip-id="my-tooltip"
                data-tooltip-content="What's your name?"
                data-tooltip-variant="error"
                > <MdErrorOutline/> </span>
              </div>
              <div className="col-sm-12 mb-3 col-lg-6 input_con">
                <input type="text" className="form-control fs-5 rounded-2" 
                name="Last_Name"
                onBlur={() => HandelOnBlur("Last_Name", userRegisterData.Last_Name)}
                value={userRegisterData.Last_Name} 
                onChange={inputEvent}
                id="l_name"
                placeholder="Last name" aria-label="Last name"/>
                <span className={`error_icon text-danger ${!ErrorStatus.Last_Name?"d-none":"" } fs-5`}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="What's your name?"
                  data-tooltip-variant="error"
                > <MdErrorOutline/> </span>
              </div>
            </div>

            {/* Gender */}
            <div className="mb-3">
            <label  className="form-label fs-5 pe-5 input_con">Gender
            <span className={`error_icon text-danger fs-5 ${genderError?"":"d-none"} `}  style={{top:"-5%"}}
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Please choose a gender."
              data-tooltip-variant="error"
            > <MdErrorOutline/> </span>
            </label>
            
            <div className="gender_container d-flex justify-content-between align-items-center flex-lg-row ">

              <div className={` ${genderError? "border-danger" : ""} inner_gen_con rounded form-check border fs-5 p-0 px-3  d-flex justify-content-between align-items-center py-1`}
              style={{width:"200px"}}
              >
                <label className="form-check-label " htmlFor="male-radio">
                  Male
                </label>
                <input className="form-check-input "   checked={userRegisterData.gender === 'Male'}
                onChange={inputEvent} type="radio" value="Male" name="gender" id="male-radio"/>
              </div>
              <div className={`${genderError? "border-danger" : ""} inner_gen_con rounded form-check border fs-5 p-0 px-3  d-flex justify-content-between py-1 align-items-center`}
              style={{width:"200px"}}
              >
                <label className="form-check-label " htmlFor="female-radio">
                  Female
                </label>
                <input className="form-check-input " checked={userRegisterData.gender === 'Female'}
                onChange={inputEvent} type="radio" value="Female" name="gender" id="female-radio" />
              </div>
              <div className={` ${genderError? "border-danger" : ""} inner_gen_con rounded form-check border fs-5 p-0 px-3  d-flex justify-content-between py-1 align-items-center`}
              style={{width:"200px"}}
              >
                <label className="form-check-label " htmlFor="others-radio">
                  Others
                </label>
                <input className="form-check-input " checked={userRegisterData.gender === 'Others'}
                onChange={inputEvent} type="radio" value="Others" name="gender" id="others-radio" />
              </div>

            </div>
            </div>
            {/* DOB */}
            <div className="mb-3   ">
            <label  className="form-label fs-5 input_con pe-5">Date of Birth
              <span className={`error_icon text-danger fs-5 ${DOBError? "" : "d-none"} `}  style={{top:"-5%"}}
              data-tooltip-id="my-tooltip"
              data-tooltip-content="please provide your correct birth date"
              data-tooltip-variant="error"
              > <MdErrorOutline/> </span>
            </label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
              <DatePicker
                className="col-lg-12 "
                onChange={(newValue) => {
                  setDOB(newValue)
                  setDOBError(false)
                  ErrorStatus.dob=false
                }} 
                
                value={DOB}
                openTo="year"
                views={['year', 'month', 'day']}
                format="DD-MM-YYYY"
                disableFuture
                slotProps={{ textField: { size: 'small' } }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: `${DOBError? 'red':'grey'}`,
                    },
                    '&:hover fieldset': {
                      borderColor: 'rgb(206, 206, 206)',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'rgb(206, 206, 206)',
                    },
                  },
                }}
              />
              </DemoContainer>
            </LocalizationProvider>
            </div>

            <div className="d-grid mb-2">
              <button type="submit" onClick={RegisterUser} disabled={disBtn} className={`btn btn-primary rounded-2 fs-5  `}>Save</button>
            </div>
          </form>

          </div>
              {
                signUpBtn?<Spinners />:""
              }
              
          </div>
       </Suspense>
      <Tooltip id="my-tooltip" className="tooltip_style" />
    </motion.div>
  )
}

export default EditProfile

