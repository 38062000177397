import { RiSignalWifiOffLine } from "react-icons/ri";

const OfflinePage = ({children}) => {

  return (
    <>
    
        <div className='  d-flex justify-content-center flex-column align-items-center' style={{width:"100svw", height:"100svh"}}> 
          <h1 className='mb-2'>
            <RiSignalWifiOffLine className='fs-1' />
          </h1>
           <h3>Connect to internet </h3> 
           <p className='fs-6'>You're Offline. Check Your Connection.</p>
           <button className='btn btn-outline-primary px-4 py-1' onClick={()=> window.location.reload(false)} >Retry</button>
        </div>
    </>
  )
}

export default OfflinePage