import { useEffect } from "react";
import "./CSS/ErrorPage.css";
import { Link } from "react-router-dom";
import { GoArrowRight  } from "react-icons/go";
import { useDispatch } from "react-redux";
import {getUser} from "../../store/slices/auth"

const ErrorPage = () => {
  const dispatch = useDispatch()

  useEffect(() =>{
      dispatch(getUser())
},[dispatch])

  return (

      <div className='error_container container-fluid'>
        <div className="inner_container">
          <h1>Oops!</h1>
          <h4 className="fw-bold" >404 - PAGE NOT FOUND</h4>
          <p className="fs-6">
            The page you are looking for might have been removed <br className="d-none d-lg-block"  />
            had its name changed or is temporarily unavailable.
          </p>
          <Link to="/" replace={true} className="btn btn-primary rounded-pill px-5" >
            Go To Home <GoArrowRight/>
          </Link>
          {/* <div onClick={()=> navigate("/",{replace:true})} className="btn btn-primary rounded-pill px-5" >
            Go To Home <GoArrowRight/>
          </div> */}
        </div>
      </div>
  )
}

export default ErrorPage