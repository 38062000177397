import React,{useEffect, useState} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Spinners from './components/Spinners/Spinners';

export const Logout = () => {

    const navigate = useNavigate()
    const {opt} = useParams()
    const [loading, setLoading] = useState(true);

    const fetchData = async (opt) =>{

      try{
        if(opt === "all"){

          const res = await fetch(`${process.env.REACT_APP_BASE_URL}/logout-all-devices`,{
              method: "GET",
              headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("token")}`
              },
              credentials: "include"
          });
            
           let data = await res.json();

            if(res.status !== 200){ throw new Error(res.error) }
            if(res.status === 200 && data) {
              localStorage.removeItem("token")
              localStorage.removeItem("FCM_Token")
              setTimeout(() => {
                setLoading(false)
                navigate('/signOut',{replace: true});
                window.location.reload(false)
              }, 1000);
            }
          
        }else{
        
          const res = await fetch(`${process.env.REACT_APP_BASE_URL}/logout`,{
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`
            },
            credentials: "include"
          });
          
          let data = await res.json();
          if(res.status !== 200){ throw new Error(res.error) }

          if(res.status === 200 && data){
              localStorage.removeItem("token")
              localStorage.removeItem("FCM_Token")
              setTimeout(() => {
                setLoading(false)
                navigate('/signOut',{replace: true});
                window.location.reload(false)
              }, 1000);
          }
      }


    } catch (err) {
        setTimeout(() => {
          localStorage.clear()
          navigate('/',{replace: true});
          setLoading(false)
          window.location.reload(false)
        }, 1000);
    }
          

  }

    useEffect( () => {
        fetchData(opt)
        // navigate("/")
        // window.location.reload(true)
    });

  return (
    <>
      {
        loading? <Spinners /> : ""
      }
    </>
  )
}
