import React,{useState, useEffect, useLayoutEffect, Suspense} from 'react';
import { BsCurrencyRupee } from "react-icons/bs";
import "./CSS/LedgerDetails.css"; 
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { BillingTransactionModels } from '../../components/Modals/Modals';
import { useSelector,useDispatch} from "react-redux";
import { useParams } from 'react-router-dom';
import { getBillTracker } from '../../store/slices/BillTrackerSlice';
import { getUser } from '../../store/slices/auth';
import { getAllUsers } from "../../store/slices/users";


import Modal from 'react-bootstrap/Modal';
import { motion } from 'framer-motion';
import { LoadingName } from '../../components/Spinners/Spinners';


const BillTrackerDetails = () => { 
 
    const [show, setShow] = useState(false);
    const [showTransactionHistory, setShowTransactionHistory] = useState(false);
    const {id} = useParams()
    const [widthSize, setWidthSize] = useState();
    const dispatch = useDispatch()
    const [data, setData] = useState(null);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    
    const handleTransactionHistoryClose = () => setShowTransactionHistory(false);
    const handleTransactionHistoryShow = () => setShowTransactionHistory(true);

    const userData = useSelector((state) =>{
        return state.userAuth
    })

    const billTracker = useSelector((state) =>{
        return state.billTracker
    })
    

    // console.log(shoppingDetails)

    const animation = {
        initial: {opacity: 0, x: -100},
        animate: {opacity: 1, x:0},
        exit: {opacity:0, x: 100}
    }

    useLayoutEffect(() => {

        let res = billTracker?.data?.filter((item) => {
            return item._id === id ;
        });

        if(res[0]){

            var {title,fixed,status,totalAmount,amount,balanceAmount,transactionData, category} = res[0]
    
            setData({
                title,fixed,status,totalAmount,amount,balanceAmount,transactionData, category
            })

        }

    }, [billTracker?.data,id]);


    useEffect(() => {
        // console.log(shoppingDetails[id])
        setWidthSize(window.innerWidth)
    }, [setWidthSize]);


    useLayoutEffect(() => {
        if(userData?.user?.length === 0 ){
            dispatch(getUser())
            dispatch(getAllUsers())
            dispatch(getBillTracker(userData?.user?._id))
        }
    }, [userData,dispatch]);

    useLayoutEffect(() => {
        dispatch(getBillTracker(userData?.user?._id))
    }, [dispatch,userData?.user?._id]);


  return (
    <motion.div
    variants={animation}
    initial="initial"
    animate="animate"
    exit="exit"
    transition={{duration: 0.2}}
    >
        <Suspense fallback={<LoadingName/>} >
            <div className='main_ledger_component px-lg-3 px-sm-1'>
                <div className="ledger_header_block mb-5">
                    <h2 className='px-2'> </h2>
                    {/* <p className='px-2 text-secondary'> {desc} </p> */}
                </div>



                <div className='w-100 body_details  d-flex  align-items-center justify-content-center  ' >
                    <div className=' inner_body'>

                        <div className='logo_container' >
                        {
                            data && userData?.user &&
                            <div>
                                <img className='img_1 bg-light shadow' loading='lazy' src={`/images/profile_pics/${userData?.user?.Profile_pic !== undefined ? userData?.user?.Profile_pic : "default.png"}`} alt="profile logo" width="120px" height="120px" />
                                {
                                    data === null || data?.category === undefined ?
                                    <img className='img_2 bg-light shadow' loading='lazy' src={`/images/others.jpeg`} alt="category logo" width="120px" height="120px" />
                                    :
                                    <img className='img_2 bg-light shadow' loading='lazy' src={`/images/${data !== null && data?.category !== undefined ? data?.category+".jpeg" : "others.jpeg"}`} alt="category logo" width="120px" height="120px" />
                                }
                                {/* <img className='img_2 shadow' src={`/images/cart_1.jpg`} alt="" width="120px" height="120px" /> */}
                            </div>
                        }
                            <p className='position-relative '  > {data?.title}   </p> 

                                </div>
                                                

                        <div className=' details_con '>
                            {
                                data?.fixed?
                                <>
                                    <div className='blocks py-2' >
                                        <h4 className='principal_amt amt_name ' >Fixed Amount   </h4>
                                        <h4 className={`amt_num text-success`}> <BsCurrencyRupee/> {data?.amount} </h4>
                                    </div>
                                    <div className='blocks py-2' >
                                        <h4 className='amt_name' >No. of Items</h4>
                                        <h4 className='  amt_num justify-content-end' > {data?.transactionData?.length}  </h4>
                                    </div>
                                    <div className='blocks py-2' >
                                        <h4 className='principal_amt amt_name' >Spent Amount   </h4>
                                        <h4 className={` text-danger amt_num`}> <BsCurrencyRupee/> {data?.totalAmount} </h4>
                                    </div>
                                    <div className='blocks mb-2 py-2' >
                                        <h4 className=' amt_name' >Balance</h4>
                                        <h4 className=' text-warning  amt_num' > <BsCurrencyRupee/> {data?.balanceAmount} </h4>
                                    </div>
                                </>
                                : 
                                <>
                                    <div className='blocks mt-3 py-2' >
                                        <h4 className='principal_amt amt_name' >Total Amount   </h4>
                                        <h4 className={`amt_num text-success`}> <BsCurrencyRupee/> {data?.totalAmount} </h4>
                                    </div>
                                    <div className='blocks mb-2 py-2' >
                                        <h4 className='amt_name' >No. of Items</h4>
                                        <h4 className='  amt_num justify-content-end' >  {data?.transactionData?.length}  </h4>
                                    </div>
                                </>

                            }
                            
                            <div className=' btn_block py-3' >
                                <div className='mt-2'>
                                    <button className='btn py-2 add_trans shadow-sm fs-5' disabled={data?.status? false : true}  onClick={handleShow}  >Add Item</button>
                                </div>
                                <div className='mt-2'>
                                    <button className='btn py-2 view_history shadow-sm fs-5'
                                    onClick={handleTransactionHistoryShow}
                                    >Shopping history</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <Modal show={showTransactionHistory} 
                onHide={handleTransactionHistoryClose}
                dialogClassName="modal-90w"
                size="lg"
                fullscreen={'sm-down'}
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                centered
                keyboard={false}
                scrollable
                >
                    <Modal.Header closeButton className='border-bottom-0 py-1' >
                    <Modal.Title>Transaction History</Modal.Title>
                    </Modal.Header>
                    <Modal.Body> 

                <div className="ledger_body_block mt-0" style={{overflowY: "scroll", maxHeight: widthSize >= 575 ?"50vh" :"100vh"}}  >

                    <Table className= "table table-striped table-hover " >
                        <Thead className="table-dark position-sticky top-0"   >
                            <Tr>
                                <Th>Sl.No</Th>
                                <Th>Item Name</Th>
                                <Th>amount</Th>
                                <Th>{data?.fixed ? 'Balance': 'Total Amount'}</Th>
                                <Th>mode</Th>
                                <Th>date</Th>
                            </Tr>
                            </Thead> 
                            <Tbody >

                                {
                                    data?.transactionData?.map((ele,index)=>{
                                        const {item_name, amount, balance, date, mode } = ele

                                        return(
                                                <Tr className="border-0 border-bottom" key={index} >
                                                    <Td>{index+1}</Td>
                                                    <Td> {item_name} </Td> 
                                                    <Td ><span className={` `}>{amount}</span> </Td>
                                                    <Td> <span className={`${balance < 0? "text-danger": ""}`} >{balance}</span>  </Td>
                                                    <Td>{mode}</Td>
                                                    <Td>{date}</Td>
                                                </Tr>
                                        )
                                    })
                                }
                                
                                
                                
                            
                            </Tbody>
                        </Table>

                    

                    </div>

                    </Modal.Body>
                </Modal>

                {/* add traction modal */}
                <BillingTransactionModels closeFun={handleClose} show={show} id={id} />

            </div>
        </Suspense>

    </motion.div>
  )
}

export default BillTrackerDetails