export const profileAvatar = [
    {   
        ID: "IMG_0",
        imageName: "default.png"
    },
    {   
        ID: "IMG_1",
        imageName: "avatar_1.jpg"
    },
    {
        ID: "IMG_2",
        imageName: "avatar_2.jpg"
    },
    {
        ID: "IMG_3",
        imageName: "avatar_3.jpg"
    },
    {
        ID: "IMG_4",
        imageName: "avatar_4.jpg"
    },
    {
        ID: "IMG_5",
        imageName: "avatar_5.jpg"
    },
    {
        ID: "IMG_6",
        imageName: "avatar_6.jpg"
    },
    {
        ID: "IMG_7",
        imageName: "avatar_7.jpg"
    },
    {
        ID: "IMG_8",
        imageName: "avatar_8.jpg"
    },
    {
        ID: "IMG_9",
        imageName: "avatar_9.jpg"
    },
    {
        ID: "IMG_10",
        imageName: "avatar_10.jpg"
    },
    {
        ID: "IMG_11",
        imageName: "avatar_11.jpg"
    },
    {
        ID: "IMG_12",
        imageName: "avatar_12.jpg"
    },
    {
        ID: "IMG_13",
        imageName: "avatar_13.jpg"
    },
    {
        ID: "IMG_14",
        imageName: "avatar_14.jpg"
    },
    {
        ID: "IMG_15",
        imageName: "avatar_15.jpg"
    },
    {
        ID: "IMG_16",
        imageName: "avatar_16.jpg"
    },
    {
        ID: "IMG_17",
        imageName: "avatar_17.jpg"
    },
    {
        ID: "IMG_18",
        imageName: "avatar_18.jpg"
    },
    {
        ID: "IMG_19",
        imageName: "avatar_19.jpg"
    },
    {
        ID: "IMG_20",
        imageName: "avatar_20.jpg"
    },
    {
        ID: "IMG_21",
        imageName: "avatar_21.jpg"
    },
    {
        ID: "IMG_22",
        imageName: "avatar_22.jpg"
    },
    {
        ID: "IMG_23",
        imageName: "avatar_23.jpg"
    },
    {
        ID: "IMG_24",
        imageName: "avatar_24.jpg"
    },
    {
        ID: "IMG_25",
        imageName: "avatar_25.jpg"
    },
    {
        ID: "IMG_26",
        imageName: "avatar_26.jpg"
    },

]