import React,{useState, useEffect, Suspense} from 'react'
import { lazily } from 'react-lazily';
import { FaLongArrowAltRight,FaLongArrowAltLeft } from "react-icons/fa";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { MdErrorOutline } from "react-icons/md";
import { Tooltip } from 'react-tooltip'
import { useSelector } from "react-redux";
import { toast } from 'sonner';
import { useDispatch } from "react-redux";
import { getShoppingLedgers } from '../../store/slices/ShoppingLedgerSlice';
import { getBillTracker } from '../../store/slices/BillTrackerSlice';
import dayjs from 'dayjs';
import {getLedgers} from "../../store/slices/FriendsLedgerSlice"
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';

import "./CSS/Modals.css"
import Spinners from '../Spinners/Spinners';
const  {DatePicker} = lazily( () => import('@mui/x-date-pickers/DatePicker')) 
// import { BsExclamationSquareFill } from 'react-icons/bs';

export const TransactionModels = ({closeFun,show,id}) => {

    const [transactionDate, setTransactionDate] = useState(dayjs(new Date()));
    const [rightArrow, setRightArrow] = useState(true);
    const [transactionMode, setTransactionMode] = useState('online');
    const [dateError, setDateError] = useState(false);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const [transactionData, setTransactionData] = useState({
        from: "",
        to: "",
        amount: "",
        balance: "",
        desc:"",
        date:'',
        mode: ''
    });

    const [transactionDataError, setTransactionDataError] = useState({
        amount: false,
        desc:false,
        date:false,
    });

    const userData = useSelector((state) =>{
        return state.userAuth
    })

    const ledgers = useSelector((state) =>{
        return state.friendsLedgers
    })

    let ledgersDetails = ledgers?.data?.filter((item) => {
        return item._id === id ;
    });

    const handelClose = () =>{

        setTransactionData({
            from: "",
            to: "",
            amount: "",
            balance: "",
            desc:"",
            date:'',
            mode: ''
        })

        setTransactionDataError({
            amount: false,
            desc:false,
            date:false,
        })

        setTransactionMode('online')
        setTransactionDate(dayjs(new Date()))
        setRightArrow(true)
        setDateError(false)

        closeFun()
    }

    const inputEvent = (e) => {
        let { name, value } = e.target;
        // console.log(name, value); 
        setTransactionData({ ...transactionData, [name]: value })
    
    }

    const hideValidate = (ele) =>{
        ele.style.border = "1px solid gray";
      }
    const showValidate = (ele) =>{
        ele.style.border = "1px solid red";
      }

    const HandelOnBlur = (name, value) =>{

        let desc_input = document.getElementById("desc_input");
        let amt = document.getElementById("amt");
    
        // validate description
        if(name === "desc"){
         if(value.trim().length > 60){
            showValidate(desc_input)
            setTransactionDataError({...transactionDataError, [name] : true})
        }else {
            setTransactionDataError({...transactionDataError, [name] : false})
            hideValidate(desc_input)
          }
    
        }
    
    
        //validate Amount numbers
        if(name === "amount"){
              if (value.trim() === "") {
                showValidate(amt)
                setTransactionDataError({...transactionDataError, [name] : true})
              } else if (Number(value.trim()) <= 0) {
                showValidate(amt)
                setTransactionDataError({...transactionDataError, [name] : true})
              }else if (String(value.trim()).includes('.')) {
                showValidate(amt)
                setTransactionDataError({...transactionDataError, [name] : true})
              } else {
                hideValidate(amt);
                setTransactionDataError({...transactionDataError, [name] : false})
              }
    
        }
    
    }

    const PostTransaction = async (e) =>{
        e.preventDefault()
        setLoading(true)

        let {person_name, transactionType, UName} = ledgersDetails[0]
        if(transactionType === "Lend"){
            if(rightArrow){
                transactionData.from =  person_name
                transactionData.to = UName
            } else{
                transactionData.from = UName 
                transactionData.to = person_name
            }

        }else{
            if(rightArrow){
                transactionData.to =  person_name
                transactionData.from = UName
            } else{
                transactionData.to = UName
                transactionData.from = person_name
            }
        }
        let dt
        transactionDate !== null ? dt = transactionDate.$D+"/"+(transactionDate.$M+1)+"/"+transactionDate.$y : dt = null
        transactionData.date = dt
        dt === null? setDateError(true) : setDateError(false)
        transactionData.mode = transactionMode
        let {desc, amount, date } = transactionData
        if( amount === "" && transactionData.date === null){
            document.getElementById("amt").focus()
            toast.error("Please fill all the fields",{duration: 1500})
        }else if(amount === "" || transactionData.date === null){
            HandelOnBlur("amount", amount)
            HandelOnBlur("date", date)
            HandelOnBlur("desc", desc)
            setLoading(false)
        }else{

            // if(transactionData.date === null){
            //     transactionDataError.date = true
            //     setDateError(true)
            // }

            let validated = Object.keys(transactionDataError).every(function(k){ return transactionDataError[k] === false });
            if(validated){
                let {newBalance, newAmount} = Calculation()
                // let amt = document.getElementById("amt");

                // if(newBalance < 0 && newAmount < ledgersDetails[0]?.amount){
                //     transactionType === "Lend" ? 
                //     toast.error(`You are receiving extra ${Math.abs(newBalance)} `)
                //     :
                //     toast.error(`You are giving extra ${Math.abs(newBalance)} `)
                //     transactionDataError.amount = true

                // }
                // else{
                    transactionData.balance = newBalance
                    // let status = newBalance === 0? false: true;

                    // console.log("transaction post =>", id, newBalance, newAmount, transactionData)

                    try {
                
                        const res = await fetch(`${process.env.REACT_APP_BASE_URL}/add-friends-transaction/${id}`, {
                          method: "POST",
                          headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                          },
                          credentials: "include",
                          body: JSON.stringify({
                            from : transactionData.from,to: transactionData.to,
                            amount: transactionData.amount, balance: newBalance, 
                            date, desc: transactionData.desc, mode: transactionData.mode , new_amount: newAmount
                          })
                        });
                        // console.log(res.status)
                        // console.log(res.body) 
                        if (!res.status === 201) {
                        //   throw new Error(res.error);
                            toast.error("Oops something went wrong",{duration: 1500})
                        }else{
                            const data =  await res.json();
                            // console.log("from the checking component==>",data);
                            // dispatch(getUser())
                            toast.success(data.message,{duration: 1500})
                            dispatch(getLedgers(userData?.user?._id))
                            setLoading(false)
                            // status? toast.success(data.message) : toast.success("Last transaction added successfully!!..")
                            handelClose()
                        }
                  
                      } catch (err) {
                        console.log(err);
                        setLoading(false)
                        dispatch(getLedgers(userData?.user?._id))
                        toast.error("Oops something went wrong!!..",{duration: 1500})
                      }

                    // dispatch(addTransaction({id, newBalance, newAmount, status, transactionData}))
                    
                // }

            }
        }
        setLoading(false)
        dispatch(getLedgers(userData?.user?._id))
    }

    const Calculation = () =>{
        let newBalance = 0
        let newAmount = 0
        // console.log(ledgersDetails[id])
        let {amount, balanceAmount} = ledgersDetails[0]
        
        // if(rightArrow){
        if(rightArrow){
            // if(Number(balanceAmount) - Number(transactionData.amount) === 0){
            //     newBalance = 0
            //     newAmount = amount
            // }else 
            // if(Number(balanceAmount) - Number(transactionData.amount) > 0){
            //     newBalance = Number(balanceAmount) - Number(transactionData.amount)
            //     newAmount = amount
            // }else 
            if(Number(balanceAmount) - Number(transactionData.amount) < 0){
                newBalance = Number(balanceAmount) - Number(transactionData.amount)
                if(Number(amount) < Number(newBalance)){
                    newAmount = amount
                }else{
                    newAmount = newBalance
                }
            }else{
                newBalance = Number(balanceAmount) - Number(transactionData.amount)
                if(Number(amount) > Number(newBalance)){
                    newAmount = amount
                }else{
                    newAmount = newBalance
                }
                newAmount = amount
            }
        }else{
            if(Number(balanceAmount) + Number(transactionData.amount) > amount){
                newBalance = Number(balanceAmount)+Number(transactionData.amount)
                newAmount = newBalance
                if(newBalance > 0){
                    newAmount = newBalance
                }else{
                    newAmount = amount
                }
            }else if(Number(balanceAmount) + Number(transactionData.amount) > Number(balanceAmount) && Number(balanceAmount) + Number(transactionData.amount) < Number(amount)){
                newBalance = Number(balanceAmount)+Number(transactionData.amount)
                newAmount = amount
            }else{
                // console.log("reached the arrow left else statement")
            }
        }
        // console.log(newBalance, newAmount)

        return {newBalance, newAmount}
    }

    useEffect(() => {
    },[ledgersDetails]);
    
  return (
    <>
    {
        loading ? <Spinners /> :
            <Modal
                show={show}
                onHide={handelClose}
                backdrop="static"
                centered
                keyboard={false}
                scrollable
            >
                <Modal.Header closeButton className='border-bottom-0 py-1' >
                <Modal.Title>New Transaction</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <form className=' pb-3 px-1 fs-4 position-relative  rounded-4 m-auto  ' >

                    <div className="members_list  pt-3 pb-4 fs-5 d-flex">
                        <div className="from_member m_block">
                             {ledgersDetails[0]?.transactionType === "Lend"? ledgersDetails[0]?.person_name : ledgersDetails[0]?.UName}
                         </div>
                        <div onClick={()=> setRightArrow(!rightArrow)} className='arrow_block'>{ rightArrow? <FaLongArrowAltRight className='fs-3' /> : <FaLongArrowAltLeft  className='fs-3' /> } </div>
                        <div className="to_member m_block">
                            {ledgersDetails[0]?.transactionType === "Lend"?  ledgersDetails[0]?.UName : ledgersDetails[0]?.person_name }
                        </div>
                    </div>

                    <div className=" amt_input  input-group mb-3 fs-5 d-flex align-items-center">
                    <label className="form-label">Amount: </label>
                    <input type="number" className="form-control" onWheel={(e) => e.target.blur()}
                    name="amount"
                    onBlur={() => HandelOnBlur("amount", transactionData.amount)}
                    value={transactionData.amount} 
                    onChange={inputEvent}
                    id="amt"
                    placeholder="eg:- 500"/>
                        <span className="input-group-text">.00</span>
                        <span className={`position-absolute top-0 end-0 me-5 text-danger ${!transactionDataError.amount?"d-none":"" } fs-5`}
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="Please enter amount."
                            data-tooltip-variant="error"
                         > <MdErrorOutline/> </span>
                    </div>

                    <div className=' amt_input input-group mb-3 fs-5 d-flex align-items-center' >
                        <label  className="form-label  mb-0 fs-5">Mode: </label>
                        <select className="form-select"
                        value={transactionMode}
                        onChange={(e)=> setTransactionMode(e.target.value) }
                        aria-label="Default select example">
                            <option value="online" >Online</option>
                            <option value="cash">Cash</option>
                        </select>
                    </div>
                                    
                            {/* Date */}
                            <Suspense fallback={<Spinner  animation="border" variant="primary" />} >
                                <div className="mb-3   ">
                                <label  className="form-label  mb-0 fs-5">Date of transition: </label>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                    <DatePicker className="col-lg-12 py-0 " value={transactionDate} 
                                    onChange={(newValue) => {
                                        setTransactionDate(newValue)
                                        setDateError(false)
                                        }} 
                                        format="DD-MM-YYYY"
                                        disableFuture
                                        minDate={dayjs(ledgersDetails[0]?.transactionDate?.split('/')?.reverse()?.join('/'))}
                                        slotProps={{ textField: { size: 'small' } }}
                                        sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                            borderColor: `${dateError? 'red':'grey'}`,
                                            },
                                            '&:hover fieldset': {
                                            borderColor: 'rgb(206, 206, 206)',
                                            },
                                            '&.Mui-focused fieldset': {
                                            borderColor: 'rgb(206, 206, 206)',
                                            },
                                        },
                                        }}
                                    />
                                    </DemoContainer>
                                </LocalizationProvider>
                                </div>
                            </Suspense>

                        <div className="mb-3">
                            <label  className="form-label  mb-0 fs-5">Description: </label>
                            <div className='position-relative'>
                                <textarea className="form-control"  style={{resize:"none",height:"60px"}} 
                                placeholder="Description..."
                                name="desc"
                                onBlur={() => HandelOnBlur("desc", transactionData.desc)}
                                value={transactionData.desc} 
                                onChange={inputEvent}
                                maxLength="60"
                                id="desc_input"
                                >
                                    
                                </textarea>
                                <span className={`position-absolute top-0 end-0 me-2 text-danger ${!transactionDataError.desc?"d-none":"" } fs-5`}
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Please write the description in 130 characters."
                                data-tooltip-variant="error"
                                > <MdErrorOutline/> </span>

                            </div>
                        </div>

                    </form>
                </Modal.Body>
                <Modal.Footer className='border-top-0 py-1' >
                <Button variant="secondary" onClick={handelClose}>
                    Close
                </Button>
                <Button variant="primary" disabled={loading} onClick={PostTransaction} >Add</Button>
                </Modal.Footer>
                <Tooltip id="my-tooltip" className="tooltip_style" />
            </Modal>
    }

    </>
  )
}

// Shopping Transaction Model
export const ShoppingTransactionModels = ({closeFun,show,id}) => {

    const [loading, setLoading] = useState(false);
    const [transactionMode, setTransactionMode] = useState('online');

    const [shoppingItems, setShoppingItems] = useState({
        item_name: '',
        amount: '',
        balance: '',
        date:''
    });

    const [shoppingItemsError, setShoppingItemsError] = useState({
        item_name: false,
        amount: false,
    });
    
    const inputEvent = (e) => {
        let { name, value } = e.target;
        // console.log(name, value); 
        setShoppingItems({ ...shoppingItems, [name]: value })
    
    }

    const dispatch = useDispatch()
    
    const userData = useSelector((state) =>{
        return state.userAuth
    })

    const shoppingLedgers = useSelector((state) =>{
        return state.shoppingLedgers
    })
    
    let shoppingDetails = shoppingLedgers?.data?.filter((item) => {
        return item._id === id ;
    });


    const hideValidate = (ele) =>{
        ele.style.border = "1px solid gray";
      }
    const showValidate = (ele) =>{
        ele.style.border = "1px solid red";
      }

    const handelClose = () =>{

        setShoppingItems({
            item_name: '',
            amount: '',
            balance: '',
            date:''
        })

        setShoppingItemsError({
            item_name: false,
            amount: false,
        })

        setTransactionMode('online')
        closeFun()
    }

    const HandelOnBlur = (name, value) =>{

        let item_input = document.getElementById("item_input");
        let amt = document.getElementById("amt");
    
        // validate description
        if(name === "item_name"){
          if (value.trim() === "") {
            showValidate(item_input)
            setShoppingItemsError({...shoppingItemsError, [name] : true})
          }else {
            setShoppingItemsError({...shoppingItemsError, [name] : false})
            hideValidate(item_input)
          }
    
        }
    
    
        //validate Amount numbers
        if(name === "amount"){
              if (value.trim() === "") {
                showValidate(amt)
                setShoppingItemsError({...shoppingItemsError, [name] : true})
              } else if (Number(value.trim()) <= 0) {
                showValidate(amt)
                setShoppingItemsError({...shoppingItemsError, [name] : true})
              }else if (String(value.trim()).includes('.')) {
                showValidate(amt)
                setShoppingItemsError({...shoppingItemsError, [name] : true})
              } else {
                hideValidate(amt);
                setShoppingItemsError({...shoppingItemsError, [name] : false})
              }
    
        }
    
    }

    const PostShoppingTransaction =  async (e) =>{
        e.preventDefault()
        setLoading(true)

        let {item_name, amount } = shoppingItems
        if(item_name === "" && amount === ""){
            document.getElementById("item_input").focus()
            toast.error("please fill the fields",{duration: 1500})
        }else if(item_name === "" || amount === ""){
            HandelOnBlur("item_name", item_name)
            HandelOnBlur("amount", amount)
        }else{
            let validated = Object.keys(shoppingItemsError).every(function(k){ return shoppingItemsError[k] === false });
            if(validated){

                let d = new Date()
                shoppingItems.date = d.toLocaleString()
                // console.log(shoppingDetails[id])
                if(shoppingDetails[0].fixed){
                    let total = Number(shoppingDetails[0].totalAmount) + Number(shoppingItems.amount)
                    let balance =  Number(shoppingDetails[0].balanceAmount) - Number(shoppingItems.amount)
                    
                    shoppingItems.balance = balance

                    // console.log(id, total, balance, shoppingItems)
                    // console.log("Added transaction!!")

                    // const {amount,balance, date, item_name} = shoppingItems
                    
                    try {
                
                        const res = await fetch(`${process.env.REACT_APP_BASE_URL}/add-shopping-transaction/${id}`, {
                          method: "POST",
                          headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                          },
                          credentials: "include",
                          body: JSON.stringify({
                            item_name ,amount, balance, date:shoppingItems.date, total, newBalance: balance, mode:transactionMode
                          })
                        });
                        // console.log(res.status)
                        // console.log(res.body) 
                        if (res.status === 400 || res.status === 500) {
                        //   throw new Error(res.error);
                            toast.error("Oops something went wrong",{duration: 1500})
                        }else{
                            const data =  await res.json();
                            if(balance < 0){
                                toast.warning(`You are exceeding your fixed Budget by ${Math.abs(balance)}`,{duration: 1500})
                            }else{
                                toast.success(data.message,{duration: 1500})
                            }
                            dispatch(getShoppingLedgers(userData?.user?._id))
                          }
                          
                        } catch (err) {
                          console.log(err);
                          toast.error("Oops something went wrong!!..",{duration: 1500})
                        }
                        setLoading(false)

                    // dispatch(addShoppingTransaction({id, total,balance, shoppingItems}))

                }else{
                    let total = Number(shoppingDetails[0].totalAmount) + Number(shoppingItems.amount)
                    let balance = 0
                    shoppingItems.balance = total
                    // toast.success("Item added!!..")
                    // console.log(id, total, balance, shoppingItems)

                    try {
                
                        const res = await fetch(`${process.env.REACT_APP_BASE_URL}/add-shopping-transaction/${id}`, {
                          method: "POST",
                          headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                          },
                          credentials: "include",
                          body: JSON.stringify({
                            item_name, amount:Number(amount), balance:shoppingItems.balance, date:shoppingItems.date, total, newBalance: balance, mode:transactionMode
                          })
                        });
                        // console.log(res.status)
                        // console.log(res.body) 
                        if (res.status === 400 || res.status === 500) {
                        //   throw new Error(res.error);
                            toast.error("Oops something went wrong",{duration: 1500})
                        }else{
                            const data =  await res.json();
                            if(balance < 0){
                                toast.warning(`You are exceeding your fixed Budget by ${Math.abs(balance)}`,{duration: 1500})
                            }else{
                                toast.success(data.message,{duration: 1500})
                            }
                            dispatch(getShoppingLedgers(userData?.user?._id))
                          }
                          setLoading(false)
                          
                        } catch (err) {
                          console.log(err);
                          setLoading(false)
                          toast.error("Oops something went wrong!!..",{duration: 1500})
                        }
                    // dispatch(addShoppingTransaction({id, total,balance, shoppingItems}))
                    
                }
               
                handelClose()
            }
        }
        setLoading(false)
    }

    // useEffect(() => {
        // console.log("shopping data=>",shoppingLedgers)
    // }, []);


  return (
    <>
    {
        loading ? <Spinners /> :
            <Modal
                show={show}
                onHide={handelClose}
                backdrop="static"
                centered
                keyboard={false}
                scrollable
            >
                <Modal.Header closeButton className='border-bottom-0 py-1'>
                <Modal.Title>New Transaction</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className=' pb-3 px-1 fs-4 position-relative  rounded-4 m-auto  ' >

                         <div className="mb-3">
                                <label htmlFor="recipient-name" className="col-form-label">Item Name </label>
                                <div className=" position-relative">
                                    <input type="text" className="form-control" 
                                    name="item_name"
                                    onBlur={() => HandelOnBlur("item_name", shoppingItems.item_name)}
                                    value={shoppingItems.item_name} 
                                    onChange={inputEvent}
                                    maxLength="50"
                                    id="item_input"
                                    />
                                    <span className={` position-absolute top-0 end-0 me-2 text-danger ${!shoppingItemsError.item_name?"d-none":"" } fs-5`}
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="Please enter the title in 25 characters"
                                        data-tooltip-variant="error"
                                    > <MdErrorOutline/> </span>
                                </div>
                            </div>
                        <div className=" amt_input  input-group mb-3 fs-5 d-flex align-items-center">
                            <label className="form-label">Amount: </label>
                            <input type="number" className="form-control" onWheel={(e) => e.target.blur()}
                            name="amount"
                            onBlur={() => HandelOnBlur("amount", shoppingItems.amount)}
                            value={shoppingItems.amount} 
                            onChange={inputEvent}
                            id="amt"
                            placeholder="eg:- 500"/>
                                <span className="input-group-text">.00</span>
                                <span className={`position-absolute top-0 end-0 me-5 text-danger ${!shoppingItemsError.amount?"d-none":"" } fs-5`}
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content="Please enter amount."
                                    data-tooltip-variant="error"
                                > <MdErrorOutline/> </span>
                        </div>
                        <div className=' amt_input input-group mb-3 fs-5 d-flex align-items-center' >
                                <label  className="form-label  mb-0 fs-5">Mode: </label>
                                <select className="form-select"
                                value={transactionMode}
                                onChange={(e)=> setTransactionMode(e.target.value) }
                                aria-label="Default select example">
                                    <option value="online" >Online</option>
                                    <option value="cash">Cash</option>
                                </select>
                        </div>

                     </form>
                </Modal.Body>
                <Modal.Footer className='border-top-0 py-1'>
                <Button variant="secondary" onClick={handelClose}>
                    Close
                </Button>
                <Button variant="primary" disabled={loading} onClick={PostShoppingTransaction} >Save</Button>
                </Modal.Footer>
            </Modal>
    }

    </>
  )
}

// Billing Transaction Model
export const BillingTransactionModels = ({closeFun,show,id}) => {

    const [loading, setLoading] = useState(false);
    const [transactionDate, setTransactionDate] = useState(dayjs(new Date()));
    const [dateError, setDateError] = useState(false);
    const [transactionMode, setTransactionMode] = useState('online');

    const [billingItems, setBillingItems] = useState({
        item_name: '',
        amount: '',
        balance: '',
        date:''
    });

    const [billingItemsError, setBillingItemsError] = useState({
        item_name: false,
        amount: false,
    });
    
    const inputEvent = (e) => {
        let { name, value } = e.target;
        // console.log(name, value); 
        setBillingItems({ ...billingItems, [name]: value })
    
    }

    const dispatch = useDispatch()
    
    const userData = useSelector((state) =>{
        return state.userAuth
    })

    const billTracker = useSelector((state) =>{
        return state.billTracker
    })
    
    let billTrackerDetails = billTracker?.data?.filter((item) => {
        return item._id === id ;
    });


    const hideValidate = (ele) =>{
        ele.style.border = "1px solid gray";
      }
    const showValidate = (ele) =>{
        ele.style.border = "1px solid red";
      }

    const handelClose = () =>{

        setBillingItems({
            item_name: '',
            amount: '',
            balance: '',
            date:''
        })

        setBillingItemsError({
            item_name: false,
            amount: false,
        })

        setTransactionDate(dayjs(new Date()))
        setDateError(false)
        setTransactionMode('online')

        closeFun()
    }

    const HandelOnBlur = (name, value) =>{

        let item_input = document.getElementById("item_input");
        let amt = document.getElementById("amt");
    
        // validate description
        if(name === "item_name"){
          if (value.trim() === "") {
            showValidate(item_input)
            setBillingItemsError({...billingItemsError, [name] : true})
          }else {
            setBillingItemsError({...billingItemsError, [name] : false})
            hideValidate(item_input)
          }
    
        }
    
    
        //validate Amount numbers
        if(name === "amount"){
              if (value.trim() === "") {
                showValidate(amt)
                setBillingItemsError({...billingItemsError, [name] : true})
              } else if (Number(value.trim()) <= 0) {
                showValidate(amt)
                setBillingItemsError({...billingItemsError, [name] : true})
              }else if (String(value.trim()).includes('.')) {
                showValidate(amt)
                setBillingItemsError({...billingItemsError, [name] : true})
              } else {
                hideValidate(amt);
                setBillingItemsError({...billingItemsError, [name] : false})
              }
    
        }
    
    }

    const PostBillTrackerTransaction =  async (e) =>{
        e.preventDefault()
        setLoading(true)

        let {item_name, amount } = billingItems
        transactionDate !== null ? billingItems.date = transactionDate.$D+"/"+(transactionDate.$M+1)+"/"+transactionDate.$y : billingItems.date = null
        if(item_name === "" && amount === ""){
            document.getElementById("item_input").focus()
            toast.error("please fill the fields",{duration: 1500})
        }else if(item_name === "" || amount === ""){
            HandelOnBlur("item_name", item_name)
            HandelOnBlur("amount", amount)
        }else{
            let validated = Object.keys(billingItemsError).every(function(k){ return billingItemsError[k] === false });
            if(validated){

                // billingItems.date = transactionDate
                // console.log(billTrackerDetails[id])
                if(billTrackerDetails[0].fixed){
                    let total = Number(billTrackerDetails[0].totalAmount) + Number(billingItems.amount)
                    let balance =  Number(billTrackerDetails[0].balanceAmount) - Number(billingItems.amount)
                    
                    billingItems.balance = balance

                    // console.log(id, total, balance, billingItems)
                    // console.log("Added transaction!!")

                    // const {amount,balance, date, item_name} = billingItems
                    
                    try {
                
                        const res = await fetch(`${process.env.REACT_APP_BASE_URL}/add-bill-tracker-transaction/${id}`, {
                          method: "POST",
                          headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                          },
                          credentials: "include",
                          body: JSON.stringify({
                            item_name ,amount, balance, date:billingItems.date, total, newBalance: balance, mode:transactionMode
                          })
                        });
                        // console.log(res.status)
                        // console.log(res.body) 
                        if (res.status === 400 || res.status === 500) {
                        //   throw new Error(res.error);
                            toast.error("Oops something went wrong",{duration: 1500})
                        }else{
                            const data =  await res.json();
                            if(balance < 0){
                                toast.warning(`You are exceeding your fixed Budget by ${Math.abs(balance)}`,{duration: 1500})
                            }else{
                                toast.success(data.message,{duration: 1500})
                            }
                            dispatch(getBillTracker(userData?.user?._id))
                          }
                          
                        } catch (err) {
                          console.log(err);
                          toast.error("Oops something went wrong!!..",{duration: 1500})
                        }
                        setLoading(false)

                    // dispatch(addShoppingTransaction({id, total,balance, billingItems}))

                }else{
                    let total = Number(billTrackerDetails[0].totalAmount) + Number(billingItems.amount)
                    let balance = 0
                    billingItems.balance = total
                    // toast.success("Item added!!..")
                    // console.log(id, total, balance, billingItems)

                    try {
                
                        const res = await fetch(`${process.env.REACT_APP_BASE_URL}/add-bill-tracker-transaction/${id}`, {
                          method: "POST",
                          headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                          },
                          credentials: "include",
                          body: JSON.stringify({
                            item_name ,amount, balance:billingItems.balance , date:billingItems.date, total,
                             newBalance: balance, mode:transactionMode
                          })
                        });
                        // console.log(res.status)
                        // console.log(res.body) 
                        if (res.status === 400 || res.status === 500) {
                        //   throw new Error(res.error);
                            toast.error("Oops something went wrong",{duration: 1500})
                        }else{
                            const data =  await res.json();
                            if(balance < 0){
                                toast.warning(`You are exceeding your fixed Budget by ${Math.abs(balance)}`,{duration: 1500})
                            }else{
                                toast.success(data.message,{duration: 1500})
                            }
                            dispatch(getBillTracker(userData?.user?._id))
                          }
                          setLoading(false)
                          
                        } catch (err) {
                          console.log(err);
                          setLoading(false)
                          toast.error("Oops something went wrong!!..",{duration: 1500})
                        }
                    // dispatch(addShoppingTransaction({id, total,balance, billingItems}))
                    
                }
               
                handelClose()
            }
        }
        setLoading(false)
    }

    // useEffect(() => {
        // console.log("shopping data=>",shoppingLedgers)
    // }, []);


  return (
    <>
    {
        loading ? <Spinners /> :
            <Modal
                show={show}
                onHide={handelClose}
                backdrop="static"
                centered
                keyboard={false}
                scrollable
            >
                <Modal.Header closeButton className='border-bottom-0 py-1'>
                <Modal.Title>New Transaction</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className=' pb-3 px-1 fs-4 position-relative  rounded-4 m-auto  ' >

                         <div className="mb-3">
                                <label htmlFor="recipient-name" className="col-form-label">Item Name </label>
                                <div className=" position-relative">
                                    <input type="text" className="form-control" 
                                    name="item_name"
                                    onBlur={() => HandelOnBlur("item_name", billingItems.item_name)}
                                    value={billingItems.item_name} 
                                    onChange={inputEvent}
                                    maxLength="50"
                                    id="item_input"
                                    />
                                    <span className={` position-absolute top-0 end-0 me-2 text-danger ${!billingItemsError.item_name?"d-none":"" } fs-5`}
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="Please enter the title in 25 characters"
                                        data-tooltip-variant="error"
                                    > <MdErrorOutline/> </span>
                                </div>
                            </div>
                        <div className=" amt_input  input-group mb-3 fs-5 d-flex align-items-center">
                            <label className="form-label">Amount: </label>
                            <input type="number" className="form-control" onWheel={(e) => e.target.blur()}
                            name="amount"
                            onBlur={() => HandelOnBlur("amount", billingItems.amount)}
                            value={billingItems.amount} 
                            onChange={inputEvent}
                            id="amt"
                            placeholder="eg:- 500"/>
                                <span className="input-group-text">.00</span>
                                <span className={`position-absolute top-0 end-0 me-5 text-danger ${!billingItemsError.amount?"d-none":"" } fs-5`}
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content="Please enter amount."
                                    data-tooltip-variant="error"
                                > <MdErrorOutline/> </span>
                        </div>
                         <div className=' amt_input input-group mb-3 fs-5 d-flex align-items-center' >
                                <label  className="form-label  mb-0 fs-5">Mode: </label>
                                <select className="form-select"
                                value={transactionMode}
                                onChange={(e)=> setTransactionMode(e.target.value) }
                                aria-label="Default select example">
                                    <option value="online" >Online</option>
                                    <option value="cash">Cash</option>
                                </select>
                        </div>

                         {/* Date */}
                         <Suspense fallback={<Spinner  animation="border" variant="primary" />} >
                                <div className="mb-3   ">
                                <label  className="form-label  mb-0 fs-5">Date of transition: </label>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                    <DatePicker className="col-lg-12 py-0 " value={transactionDate} 
                                    onChange={(newValue) => {
                                        setTransactionDate(newValue)
                                        setDateError(false)
                                        }} 
                                        format="DD-MM-YYYY"
                                        disableFuture
                                        minDate={dayjs(billTrackerDetails[0]?.billDate?.split('/')?.reverse()?.join('/'))}
                                        slotProps={{ textField: { size: 'small' } }}
                                        sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                            borderColor: `${dateError? 'red':'grey'}`,
                                            },
                                            '&:hover fieldset': {
                                            borderColor: 'rgb(206, 206, 206)',
                                            },
                                            '&.Mui-focused fieldset': {
                                            borderColor: 'rgb(206, 206, 206)',
                                            },
                                        },
                                        }}
                                    />
                                    </DemoContainer>
                                </LocalizationProvider>
                                </div>
                            </Suspense>

                     </form>
                </Modal.Body>
                <Modal.Footer className='border-top-0 py-1'>
                <Button variant="secondary" onClick={handelClose}>
                    Close
                </Button>
                <Button variant="primary" disabled={loading} onClick={PostBillTrackerTransaction} >Save</Button>
                </Modal.Footer>
            </Modal>
    }

    </>
  )
}

// logOut All Modals
export const LogoutAllModal = ({closeFun, show}) => {

    const navigate = useNavigate()

    return(
        <>

            <Modal
            show={show}
            onHide={closeFun}
            backdrop="static"
            keyboard={false}
            centered
            >
                    <Modal.Body>
                        <div className="mb-3 position-relative text-center ">
                            <p className='fw-bold'>
                            Are you sure you want to logout from all devices

                            </p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='border-top-0 py-1'>
                    <Button variant="primary" onClick={closeFun}>
                        Cancel
                    </Button>
                    <button onClick={ ()=> {
                        closeFun()
                        navigate("/logout/all") 
                        }} className='btn btn-danger'> logout </button>
                    </Modal.Footer>
                    <Tooltip id="my-tooltip" className="tooltip_style" />
                </Modal>

        </>
    )
} 

// logOut Modals
export const LogoutModal = ({closeFun, show}) => {

    const navigate = useNavigate()

    return(
        <>

                <Modal
                    show={show}
                    onHide={closeFun}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Body>
                        <div className=" position-relative text-center  ">
                            <p className='fw-bold '>
                            Are you sure you want to logout

                            </p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='border-top-0 py-1 '>
                    <Button variant="primary" onClick={closeFun}>
                        Cancel
                    </Button>
                    <button onClick={ ()=> {
                        closeFun()
                        navigate("/logout/single") 
                        }} className='btn btn-danger'> logout </button>
                    </Modal.Footer>
                    <Tooltip id="my-tooltip" className="tooltip_style" />
                </Modal>

        </>
    )
} 
