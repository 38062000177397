import {useState} from "react";
import "./CSS/login.css"
// import { NavLink, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
// import { loginFunc } from "../../services/Apis";
import { FaUser } from "react-icons/fa";
import { BiSolidLock } from "react-icons/bi";
// import { FaRegUser } from "react-icons/fa6";
import { IoEyeOffOutline,IoEyeOutline } from "react-icons/io5";
import { motion } from "framer-motion";
import Spinners from "../../components/Spinners/Spinners";
import { Link } from "react-router-dom";
import { toast } from 'sonner';
import { Helmet } from 'react-helmet';

// import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";

const Login = () => {

  // const navigate = useNavigate(); 
  // console.log("base URL => ", process.env.REACT_APP_BASE_URL)

  const [showPass, setShowPass] = useState(false);
  // const [isRemember, setIsRemember] = useState(false);
  const [loading, setLoading] = useState(false);

  const [userLoginData, setUserLoginData] = useState({
    userID: '',
    userPwd: '',
});


const inputEvent = (e) => {
    let { name, value } = e.target;
    // console.log(name, value);

    setUserLoginData({ ...userLoginData, [name]: value })

  }
  
const LoginUser = async (e) => {
      e.preventDefault();
  
      setLoading(true)
      let user_name = document.getElementById("InputUserName");
      let pass = document.getElementById("InputuserPwd");
      var email , number
      
      let { userID, userPwd } = userLoginData;
      userID = userID.trim()
      userPwd = userPwd.trim();
      // console.log(userID, userPwd)
  
      // validation
      if(userID === "" &&  userPwd === ""){
  
        toast.error("Please Enter User Id and password",{duration: 1500})
        user_name.style.border = "1px solid red";
        pass.style.border = "1px solid red";
  
      }else if(userID === "" || userPwd === ""){
  
        //validate User Name
        if (userID === "") {
            toast.error("Please Enter email or Phone number",{duration: 1500})
            user_name.style.border = "1px solid red";
          }else {
            user_name.style.border = "1px solid gray";
        }
    
         //validate userPwd
         if (userPwd === "") {
            toast.error("Please Enter the Password",{duration: 1500});
            pass.style.border = "1px solid red";
          }else if(userPwd.length < 6) {
            toast.error("Invalid password",{duration: 1500});
            pass.style.border = "1px solid red";
          }else{
          pass.style.border = "1px solid gray";
        }
        setLoading(false)
      }else{
  
        user_name.style.border = "1px solid gray";
        pass.style.border = "1px solid gray";
  
        if(isNaN(userID)){
          email = userID
          number = undefined
        }else{
          number = userID
          email = undefined
        }
  
        try {
          const res = await fetch(`${process.env.REACT_APP_BASE_URL}/signIn`, {
             method: "POST",
             headers: {
                 "Content-Type": "application/json",
             },
             credentials: "include",
             body: JSON.stringify({
               email,number, password : userPwd
             })
         });
    
         // data = {userID, userPwd}
         // const res = await loginFunc(data,"")
         const data = await res.json();
    
         if (res.status === 400 || !data) { 
    
             toast.error("Invalid Credentials",{duration: 1500});
             pass.style.border = "1px solid red";
             user_name.style.border = "1px solid red";
             setLoading(false)
    
         } else {
             toast.success("Login Successful!!..",{duration: 1500});
             setLoading(false)
             const token = data?.token
             localStorage.setItem('token', token)
             pass.style.border = "1px solid gray";
             user_name.style.border = "1px solid gray";
    
             setTimeout(() => {
               setLoading(false)
              //  navigate('/signin', {replace: true})
               window.location.reload(true)
             }, 1000);
         }
          
        } catch (err) {
          navigator.onLine ? toast.error("Oops something went wrong!!..",{duration: 1500}) :
          toast.warning("No Internet Connection!!..",{duration: 1500})
          setLoading(false)
        }
  
  
        setLoading(false)
      }
      setLoading(false)
  }


// const animation = {
//   initial: {opacity: 0, x: -100},
//   animate: {opacity: 1, x:0},
//   exit: {opacity:0, x: 100}
// }

  return (
    <motion.div 
    // variants={animation}
    initial="initial"
    animate="animate"
    exit="exit"
    transition={{duration: 0.2}}
    >

      <Helmet>
        <link rel="canonical" href="https://friendsledger.com/login"/>
        <title>Login</title>
      </Helmet>
      <div className='row container-fluid login_div m-0  p-0 ' style={{position:"relative"}}  >
        {/* <HomeNavbar/> */}
        <div className=" col-sm-12 d-flex " style={{height:"100%",width:"100%",padding:"0px"}}>

        <form className='col-sm-6 col-lg-4 pb-3 px-4 fs-4 pt-5 rounded-4 m-auto  ' >
          <div className="mb-5 d-flex justify-content-center " >
            {/* <div style={{borderRadius: "50%", width:"120px", height:"120px", backgroundColor:"pink", backgroundImage:"../../../public/images/profile_pics/default.png"}} className="border"  ></div> */}
          <img src={`/images/profile_pics/default.png `} alt="Profile" className="border"  width={"120px"} height={"120px"} style={{borderRadius: "50%"}} />
          {/* <img src={"/images/pro-2.jpg"} alt="Profile Image" className="border"  width={"120px"} height={"120px"} style={{borderRadius: "50%"}} /> */}
          </div>

          <div className="input-group mb-3 fs-5">
            <span className="input-group-text">
              <FaUser/>
            </span>
              <input type="text" className="form-control fs-5 rounded-2 "
              name="userID"
               value={userLoginData.userID}
               onChange={inputEvent}
               placeholder="Email or Number"
               autoComplete="false"
               autoCapitalize="false"
               id="InputUserName"/>
          </div>

          <div className="mb-1 position-relative  ">
            <div className="d-flex">
              <span className="  d-flex justify-content-center align-items-center rounded-2  border border-end-0 fs-5" style={{background:"#f8f9fa",padding:"12px"}}>
                  <BiSolidLock/>
                </span>
                <input type={showPass?"text":"password"} className="form-control fs-5 rounded-2 " 
                placeholder="Password"
                name="userPwd"
                value={userLoginData.userPwd}
                onChange={inputEvent} 
                id="InputuserPwd"/>
                <span className="position-absolute  fs-5  px-2  text-dark"  onClick={()=> showPass? setShowPass(false): setShowPass(true)}
                style={{
                  top:"10%", right:"2%",cursor: "pointer",zIndex:"20"
                }} > {showPass? <IoEyeOffOutline/> : <IoEyeOutline/>} </span>

            </div>

          </div>
            <p className="fs-6  text-end"> <Link to="/forgot-password" className="">forgot Password?</Link> </p>

          <div className="d-grid mb-2">
            <button type="submit" onClick={LoginUser} className="btn btn-primary rounded-2 fs-5">Sign In</button>
          </div>
          <p className="fs-6 text-center mt-3"  > New User?  <NavLink className="text-primary text-decoration-none" to="/register">Sign up</NavLink> </p>
        </form>

        </div>

           {
              loading?<Spinners />:""
            }

      </div>
    </motion.div>
  )
}

export default Login