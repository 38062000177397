import React,{useState, useLayoutEffect, Suspense, useEffect} from 'react'
import { Routes, Route, Navigate, useLocation } from "react-router-dom"
import Login from "./pages/Login/Login"
import Register from "./pages/Register/Register"
import HomePage from './pages/HomePage/HomePage'
import Profile from './pages/Profile/Profile'
import { HomeNavbar } from './components/NavbarContainer/HomeNavbar'
import MainNavbar from './components/NavbarContainer/MainNavbar'
import MainHome from './pages/HomePage/MainHome'
import EditProfile from './pages/Profile/EditProfile'
import { Logout } from './Logout'
import FriendsLedgerDetails from './pages/FriendsLedger/FriendsLedgerDetails'
import FriendsLedgerDashboard from './pages/FriendsLedger/FriendsLedgerDashboard'
import ShoppingLedgerDashboard from './pages/ShoppingLedger/ShoppingLedgerDashboard'
import ShoppingLedgerDetails from './pages/ShoppingLedger/ShoppingLedgerDetails'
import StickyNotes from "./pages/StickyNotes/StickyNotes"
import Spinners, { LoadingName } from './components/Spinners/Spinners'
import SharedLedgersDashboard from './pages/FriendsLedger/SharedLedgersDashboard'
import ForgotPassword from './pages/Login/ForgotPassword'
import ExternalSharedLedger from './pages/FriendsLedger/ExternalSharedLedger'
import ErrorPage from './pages/404ErrorPage/ErrorPage'
import About from './pages/About/About'
import VideoDemos from './pages/About/VideoDemos'
import OfflinePage from './pages/404ErrorPage/OfflinePage'
import BillTrackerDashboard from './pages/BillTracker/BillTrackerDashboard'
import BillTrackerDetails from './pages/BillTracker/BillTrackerDetails'


import { AnimatePresence } from 'framer-motion'

const Routing = () => {

  // const [authenticate, setAuthenticate] = useState('');
  // const [loading, setLoading] = useState(false);
  // let user_log =  localStorage.getItem("login")

  const fetchData = async () => {
    try {
      // console.log("auth called")
      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/user-data-fetch`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        credentials: "include",
      });

      await res.json();
      // console.log("from the checking component==>",res); 
      
      if (res.status === 200) {
        // setAuthenticate(data)
      }else {
        if(localStorage.getItem("token")){
          localStorage.removeItem("token")
          window.location.reload(false);
        }else{
          localStorage.removeItem("token")
        }
        // throw new Error(res.error); 
      }
    } catch(err) {
      // console.log("routing error=> ",err);
      // history.push('/');
    }
  }


  useLayoutEffect(() => {
    if(localStorage.getItem('token')){
      fetchData()
    }
    // setLoading(false)
    setTimeout(() => {
    }, 1000);

  },[]);

  return (
    <>
   
    { 
    navigator.onLine?
    // loading? <Spinners/> : localStorage.getItem("token") ? <UserRoutings/> : <BasicRoutings/>
    localStorage.getItem("token") ? <UserRoutings/> : <BasicRoutings/>
    : <div>No Connection</div> 
    // localStorage.getItem("token") ? <UserRoutings/> : <BasicRoutings/>
    }
        
    </>
  )
}

export default Routing

const BasicRoutings = () =>{

  const pathname  = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname]);

  return(
    <>
      
        <MainNavbar/>
        <Routes>
            <Route path='/' element={<MainHome />} />
            <Route path='/login' element={<Login />} />
            <Route path='/Register' element={<Register />} />
            <Route path='/about' element={<About />} />
            <Route path='/getting-started' element={<VideoDemos />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/shared-ledger/:id' element={<ExternalSharedLedger />} />
            {/* <Route path='/offline' element={<OfflinePage />} /> */}
            <Route
                    path="*"
                    element={<Navigate to="/login" replace />}
              />
              <Route path='/ErrorPage' element={<ErrorPage />} />
        </Routes>

    </>
  )
}

const UserRoutings = () =>{

  const  location = useLocation();
  const [online, setOnline] = useState(navigator.onLine);

  useEffect(() => {
    window.scrollTo(0, 0) 
  }, [location]); 
  
  useEffect(() => {
    setOnline(navigator.onLine)
  }, [online]);

  return(
    <>
    {
      online ?
        <Suspense fallback={<LoadingName/>} >
            <HomeNavbar />
            <AnimatePresence  mode='wait' >
              <Routes key={location.pathname} location={location} > 
                  <Route path='/' element={<HomePage />} />
                  <Route path='/profile' element={<Profile />} />
                  <Route path='/profile-edit' element={<EditProfile />} />
                  <Route path='/friends-ledgers' element={<FriendsLedgerDashboard />} />
                  <Route path='/friends-ledgers-shared' element={<SharedLedgersDashboard />} />
                  <Route path='/friends-ledger-details/:id' element={<FriendsLedgerDetails />} />
                  <Route path='/shared-ledger/:id' element={<ExternalSharedLedger />} />

                  <Route path='/shopping-ledgers' element={<ShoppingLedgerDashboard />} />
                  <Route path='/shopping-ledger-details/:id' element={<ShoppingLedgerDetails />} />
                  <Route path='/billTracker-ledgers' element={<BillTrackerDashboard />} />
                  <Route path='/bill-tracker-details/:id' element={<BillTrackerDetails />} />
                  <Route path='/sticky-notes' element={<StickyNotes />} />
                  <Route path='/logout/:opt' element={<Logout />} />
                  <Route path='/getting-started' element={<VideoDemos />} />
                  {/* <Route path='/offline' element={<OfflinePage />} /> */}
                  <Route path="/login" element={<Navigate to="/" replace />} />
                  <Route path="/register" element={<Navigate to="/" replace />} />
                  <Route
                      path="/signOut"
                      element={()=>{
                        return(<><Spinners/></>)
                      }} 
                  />
                    <Route path='/about' element={<About />} />
                    <Route path='/ErrorPage' element={<ErrorPage />} />
                    <Route path='*' element={<Navigate to="/ErrorPage" replace />} />
                  {/* <Route
                      path="*"
                      element={<Navigate to="/" replace />}
                    /> */}
              </Routes>

            </AnimatePresence>
        </Suspense>

        : <OfflinePage /> 
    }
    </>
  )
}