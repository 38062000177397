import {useState, useEffect, useLayoutEffect, Suspense} from 'react';
import { BsArrowRight, BsCurrencyRupee } from "react-icons/bs";
import { toast } from 'sonner';import "./CSS/FriendsLedgerDetails.css"; 
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { MdEast } from "react-icons/md";
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import {getUser} from "../../store/slices/auth"
import Modal from 'react-bootstrap/Modal';

import { motion } from 'framer-motion';
import { LoadingName } from '../../components/Spinners/Spinners';
import ErrorPage from '../404ErrorPage/ErrorPage';


const ExternalSharedLedger = () => {

    const {id} = useParams();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [data, setData] = useState([]);
    const [data1, setData1] = useState([]);
    const [data2, setData2] = useState([]);
    const [showTransactionHistory, setShowTransactionHistory] = useState(false);
    const [widthSize, setWidthSize] = useState();   
    const [loading, setLoading] = useState(true);

    const handleTransactionHistoryClose = () => setShowTransactionHistory(false);
    const handleTransactionHistoryShow = () => setShowTransactionHistory(true);

    const animation = {
        initial: {opacity: 0, x : -100},
        animate: {opacity: 1, x: 0},
        exit: {opacity:0, x: 100}
    }

    useLayoutEffect(() =>{
        dispatch(getUser())
    },[dispatch])

    useEffect(() => {
        setWidthSize(window.innerWidth)
    }, [data, setWidthSize]);

    useLayoutEffect(() =>{
        setLoading(true)
        const fetchLedgerData = async () => {
            try {
              // console.log("auth called")
              const res = await fetch(`${process.env.REACT_APP_BASE_URL}/friends-ledger/${id}`, {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                credentials: "include",
              });
        
              const data = await res.json();
    
            //   console.log("from the checking component==>",res);
              if(res.status === 404){
                navigate("/ErrorPage",{replace:true})
              }
              
              if (!res.status === 200) {
                  throw new Error(res.error);
                }else{
                    // console.log("data => ",data)
                    setData(data?.ledger)
                    setData1(data?.user1)
                    setData2(data?.user2)
                    setLoading(false)
                }
        
            //   return  data

            } catch (err) {
            //   console.log(err);
                navigate("/ErrorPage",{replace:true})
                toast.error("Oops ledger not found..!!",{duration: 3000})
            //   history.push('/');
            }
          }
        
          fetchLedgerData()
    },[id, navigate])
 
    // console.log(data1?.Profile_pic , data2?.Profile_pic, data)
    // console.log("data1=> ",data1 , "data2=> ",data2)
    // console.log("data=> ",data)

  return ( 
    <motion.div 
    variants={animation}
    initial="initial"
    animate="animate"
    exit="exit"
    transition={{duration: 0.2}}
    style={{ height: "100vh"}}
    // transition={{duration: 1}}
    >

    {
        loading ? 
        <LoadingName />
        :
        data === null ?
        <ErrorPage />
        :
        <Suspense fallback={<LoadingName/>} >
            <div className='main_ledger_component px-lg-3 px-sm-1'>
                <div className="ledger_header_block ">
                    <h2 className='px-2'> {data?.title} </h2>
                    <p className='px-2 text-secondary'> {data?.desc} </p>
                </div>

                {
                    data1?.Profile_pic === undefined && data2 ? data2?.profile_pic === undefined : false ? <LoadingName /> : 
                    <div className='w-100 body_details  d-flex  align-items-center justify-content-center  ' >
                        <div className=' inner_body'>

                            { 
                                data && data1 &&
                                <>
                                    {
                                    data?.transactionType === "Lend" ?  

                                    <div className='logo_container' >
                                        <div>
                                            {
                                                data?.amount > 0 && data ? 
                                                <>
                                                    <img className='img_1 shadow bg-light' loading="lazy" src={`/images/profile_pics/${data?.SUID === ""? "default.png" : data2?.Profile_pic !== undefined ? data2?.Profile_pic : "default.png" }`} alt="profile pic" width="120px" height="120px" />
                                                    <img className='img_2 shadow bg-light' loading="lazy" src={`/images/profile_pics/${data1?.Profile_pic !== undefined ? data1?.Profile_pic  : "default.png" }`} alt="profile pic" width="120px" height="120px" />
                                                </>
                                                : 
                                                <>
                                                    <img className='img_1 shadow bg-light' loading="lazy" src={`/images/profile_pics/${data1?.Profile_pic !== undefined ? data1?.Profile_pic : "default.png" }`} alt="profile pic" width="120px" height="120px" />
                                                    <img className='img_2 shadow bg-light' loading="lazy" src={`/images/profile_pics/${data?.SUID === ""?"default.png" : data2?.Profile_pic !== undefined ? data2?.Profile_pic : "default.png" }`} alt="profile pic" width="120px" height="120px" />
                                                </> 
                                            }
                                        </div>
                                        <p className='position-relative '  > 
                                            {data?.amount > 0 ? data?.person_name : data?.UName }  <BsArrowRight/>  {data?.amount > 0 ? data?.UName : data?.person_name }
                                        </p> 

                                    </div>
                                                        
                                    : 
                                    <div className='logo_container' >
                                            <div>
                                                {
                                                    data?.amount > 0 ? 
                                                    <>
                                                        <img className='img_1 shadow bg-light' loading="lazy" src={`/images/profile_pics/${data1?.Profile_pic !== undefined ? data1?.Profile_pic :"default.png"}`} alt="profile pic" width="120px" height="120px" />
                                                        <img className='img_2 shadow bg-light' loading="lazy" src={`/images/profile_pics/${data?.SUID === ""?"default.png" : data2?.Profile_pic !== undefined ? data2?.Profile_pic : "default.png" }`} alt="profile pic" width="120px" height="120px" />
                                                    </>
                                                    : 
                                                    <>
                                                        <img className='img_1 shadow bg-light' src={`/images/profile_pics/${data?.SUID === ""? "default.png" : data2?.Profile_pic !== undefined ? data2?.Profile_pic : "default.png" }`} alt="profile pic" width="120px" height="120px" />
                                                        <img className='img_2 shadow bg-light' src={`/images/profile_pics/${data1?.Profile_pic !== undefined ? data1?.Profile_pic :"default.png"}`} alt="profile pic" width="120px" height="120px" />
                                                    </> 
                                                }
                                            </div>
                                        <p className='position-relative ' >  
                                        {data?.amount > 0 ? data?.UName : data?.person_name }  <BsArrowRight/>  {data?.amount > 0 ? data?.person_name : data?.UName }
                                        <span 
                                        className={`${data?.transactionType === "Lend"? 'text-success': 'text-danger'}  position-absolute  ms-2 `} > 
                                        </span> </p>

                                    </div>
                                    }
                                </>
                            }

                            <div className=' details_con fs-6'>
                                <div className='blocks py-2' >
                                    <h4 className='principal_amt amt_name ' style={{width:"60%"}}   >Total Amount   </h4>
                                    <h4
                                    className={` amt_num`}
                                    style={{width:"40%"}}
                                    > <BsCurrencyRupee/> {data?.amount ? data?.amount : ""} </h4>
                                </div>
                                <div className='blocks py-2' >
                                    <h4 className='amt_name'  style={{width:"60%"}}  >Balance</h4>
                                    <h4 className=' text-warning  amt_num'  style={{width:"40%"}}   > <BsCurrencyRupee/> {data?.balanceAmount ? data?.balanceAmount : ""} </h4>
                                </div>
                                <div className=' btn_block py-3' >
                                    <div className='mt-2'>
                                        <button className='btn py-2 view_history shadow-sm fs-5'
                                        onClick={handleTransactionHistoryShow}
                                        >Transaction history</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                }


                <Modal show={showTransactionHistory} 
                onHide={handleTransactionHistoryClose}
                dialogClassName="modal-90w"
                size="lg"
                fullscreen={'sm-down'}
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                centered
                keyboard={false}
                scrollable
                >
                    <Modal.Header closeButton className='border-bottom-0 py-1 ' >
                    <Modal.Title>Transaction History</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                <div className="ledger_body_block mt-0"  style={{overflowY: "scroll", maxHeight: widthSize >= 575 ?"50vh" :"100vh"}} >

                        <Table className= "table table-striped table-hover" >
                            <Thead className="table-dark position-sticky top-0"   > 
                                <Tr>
                                {/* <Th>Sl.No</Th> */}
                                <Th>Transaction</Th>
                                <Th>Amount</Th>
                                <Th>Balance</Th>
                                <Th>Mode</Th>
                                <Th>Description</Th>
                                <Th>Date</Th>
                                </Tr>
                            </Thead> 
                            <Tbody >

                                {
                                    data?.transactionData?.map((ele,index)=>{
                                        const {amount, balance, date, desc, from, mode, to} = ele

                                        return(
                                                <Tr className="border-0 border-bottom" key={index} >
                                                {/* <Td>{index+1}</Td> */}
                                                <Td> {from} <MdEast/> {to} </Td> 
                                                <Td ><span>{amount}</span> </Td>
                                                <Td>{balance} </Td>
                                                <Td>{mode} </Td>
                                                <Td> {desc} </Td>
                                                <Td>{date}</Td>
                                                </Tr>
                                        )
                                    })
                                }
                                                    
                            </Tbody>
                        </Table>

                    </div>

                    </Modal.Body>
                </Modal>
                

            </div>
        </Suspense>
    }
    </motion.div>
  )
}

export default ExternalSharedLedger