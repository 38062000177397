import { useEffect, useState } from "react";
import { NavLink, useLocation , useNavigate } from "react-router-dom"
import { TbLogout } from "react-icons/tb";
import { AiOutlineClose } from "react-icons/ai";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdArrowBackIosNew } from "react-icons/md";
import "./CSS/Navbar.css"
import { ImHome } from "react-icons/im";
import { useSelector } from "react-redux";
import { LogoutModal } from "../Modals/Modals";

export const HomeNavbar = () => {

  const [isNavCollapsed, setIsNavCollapsed] = useState(false);
  const [isProfile, setIsProfile] = useState(false);
  const [isProfileEdit, setIsProfileEdit] = useState(false);
  const [isHome, setIsHome] = useState(false);
  const [showLogout, setLogout] = useState(false);
  const [isShared, setIsShared] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  const userData = useSelector((state) =>{
    return state.userAuth
})

const handelLogoutClose = () => setLogout(false);
const handelLogoutShow = () => setLogout(true);

  useEffect(() => {
    // console.log(`navbar is ${isNavCollapsed ? 'opened' : "closed"}`)
    location.pathname === "/" ? setIsHome(true) : setIsHome(false)
    location.pathname.split('/')[1] === `profile` ? setIsProfile(true) : setIsProfile(false)
    location.pathname.split('/')[1] === `profile-edit` ? setIsProfileEdit(true) : setIsProfileEdit(false)
    location.pathname.split('/')[1] === `shared-friends-ledger` ? setIsShared(true) : setIsShared(false)

  //  console.log("location changed")
  },[location.pathname,isNavCollapsed])

  useEffect(() => {
    setIsNavCollapsed(false)
  }, [location, userData]);

  return (
    <>
        
        <nav className=" navbar_container">
          <div className="inner_navbar px-2 px-lg-4"> 
            <div className="brand_name">
              {
                !isHome? 
              <button className="btn ps-0 mb-1 border-0"  onClick={()=> isShared ? navigate('/') : navigate(-1)}  >
                  <MdArrowBackIosNew className="fw-bold " />
              </button>
              :""
              }
              <div className=" navbar-brand d-flex fs-5" style={{fontWeight: "650"}} >
                <div className="brand_image"></div>
                    Friends Ledger 
              </div>

                <div className="more_btn_nav "  style={{visibility: isProfileEdit? "hidden" :""}}   onClick={handleNavCollapse} >  
                {

                  !isNavCollapsed? 
                    <span> <RxHamburgerMenu className="fs-1"  /> </span>
                    :
                    <span><AiOutlineClose className="fs-1"  /></span>

                }
                </div>

            </div>
            <div className="nav_items">

                  {
                    isProfileEdit ? "" : 
                    <>
                <ul className={`align-items-center mb-2 navbar_mobile mb-lg-0 ${isNavCollapsed ? "shadow-lg": ""}`} style={{top:isNavCollapsed?"100%" : "-190%"}} >
                    
                      <li className="nav-item me-lg-2  "> 
                      {
                                  isHome || isProfile ? 
                                    !isHome ? 
                                    <NavLink to="/"  onClick={handleNavCollapse} className={"text-decoration-none d-flex align-items-center fs-5 text-dark "} >
                                     <ImHome style={{width:"37px"}} /> Home 
                                    </NavLink>
                                    :
                                    <NavLink to="/profile"  onClick={handleNavCollapse}
                                    className={`text-decoration-none fs-5 d-flex align-items-center profile_nav rounded-5 px-lg-2 py-1 text-dark `}
                                    style={{width:"150px"}}  >
                                      {/* <img src="/images/user-logo.png" alt="" width="30px" height="30px" className="rounded-circle me-2" />  Monish */}
                                      <img src={`/images/profile_pics/${userData?.user?.Profile_pic === undefined? "default.png":userData?.user?.Profile_pic }`} alt="" width="30px" height="30px" className="rounded-circle me-2" />  {userData?.user?.firstName}
                                    </NavLink>
                                    : 
                                    <>
                                    <div className="d-flex align-items-center mob_nav_items me-lg-0 ">

                                        <div className="nav-item me-lg-3 ">
                                          <NavLink to="/profile"  onClick={handleNavCollapse}
                                            className={` text-decoration-none fs-5 d-flex align-items-center profile_nav rounded-5 px-lg-2 py-1 text-dark `}
                                            style={{width:"150px", textOverflow: "ellipsis"}}  >
                                              {/* <img src="/images/user-logo.png" alt="" width="30px" height="30px" className="rounded-circle me-2" />  Monish */}
                                              <img src={`/images/profile_pics/${userData?.user?.Profile_pic === undefined? "default.png":userData?.user?.Profile_pic }`} alt="" width="30px" height="30px" className="rounded-circle me-2" />  {userData?.user?.firstName}
                                          </NavLink>
                                        </div>
            
                                        <div className="nav-item me-lg-4  ">
                                          <NavLink to="/"  onClick={handleNavCollapse} className={"text-decoration-none d-flex align-items-center fs-5 text-dark  "} >
                                           <ImHome style={{width:"37px"}} />  Home 
                                          </NavLink>
                                        </div> 
                                    </div>
                                    
                                    </>
                                }
                      </li>

                      <li className='nav-item d-flex align-items-center pt-0 '>
                          <div onClick={ () => {
                              handleNavCollapse() 
                              handelLogoutShow()
                            } } className={"text-decoration-none logout_btn_nav fs-5 d-flex align-items-center text-dark "}
                            style={{cursor: "pointer"}}
                               >
                             <TbLogout style={{width:"37px"}} /> logout
                          </div>
                      </li>
                  
                    </ul>
                    </>
                  }

                <LogoutModal closeFun={handelLogoutClose} show={showLogout} />

            </div>
          </div>
        </nav>

          

    </>
  )
}

