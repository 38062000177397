import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


export const getAllUsers = createAsyncThunk("getAllUser", async () =>{
    try {
        // console.log("users called")
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}/get-all-users`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`
          },
          credentials: "include",
        });
  
        if (!res.status === 200) {
            return res.error
        }
        let data =  await res.json();
        // console.log(data);
        return data
  
  
      } catch (err) {
        console.log(err);
        return err
      }
})


const getUsersSlice = createSlice({
    name: "users",
    initialState:{
        loading: false,
        users: [],
        error:''
    },
    extraReducers: (builder) => {
        builder
          .addCase(getAllUsers.pending, (state) => {
            state.loading = true;
          })
          .addCase(getAllUsers.fulfilled, (state, action) => {
            state.loading = false;
            state.users = action.payload;
          })
          .addCase(getAllUsers.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
          });
      },
})

export default  getUsersSlice.reducer
// export const {  } = getUsersSlice.actions
