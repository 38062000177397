import {useState} from "react";
import { toast } from 'sonner';
import { IoEyeOffOutline,IoEyeOutline } from "react-icons/io5";
import {  GoArrowRight  } from "react-icons/go";
import Spinners from "../../components/Spinners/Spinners";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';



const MainHome = () => {

    const [showPass, setShowPass] = useState(false);
    // const [isRemember, setIsRemember] = useState(false);
    const [loading, setLoading] = useState(false);
  
    const [userLoginData, setUserLoginData] = useState({
      userID: '',
      userPwd: '',
  });
  
  
  const inputEvent = (e) => {
      let { name, value } = e.target;
      // console.log(name, value);
  
      setUserLoginData({ ...userLoginData, [name]: value })
  
    }
    
  const LoginUser = async (e) => {
        e.preventDefault();
    
        setLoading(true)
        let user_name = document.getElementById("InputUserName");
        let pass = document.getElementById("InputuserPwd");
        var email , number
        
        let { userID, userPwd } = userLoginData;
        userID = userID.trim()
        userPwd = userPwd.trim();
        // console.log(userID, userPwd)
    
        // validation
        if(userID === "" &&  userPwd === ""){
    
          toast.error("Please Enter User Id and password",{duration: 1500})
          user_name.style.border = "1px solid red";
          pass.style.border = "1px solid red";
    
        }else if(userID === "" || userPwd === ""){
    
          //validate User Name
          if (userID === "") {
              toast.error("Please Enter email or Phone number",{duration: 1500})
              user_name.style.border = "1px solid red";
            }else {
              user_name.style.border = "1px solid gray";
          }
      
           //validate userPwd
           if (userPwd === "") {
              toast.error("Please Enter the Password",{duration: 1500});
              pass.style.border = "1px solid red";
            }else if(userPwd.length < 6) {
              toast.error("Invalid password",{duration: 1500});
              pass.style.border = "1px solid red";
            }else{
            pass.style.border = "1px solid gray";
          }
          setLoading(false)
        }else{
    
          user_name.style.border = "1px solid gray";
          pass.style.border = "1px solid gray";
    
          if(isNaN(userID)){
            email = userID
            number = undefined
          }else{
            number = userID
            email = undefined
          }
    
          try {
            const res = await fetch(`${process.env.REACT_APP_BASE_URL}/signIn`, {
               method: "POST",
               headers: {
                   "Content-Type": "application/json",
               },
               credentials: "include",
               body: JSON.stringify({
                 email,number, password : userPwd
               })
           });
      
           // data = {userID, userPwd}
           // const res = await loginFunc(data,"")
           const data = await res.json();
      
           if (res.status === 400 || !data) { 
      
               toast.error("Invalid Credentials",{duration: 1500});
               pass.style.border = "1px solid red";
               user_name.style.border = "1px solid red";
               setLoading(false)
      
           } else {
               toast.success("Login Successful!!..",{duration: 1500});
               setLoading(false)
               const token = data?.token
               localStorage.setItem('token', token)
               pass.style.border = "1px solid gray";
               user_name.style.border = "1px solid gray";
      
               setTimeout(() => {
                 setLoading(false)
                //  navigate('/signin', {replace: true})
                 window.location.reload(true)
               }, 1000);
           }
            
          } catch (err) {
            navigator.onLine ? toast.error("Oops something went wrong!!..",{duration: 1500}) :
            toast.warning("No Internet Connection!!..",{duration: 1500})
            setLoading(false)
          }
    
    
          setLoading(false)
        }
        setLoading(false)
    }

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://friendsledger.com"/>
      </Helmet>
        <div className="  d-flex align-items-sm-start align-items-md-center align-items-lg-center justify-content-center mx-0 px-2 mt-5 mt-lg-0 mt-md-0 " 
        style={{height: '90svh'}}
         >
            {/* <div className="col-lg-11 col-md-11 col-sm-12 py-4 py-lg-5 row"> */}
            <div className="container px-0 py-4 py-lg-5 row">

                {/* Home page left container */}
                <div className="col-lg-8 col-md-7 col-sm-12 d-flex flex-column align-items-center justify-content-lg-center justify-content-md-center justify-content-center">
                    <div className="col-lg-12 ps-lg-3">
                        <h1 className="fw-bolder fs-2 my-1 position-relative z-3" >Login to Friends-Ledger</h1>
                        <p className="text-secondary fs-5 position-relative z-3" > 
                            Modernized digital system for tracking transactions <br className="d-lg-flex d-none" />involved in lending or borrowing money among friends.
                        </p>
                        <Link to="/about" className="btn btn-outline-primary" >Know More <GoArrowRight/> </Link>
                    </div>
                </div>

                {/* Home right container */}
                <div className="col-lg-4 col-md-5 col-sm-12 px-lg-3 my-lg-0 my-5 my-md-1 "> 

                    <form className='col-lg-12 col-md-11 col-sm-6 pb-3 fs-5 rounded-3 mx-auto px-3 py-4 shadow ' >

                        <div className="input-group mb-3 fs-5">
                            <input type="text" className="form-control fs-5 rounded-2 "
                            name="userID"
                            value={userLoginData.userID}
                            onChange={inputEvent}
                            placeholder="Email or Number"
                            autoComplete="false"
                            autoCapitalize="false"
                            id="InputUserName"/>
                        </div>

                        <div className="mb-1 position-relative  ">
                            <div className="d-flex">
                                <input type={showPass?"text":"password"} className="form-control fs-5 rounded-2 " 
                                placeholder="Password"
                                name="userPwd"
                                value={userLoginData.userPwd}
                                onChange={inputEvent} 
                                id="InputuserPwd"/>
                                <span className="position-absolute  fs-5  px-2  text-dark"  onClick={()=> showPass? setShowPass(false): setShowPass(true)}
                                style={{
                                top:"10%", right:"2%",cursor: "pointer",zIndex:"20"
                                }} > {showPass? <IoEyeOffOutline/> : <IoEyeOutline/>} </span>

                            </div>

                        </div>

                        <div className="d-grid mt-4 mb-2">
                            <button type="submit" onClick={LoginUser} className="btn btn-primary rounded-2 text-white fs-5" >LogIn</button>
                        </div>
                        <p className="fs-6  text-center"> <Link to="/forgot-password" className="text-decoration-none">forgot Password?</Link> </p>
                        <hr />
                        <div className="d-flex justify-content-center mt-4 mb-2">
                            <Link to="/register" className="btn w-75 rounded-2 text-white fs-5" 
                            style={{backgroundColor:"#42b72a"}} >Sign Up</Link>
                        </div>
                    </form>

                </div>
            </div>
           {
              loading?<Spinners />:""
            }
        </div>
    </>
  )
}

export default MainHome