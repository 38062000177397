import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getShoppingLedgers = createAsyncThunk("getShoppingLedgers", async (id) =>{
    try {
        // console.log("shopping ledger called", id)
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}/friends-shopping-data/${id}`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`
          },
          credentials: "include",
        });
  
        if (!res.status === 200) {
        //   throw new Error(res.error);
            return res.error
        }
        
        return await res.json();
        // console.log(data)
        // console.log("from the checking component==>",data);
  
  
  
      } catch (err) {
        console.log("error => ",err);
        return err
      //   history.push('/');
      }
})

const shoppingLedgerSlice = createSlice({
    name: "shoppingLedger",
    initialState: {
        loading: false,
        data:[],
        error:''
        },
    // reducers: {
    //     createShoppingLedger(state, action){
    //         state.push(action.payload);
    //     },
    //     removeShoppingLedger(state, action){
    //         state.splice(action.payload, 1)
    //     },
    //     toggleShoppingStatus(state, action){
    //         state[action.payload[0]].status = action.payload[1]
    //     },
    //     editShoppingLedgerData(state, action){
    //         const { title, theme, desc} = action.payload[1]
    //         state[action.payload[0]].title = title
    //         state[action.payload[0]].desc = desc
    //         state[action.payload[0]].theme = theme
    //     },
    //     addShoppingTransaction(state, action){
    //         const {id, total,balance, shoppingItems} = action.payload
    //         state[id].total_amt = total
    //         state[id].balance_amt = balance
    //         state[id].transactions.push(shoppingItems)
    //      },

    // },

    extraReducers: (builder) => {
        builder
          .addCase(getShoppingLedgers.pending, (state) => {
            state.loading = true;
          })
          .addCase(getShoppingLedgers.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
          })
          .addCase(getShoppingLedgers.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
          });
      },

})

// console.log(shoppingLedgerSlice.actions);

export default  shoppingLedgerSlice.reducer

// export const { } = shoppingLedgerSlice.actions