import { useState, useMemo } from "react";
import "./CSS/cards.css"
// import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight, MdMoreVert, MdOutlineModeEditOutline ,MdDeleteOutline } from "react-icons/md";
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'sonner';
import { getLedgers } from "../../store/slices/FriendsLedgerSlice";
import { getShoppingLedgers } from "../../store/slices/ShoppingLedgerSlice";
import { getBillTracker } from "../../store/slices/BillTrackerSlice";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { BiLinkAlt } from "react-icons/bi";
import { IoEyeOffOutline,IoEyeOutline } from "react-icons/io5";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinners from "../Spinners/Spinners";
import { getStickyNotes } from "../../store/slices/StickyNotesSlice";
import moment from 'moment';


export const LoadingCards = () => {
  return (
    <>
      <div
        className="card  rounded-2 main_cards"
        aria-hidden="true"
      >
        <div className="card-body">
          <h5 className="card-title placeholder-glow">
            <span className="placeholder col-6"></span>
          </h5>
          <p className="card-text placeholder-glow">
            <span className="placeholder col-7"></span>
            <span className="placeholder col-4"></span>
            <span className="placeholder col-4"></span>
            <span className="placeholder col-6"></span>
            <span className="placeholder col-8"></span>
          </p>
          {/* <a
            className="btn btn-primary disabled placeholder col-6"
            aria-disabled="true"
          ></a> */}
        </div>
      </div>
    </>
  );
};

export const LedgersCards =({data,url,theme, id, EditLedgerFun, usersData, authData }) =>{

  const [deleteShow, setDeleteShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {bg, txt_color} = theme;
  const {title ,desc, person_name, status, share, UID, SUID, updatedAt} = data;
  const {_id} = authData
  const [loading, setLoading] = useState(false);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);

  const userData = useSelector((state) =>{
    return state?.userAuth
})

const DeleteLedger = async (e) =>{
  e.preventDefault()
  setLoading(true)
  
  try {
                
    const res = await fetch(`${process.env.REACT_APP_BASE_URL}/delete-friends-ledger/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      },
      credentials: "include",
    });
    // console.log(res.status)
    // console.log(res.body)
    if (res.status === 400) {
    //   throw new Error(res.error);
        toast.error("couldn't delete the ledger!!..",{duration: 1500})
    }else{
        const data =  await res.json();
        // console.log("from the checking component==>",data);
        // dispatch(getUser())
        toast.success(data.message,{duration: 1500})
        dispatch(getLedgers(userData?.user?._id))
    }

  } catch (err) {
    console.log(err);
    toast.error("Oops something went wrong!!..",{duration: 1500})
  }

  setLoading(false)

}

  const handleDeleteShow = () => setDeleteShow(true)
  const handleDeleteClose = () => setDeleteShow(false)

  const lastUpdatedMoment = moment(updatedAt)
  
  const toggleStatus = async (e, id, status) =>{
    e.preventDefault();
    setLoading(true)

    try {
              
      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/toggle-friends-ledger-status/${id}`, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        credentials: "include",
        body: JSON.stringify({
          status
        })
      });
      // console.log(res.status)
      // console.log(res.body) 
      if (res.status === 400 || res.status === 500) {
      //   throw new Error(res.error);
          toast.error("Oops something went wrong",{duration: 1500})
      }else{
          const data =  await res.json();
          toast.success(data.message,{duration: 1500})
          dispatch(getLedgers(userData?.user?._id))
        }
        
      } catch (err) {
        // console.log(err);
        toast.error("Oops something went wrong!!..",{duration: 1500})
      }
      setLoading(false)
}

  useMemo(() => {
    if(data){

      let data1 = usersData?.users?.filter((item) => {
        return item._id === UID ;
      });
      
      if(SUID){
        let data2 = usersData?.users?.filter((item) => {
            return item._id === SUID ;
        });
        setData2(data2)
      }
      setData1(data1)
    }
    
  },[UID,SUID,data,usersData?.users]);

  
  return(
    <>
    {
      loading ? <Spinners /> :
      <> 
            <div  className={`card border-0 main_cards position-relative `}   
              style={{backgroundColor: bg, color: txt_color, height: "155px" }}
            >
              <div className=" card-body" onClick={()=> navigate(`${url}`)}  >
                <h5 className="card-title card_text" style={{width:"85%"}}  >{title}</h5>
                  <div className=" card-text ">
                    {
                        UID === _id ?
                          <div className=" position-relative d-flex align-items-end" >  
                            <img src={`/images/profile_pics/${data1[0]?.Profile_pic !== undefined ? data1[0]?.Profile_pic : "default.png" }`} alt="profile pic" loading="lazy" className=" rounded-circle"
                             style={{width:"25px", height:"25px", backgroundColor:"#F1F0E8"}} />
                            <img src={`/images/profile_pics/${data2[0] ? data2[0]?.Profile_pic: "default.png"}`} alt="profile pic" loading="lazy" className=" rounded-circle position-absolute" 
                            style={{width:"25px", height:"25px", left:"18px", backgroundColor:"#F1F0E8"}} />
                            <span className="ms-4">
                              You, {person_name}
                            </span>
                           </div>                  
                           :
                            <div className=" position-relative d-flex align-items-end" >  
                              <img src={`/images/profile_pics/${data2[0] ? data2[0]?.Profile_pic: "default.png" }`} alt="profile pic" loading="lazy" className=" rounded-circle" 
                              style={{width:"25px", height:"25px", backgroundColor:"#F1F0E8"}} />
                              <img src={`/images/profile_pics/${data1?.Profile_pic !== undefined ? data1[0]?.Profile_pic : "default.png"}`} alt="profile pic" loading="lazy" className=" rounded-circle position-absolute" 
                              style={{width:"25px", height:"25px", left:"18px", backgroundColor:"#F1F0E8"}} />
                              <span className="ms-4">
                               {data1?.firstName}, You
                              </span>
                            </div>                  

                    }
                  </div>                
                  <p className="card-text card_text mt-1" style={{height: "45px", textOverflow: "ellipsis", overflow:"hidden" }} >
                   {desc.length>80? desc.substr(0, 80)+"...": desc}
                    </p>
              </div>
              
             

              <div className={`position-absolute  top-0 end-0  shadow mt-2  px-3 badge_icon fw-bold`}
              style={{backgroundColor: status? "#DA0C81" : "#A459D1", color: "#fff"}}
              > {status? "Active": "Deactive"}</div>
              <Dropdown className={`more_opt position-absolute bottom-0 mb-1 fs-5 me-1 end-0 ${UID === _id ? "" : "d-none" }`}
              
              >
                <Dropdown.Toggle className="border-0 pb-2 text-dark fs-5" style={{background:"transparent"}} >
                  <MdMoreVert />
                </Dropdown.Toggle>

                <Dropdown.Menu className="border-0 shadow-sm"  >

                  <Dropdown.Item   
                  onClick={(e)=> toggleStatus(e,id,!status)}
                  >  {status?  <><IoEyeOffOutline style={{color : "#A459D1" }}  /> Deactive</> : <><IoEyeOutline style={{color : "#DA0C81"}} /> Active</>}</Dropdown.Item>
                  <Dropdown.Item onClick={()=> EditLedgerFun(id)}   > <MdOutlineModeEditOutline className="text-warning" /> Edit</Dropdown.Item>
                  <CopyToClipboard text={`https://www.friendsledger.com/shared-ledger/${id}`}>
                    <Dropdown.Item  onClick={()=> toast.success("Link copied to clipboard!",{duration: 1500}) } > <BiLinkAlt className="text-primary" /> Copy link</Dropdown.Item>
                  </CopyToClipboard>
                  <Dropdown.Item onClick={handleDeleteShow}  > <MdDeleteOutline className="text-danger" /> Delete</Dropdown.Item>
                </Dropdown.Menu>

              </Dropdown>
                  {
                    share?
                    <p className={` position-absolute`} style={{bottom:" -10px", right:"40px", color:"grey"}} > <i>shared</i>  </p>
                    :""
                  }
                  {/* <p className={` position-absolute fs-6`} style={{bottom:" -10px", left:"20px", color:"grey"}} > <i>Created By: {UID === _id ? "You" : data1[0]?.firstName } </i>  </p> */}
                  <p className={` position-absolute fs-6`} style={{bottom:" -10px", left:"20px", color:"grey"}} > <i> { lastUpdatedMoment.fromNow() } </i>  </p>
        </div>

                {/* delete confirmation modal */}
                <Modal show={deleteShow} 
                  onHide={handleDeleteClose}
                  backdrop="static"
                  centered
                  keyboard={false}
                  scrollable>
                    <Modal.Body className="text-center fs-5  border-0">Are you sure do you want to delete <b>"{title}"</b> ledger.</Modal.Body>
                    <Modal.Footer className="border-0" >
                    <Button variant="secondary" onClick={handleDeleteClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={DeleteLedger} >
                        Delete
                    </Button>
                    </Modal.Footer>
                </Modal>
      </>
    }

    </>
  )
}

export const SharedLedgersCards =({data,url,theme, id, usersData, authData  }) =>{

  const navigate = useNavigate()
  const {bg, txt_color} = theme
  const {title ,desc, person_name, status, share, UID, SUID, updatedAt} = data;
  const {_id} = authData
  const dispatch = useDispatch();
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [deleteShow, setDeleteShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDeleteShow = () => setDeleteShow(true)
  const handleDeleteClose = () => setDeleteShow(false)

//   const userData = useSelector((state) =>{
//     return state.userAuth
// })

  const lastUpdatedMoment = moment(updatedAt)

  const userData = useSelector((state) =>{
    return state?.userAuth
  })

  const RemoveLedger = async (e) =>{
    e.preventDefault()
    setLoading(true)
    
    try {
                  
      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/remove-friends-ledger/${id}`, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        credentials: "include",
      });
      // console.log(res.status)
      // console.log(res.body)
      if (res.status === 400) {
      //   throw new Error(res.error);
          toast.error("couldn't delete the ledger!!..",{duration: 1500})
      }else{
          const data =  await res.json();
          // console.log("from the checking component==>",data);
          // dispatch(getUser())
          toast.success(data?.message,{duration: 1500})
          dispatch(getLedgers(userData?.user?._id))
      }

    } catch (err) {
      console.log(err);
      toast.error("Oops something went wrong!!..",{duration: 1500})
    }

    setLoading(false)

  }

  useMemo(() => {

    if(data){
    
        let data1 = usersData?.users?.filter((item) => {
            return item._id === UID ;
        });
    
        let data2 = usersData?.users?.filter((item) => {
            return item._id === SUID ;
        });
        setData1(data1)
        setData2(data2)
    }

  },[UID,SUID,data,usersData?.users]);

  
  return(
    <>

    {
            loading ? <Spinners /> :
            <>
              <div  className={`card border-0 main_cards position-relative `}   
                style={{backgroundColor: bg, color: txt_color, height: "155px" }}
              >
                <div className=" card-body" onClick={()=> navigate(`${url}`)}  >
                  <h5 className="card-title card_text" style={{width:"85%"}}  >{title}</h5>
                  <div className=" card-text ">
                      {
                          UID === _id ?
                            <div className=" position-relative d-flex align-items-end" >  
                              <img src={`/images/profile_pics/${data1[0]?.Profile_pic !== undefined ? data1[0]?.Profile_pic :"default.png"}`} alt="profile pic" loading="lazy" className=" rounded-circle" 
                              style={{width:"25px", height:"25px", backgroundColor:"#F1F0E8"}} />
                              <img src={`/images/profile_pics/${data2[0] ? data2[0]?.Profile_pic: "default.png"}`} alt="profile pic" loading="lazy" className=" rounded-circle position-absolute" 
                              style={{width:"25px", height:"25px", left:"18px", backgroundColor:"#F1F0E8"}} />
                              <span className="ms-4">
                                You, {person_name}
                              </span>
                            </div>                  
                            :
                              <div className=" position-relative d-flex align-items-end" >  
                                <img src={`/images/profile_pics/${data2[0] ? data2[0]?.Profile_pic: "default.png" }`} alt="profile pic" loading="lazy" className=" rounded-circle" 
                                style={{width:"25px", height:"25px", backgroundColor:"#F1F0E8"}} />
                                <img src={`/images/profile_pics/${data1[0]?.Profile_pic !== undefined ? data1[0]?.Profile_pic : "default.png"}`} alt="profile pic" loading="lazy" className=" rounded-circle position-absolute" 
                                style={{width:"25px", height:"25px", left:"18px", backgroundColor:"#F1F0E8"}} />
                                <span className="ms-4">
                                {data1[0]?.firstName}, You 
                                </span>
                              </div>                   

                      }
                    </div>                 
                    <p className="card-text card_text mt-1" style={{height: "45px", textOverflow: "ellipsis", overflow:"hidden" }} >
                    {desc.length>80? desc.substr(0, 80)+"...": desc}
                      </p>
                </div>
                
              

                <div className={`position-absolute  top-0 end-0  shadow mt-2  px-3 badge_icon fw-bold`}
                style={{backgroundColor: status? "#DA0C81" : "#A459D1", color: "#fff"}}
                > {status?"Active":"Deactive"}</div>

                <Dropdown className={`more_opt position-absolute bottom-0 mb-1 fs-5 me-1 end-0 ${SUID === _id ? "" : "d-none" }`}
                
                >
                  <Dropdown.Toggle className="border-0 pb-2 text-dark fs-5" style={{background:"transparent"}} >
                    <MdMoreVert />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="border-0 shadow-sm"  >
                    <CopyToClipboard text={`https://www.friendsledger.com/shared-ledger/${id}`}>
                      <Dropdown.Item  onClick={()=> toast.success("Link copied to clipboard!",{duration: 1500}) } > <BiLinkAlt className="text-primary" /> Copy link</Dropdown.Item>
                    </CopyToClipboard>
                    <Dropdown.Item onClick={handleDeleteShow}  > <MdDeleteOutline className="text-danger" /> Remove</Dropdown.Item>
                  </Dropdown.Menu>

                </Dropdown>
                
                    {
                      share?
                      // <p className={` position-absolute`} style={{bottom:" -10px", right:"40px", color:"grey"}} > <i>shared</i>  </p>
                      <p className={` position-absolute fs-6`} style={{bottom:" -10px", right:"40px", color:"grey"}} > <i> { lastUpdatedMoment.fromNow() } </i>  </p>
                      :""
                    }
                    <p className={` position-absolute fs-6`} style={{bottom:" -10px", left:"20px", color:"grey"}} > <i>Created By: {UID === _id ? "You" : data1[0]?.firstName } </i>  </p>
              </div>

              {/* Remove ledger confirmation modal */}
              <Modal show={deleteShow} 
                    onHide={handleDeleteClose}
                    backdrop="static"
                    centered
                    keyboard={false}
                    scrollable>
                      <Modal.Body className="text-center fs-5  border-0">Are you sure do you want to remove <b>"{title}"</b> ledger.</Modal.Body>
                      <Modal.Footer className="border-0" >
                      <Button variant="secondary" onClick={handleDeleteClose}>
                          Close
                      </Button>
                      <Button variant="danger" onClick={RemoveLedger} >
                          Remove
                      </Button>
                      </Modal.Footer>
              </Modal>
            </>
    }

    </>
  )
}

export const ShoppingLedgerCards =({data,url,theme, id, EditLedgerFun}) =>{

  const [deleteShow, setDeleteShow] = useState(false);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {bg, txt_color} = theme
  const {title ,desc, status, updatedAt} = data 
  const [loading, setLoading] = useState(false);
    
  const userData = useSelector((state) =>{
    return state.userAuth
})
 
  const handleDeleteShow = () => setDeleteShow(true)
  const handleDeleteClose = () => setDeleteShow(false)

  const lastUpdatedMoment = moment(updatedAt)

  const deleteShoppingLedger = async (e, id) =>{
      e.preventDefault()
      setLoading(true)

      try {
                
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}/delete-shopping-ledger/${id}`, {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`
          },
          credentials: "include",
        });
        // console.log(res.status)
        // console.log(res.body) 
        if (res.status === 400 || res.status === 500) {
        //   throw new Error(res.error);
            toast.error("Oops something went wrong",{duration: 1500})
        }else{
            const data =  await res.json();
            toast.success(data.message,{duration: 1500})
            dispatch(getShoppingLedgers(userData?.user?._id))
          }
          
        } catch (err) {
          console.log(err);
          toast.error("Oops something went wrong!!..",{duration: 1500})
        }
        
        handleDeleteClose()
        setLoading(false)
  }

  const toggleStatus = async (e, id, status) =>{
      e.preventDefault();
      setLoading(true)

      try {
                
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}/toggle-shopping-ledger-status/${id}`, {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`
          },
          credentials: "include",
          body: JSON.stringify({
            status
          })
        });
        // console.log(res.status)
        // console.log(res.body) 
        if (res.status === 400 || res.status === 500) {
        //   throw new Error(res.error);
            toast.error("Oops something went wrong",{duration: 1500})
        }else{
            const data =  await res.json();
            toast.success(data.message,{duration: 1500})
            dispatch(getShoppingLedgers(userData?.user?._id))
          }
          
        } catch (err) {
          console.log(err);
          toast.error("Oops something went wrong!!..",{duration: 1500})
        }
        setLoading(false)
  }
  
  return(
    <>
    {
      loading ? <Spinners/> :
      <>
            <div  className={`card border-0 main_cards position-relative `}   
              style={{backgroundColor: bg, color: txt_color, height: "140px" }}
            >
              <div className=" card-body" onClick={()=> navigate(`${url}`)}  >
                <h5 className="card-title card_text" style={{width:"85%"}}  >{title}</h5>
                  <p className=" card-text  ">
                    
                  </p>                
                  <p className="card-text card_text mt-1" style={{height: "45px", textOverflow: "ellipsis", overflow:"hidden" }} >
                   {desc.length>80? desc.substr(0, 80)+"...": desc}
                    </p>

              </div>

              <div className={`position-absolute  top-0 end-0  shadow mt-2  px-3 badge_icon fw-bold`}
              style={{backgroundColor: status? "#DA0C81" : "#A459D1", color: "#fff"}}
              > {status? "Active": "Deactive"}</div>
              <Dropdown className="more_opt position-absolute bottom-0 mb-1 fs-5 me-1 end-0 bg-"
              
              >
                <Dropdown.Toggle className="border-0 pb-2 text-dark fs-5" style={{background:"transparent"}} >
                  <MdMoreVert />
                </Dropdown.Toggle>

                <Dropdown.Menu className="border-0 shadow-sm"  >

                  <Dropdown.Item   
                  onClick={(e)=> toggleStatus(e,id,!status)}
                  > {status?  <><IoEyeOffOutline style={{color : "#A459D1" }}  /> Deactive</> : <><IoEyeOutline style={{color : "#DA0C81"}} /> Active</>}</Dropdown.Item>

                  <Dropdown.Item onClick={()=> EditLedgerFun(id)}   > <MdOutlineModeEditOutline className="text-warning" /> Edit</Dropdown.Item>
                  <Dropdown.Item onClick={handleDeleteShow}  > <MdDeleteOutline className="text-danger" /> Delete</Dropdown.Item>
                </Dropdown.Menu>

              </Dropdown>
              <p className={` position-absolute fs-6`} style={{bottom:" -10px", left:"20px", color:"grey"}} > <i> { lastUpdatedMoment.fromNow() } </i>  </p>
        </div>

                {/* delete confirmation modal */}
                <Modal show={deleteShow} 
                  onHide={handleDeleteClose}
                  backdrop="static"
                  centered
                  keyboard={false}
                  scrollable>
                    <Modal.Body className="text-center fs-5  border-0">Are you sure do you want to delete <b>"{title}"</b> ledger.</Modal.Body>
                    <Modal.Footer className="border-0" >
                    <Button variant="secondary" disabled={loading} onClick={handleDeleteClose}>
                        Close
                    </Button>
                    <Button variant="danger" disabled={loading} onClick={(e) => deleteShoppingLedger(e,id)} >
                        Delete
                    </Button>
                    </Modal.Footer>
                </Modal>
      
      </>
    }

    </>
  )
}

export const BillTrackerCards =({data,url,theme, id, EditLedgerFun}) =>{

  const [deleteShow, setDeleteShow] = useState(false);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {bg, txt_color} = theme
  const {title ,desc, status, category, updatedAt} = data 
  const [loading, setLoading] = useState(false);
    
  const userData = useSelector((state) =>{
    return state.userAuth
})
 
  const handleDeleteShow = () => setDeleteShow(true)
  const handleDeleteClose = () => setDeleteShow(false)

  const lastUpdatedMoment = moment(updatedAt)

  const deleteBillTracker = async (e, id) =>{
      e.preventDefault()
      setLoading(true)

      try {
                
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}/delete-bill-tracker/${id}`, {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`
          },
          credentials: "include",
        });
        // console.log(res.status)
        // console.log(res.body) 
        if (res.status === 400 || res.status === 500) {
        //   throw new Error(res.error);
            toast.error("Oops something went wrong",{duration: 1500})
        }else{
            const data =  await res.json();
            toast.success(data.message,{duration: 1500})
            dispatch(getBillTracker(userData?.user?._id))
          }
          
        } catch (err) {
          console.log(err);
          toast.error("Oops something went wrong!!..",{duration: 1500})
        }
        
        handleDeleteClose()
        setLoading(false)
  }

  const toggleStatus = async (e, id, status) =>{
      e.preventDefault();
      setLoading(true)

      try {
                
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}/toggle-bill-tracker-status/${id}`, {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`
          },
          credentials: "include",
          body: JSON.stringify({
            status
          })
        });
        // console.log(res.status)
        // console.log(res.body) 
        if (res.status === 400 || res.status === 500) {
        //   throw new Error(res.error);
            toast.error("Oops something went wrong",{duration: 1500})
        }else{
            const data =  await res.json();
            toast.success(data.message,{duration: 1500})
            dispatch(getBillTracker(userData?.user?._id))
          }
          
        } catch (err) {
          console.log(err);
          toast.error("Oops something went wrong!!..",{duration: 1500})
        }
        setLoading(false)
  }
  
  return(
    <>
    {
      loading ? <Spinners/> :
      <>
            <div  className={`card border-0 main_cards position-relative `}   
              style={{backgroundColor: bg, color: txt_color, height: "140px", backgroundImage: `url('/images/${category}.png')` }}
            >
              <div className=" card-body" onClick={()=> navigate(`${url}`)}  >
                <h5 className="card-title card_text" style={{width:"85%"}}  >{title}</h5>
                  <p className=" card-text  ">
                    
                  </p>                
                  <p className="card-text card_text mt-1" style={{height: "45px", textOverflow: "ellipsis", overflow:"hidden" }} >
                   {desc.length>80? desc.substr(0, 80)+"...": desc}
                    </p>

              </div>

              <div className={`position-absolute  top-0 end-0  shadow mt-2  px-3 badge_icon fw-bold`}
              style={{backgroundColor: status? "#DA0C81" : "#A459D1", color: "#fff"}}
              > {status? "Active": "Deactive"}</div>
              <Dropdown className="more_opt position-absolute bottom-0 mb-1 fs-5 me-1 end-0 bg-"
              
              >
                <Dropdown.Toggle className="border-0 pb-2 text-dark fs-5" style={{background:"transparent"}} >
                  <MdMoreVert />
                </Dropdown.Toggle>

                <Dropdown.Menu className="border-0 shadow-sm"  >

                  <Dropdown.Item   
                  onClick={(e)=> toggleStatus(e,id,!status)}
                  > {status?  <><IoEyeOffOutline style={{color : "#A459D1" }}  /> Deactive</> : <><IoEyeOutline style={{color : "#DA0C81"}} /> Active</>}</Dropdown.Item>

                  <Dropdown.Item onClick={()=> EditLedgerFun(id)}   > <MdOutlineModeEditOutline className="text-warning" /> Edit</Dropdown.Item>
                  <Dropdown.Item onClick={handleDeleteShow}  > <MdDeleteOutline className="text-danger" /> Delete</Dropdown.Item>
                </Dropdown.Menu>

              </Dropdown>
              <p className={` position-absolute fs-6`} style={{bottom:" -10px", left:"20px", color:"grey"}} > <i> { lastUpdatedMoment.fromNow() } </i>  </p>
        </div>

                {/* delete confirmation modal */}
                <Modal show={deleteShow} 
                  onHide={handleDeleteClose}
                  backdrop="static"
                  centered
                  keyboard={false}
                  scrollable>
                    <Modal.Body className="text-center fs-5  border-0">Are you sure do you want to delete <b>"{title}"</b> ledger.</Modal.Body>
                    <Modal.Footer className="border-0" >
                    <Button variant="secondary" disabled={loading} onClick={handleDeleteClose}>
                        Close
                    </Button>
                    <Button variant="danger" disabled={loading} onClick={(e) => deleteBillTracker(e,id)} >
                        Delete
                    </Button>
                    </Modal.Footer>
                </Modal>
      
      </>
    }

    </>
  )
}

export const MainCards =({bg, title, url, image_logo}) =>{

  const navigate = useNavigate()

  return(
    <>
            <div  onClick={()=> navigate(`${url}`)}  style={{backgroundColor: bg, height:"80px", opacity:"0.9"}}
            className={`card dash_cards shadow-sm border-0 fw-bold position-relative text-white `}>
              <div className="card-body pt-3 d-flex align-items-center justify-content-between ">
                <div className="d-flex" >
                  <div className="me-2" style={{width:"35px", height:"35px", backgroundImage:`url(/images/${image_logo})`, 
                  backgroundPosition:"center", backgroundRepeat:"no-repeat", backgroundSize:"contain"}} ></div>
                  {/* <img src={`/images/${image_logo}`} className="me-3" alt="ledger" width="35px" height="35x" /> */}
                  <h5 className="card-title "> {title} </h5>
                </div>
                 <h5 className=" card-title d-flex align-items-center pt-2 " >   <MdKeyboardArrowRight/>   </h5>            
              </div>

        </div>
    </>
  )
}

export const NotesCard = ({data,theme, id, EditNoteFun, viewNotes}) =>{
  const [deleteShow, setDeleteShow] = useState(false);
  // const navigate = useNavigate()
  const dispatch = useDispatch()
  const {bg, txt_color} = theme
  const {title ,desc, updatedAt } = data 
  const [loading, setLoading] = useState(false);
    
  const userData = useSelector((state) =>{
    return state.userAuth
})
 
  const handleDeleteShow = () => setDeleteShow(true)
  const handleDeleteClose = () => setDeleteShow(false)

  const lastUpdatedMoment = moment(updatedAt)

  const deleteNotes = async (e, id) =>{
      e.preventDefault()
      setLoading(true)

      try {
                
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}/delete=sticky-notes/${id}`, {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`
          },
          credentials: "include",
        });
        // console.log(res.status)
        // console.log(res.body) 
        if (res.status === 400 || res.status === 500) {
        //   throw new Error(res.error);
            toast.error("Oops something went wrong",{duration: 1500})
        }else{
            const data =  await res.json();
            toast.success(data.message,{duration: 1500})
            dispatch(getStickyNotes(userData?.user?._id))
          }
          
        } catch (err) {
          console.log(err);
          toast.error("Oops something went wrong!!..",{duration: 1500})
        }
        
        handleDeleteClose()
        setLoading(false)
  }
  
  return(
    <>
    {
      loading ? <Spinners/> :
      <>
            <div  className={`card border-0 main_cards position-relative `}   
              style={{backgroundColor: bg, color: txt_color, height: "140px" }}
            >
              <div className=" card-body" onClick={()=> viewNotes(id)}  >
                <h5 className="card-title card_text fs-4 note_title " style={{width:"100%"}}  >{title.length>30? title.substr(0, 30)+"...": title}</h5>
                  <p className=" card-text  ">
                    
                  </p>                
                  <pre className="card-text card_text mt-1" style={{height: "45px", textOverflow: "ellipsis", overflow:"hidden" }} >
                   {desc.length>80? desc.substr(0, 80)+"...": desc}
                    </pre>

              </div>

              <Dropdown className="more_opt position-absolute bottom-0 mb-1 fs-5 me-1 end-0 ">
                <Dropdown.Toggle className="border-0 pb-2 text-dark fs-5" style={{background:"transparent"}} >
                  <MdMoreVert />
                </Dropdown.Toggle>

                <Dropdown.Menu className="border-0 shadow-sm"  >
                  <Dropdown.Item onClick={()=> EditNoteFun(id)}   > <MdOutlineModeEditOutline className="text-warning" /> Edit</Dropdown.Item>
                  <Dropdown.Item onClick={handleDeleteShow}  > <MdDeleteOutline className="text-danger" /> Delete</Dropdown.Item>
                </Dropdown.Menu>

              </Dropdown>
              <p className={` position-absolute fs-6`} style={{bottom:" -10px", left:"20px", color:"grey"}} > <i> { lastUpdatedMoment.fromNow() } </i>  </p>
        </div>

                {/* delete confirmation modal */}
                <Modal show={deleteShow} 
                  onHide={handleDeleteClose}
                  backdrop="static"
                  centered
                  keyboard={false}
                  scrollable>
                    <Modal.Body className="text-center fs-5  border-0">Are you sure do you want to delete <b>"{title}"</b> Note.</Modal.Body>
                    <Modal.Footer className="border-0" >
                    <Button variant="secondary" disabled={loading} onClick={handleDeleteClose}>
                        Close
                    </Button>
                    <Button variant="danger" disabled={loading} onClick={(e) => deleteNotes(e,id)} >
                        Delete
                    </Button>
                    </Modal.Footer>
                </Modal>
      
      </>
    }

    </>
  )
}