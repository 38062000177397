
export  const Themes = [
     {
        ID: "Theme_1",
        bg: "#B4B4B3  ",
        txt_color:"#fff",
    },
    {
        ID: "Theme_2",
        bg: "#FBECB2  ",
        txt_color:"#000",
    },
     {
        ID: "Theme_3",
        bg: "#E1AA74  ",
        txt_color:"#fff",
    },
     {
        ID: "Theme_4",
        bg: "#64CCC5  ",
        txt_color:"#fff",
    },
    {
        ID: "Theme_5",
        bg: "#F1B4BB  ",
        txt_color:"#000",
    },
    {
        ID: "Theme_6",
        bg: "#DFCCFB  ",
        txt_color:"#000",
    },
    {
        ID: "Theme_7",
        bg: "#A0E9FF  ",
        txt_color:"#000",
 
    },
    {
        ID: "Theme_8",
        bg: "#A1CCD1  ",
        txt_color:"#000",

    },
    {
        ID: "Theme_9",
        txt_color:"#000",
        bg: "#E8A9A9  ",
    },
     {
        ID: "Theme_10",
        bg: "#B4E4FF  ",
        txt_color:"#000",
    },
     {
        ID: "Theme_11",
        bg: "#FF8080  ",
        txt_color:"#fff",
    },
     {
        ID: "Theme_12",
        bg: "#CDFAD5  ",
        txt_color:"#000",
    },
     {
        ID: "Theme_13",
        bg: "#EAD7BB  ",
        txt_color:"#000",
    },
     {
        ID: "Theme_14",
        bg: "#FFCC70  ",
        txt_color:"#000",
    },
     {
        ID: "Theme_15",
        bg: "#96C291  ",
        txt_color:"#000",
    },

]
