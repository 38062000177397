import { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
// import { MdArrowBackIosNew } from "react-icons/md";
import {useState } from "react";
import { useLocation ,Link, NavLink } from "react-router-dom"
import { AiOutlineClose } from "react-icons/ai";
import { RxHamburgerMenu } from "react-icons/rx";
import "./CSS/Navbar.css"

const MainNavbar = () => {
  // const [isHome, setIsHome] = useState(false);
  const [isNavCollapsed, setIsNavCollapsed] = useState(false);
  // const [isAbout, setIsAbout] = useState(false);
  // const [isShared, setIsShared] = useState(false);
  // const navigate = useNavigate();
  const location = useLocation();

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  // useEffect(() => {
    // location.pathname === "/" ? setIsHome(false) : setIsHome(true)
    // location.pathname === "/about" || location.pathname === "/getting-started"  ? setIsAbout(false) : setIsAbout(true)
    // location.pathname.split('/')[1] === `shared-ledger` ? setIsShared(true) : setIsShared(false)
  // },[location.pathname])

  useEffect(() => {
    isNavCollapsed ? document.body.style.overflow = 'hidden' : document.body.style.overflow = "auto" 
  }, [isNavCollapsed]);

  return (
    <>
        <Navbar className="bg-body-tertiary position-fixed top-0 w-100 " style={{zIndex:"1000"}}>
        <Container className=' fw-bold '>
          <Navbar.Brand className='d-flex align-items-center' >
            {/* {
              isHome? 
              <span className="btn ps-0 m-0 border-0 my-0 py-0 "  onClick={()=> isShared ? navigate('/login') : navigate(-1)}  >
                    <MdArrowBackIosNew className="fw-bold " />
                </span>
                :""
            } */}
            <img
              alt="friends ledger logo"
              src="/favicon.ico"
              width="30"
              height="30"
              className="d-inline-block align-top me-2 rounded-circle"
            />
            <span className='' style={{height:"100%"}}  >
                Friends Ledger 
            </span>
          </Navbar.Brand> 
            <ul className='d-lg-flex d-none lg_main_nav my-auto justify-content-end pe-2'>
              <li> <Link to="/" className={`${location.pathname === "/" ? "text-primary" : ""}`}  >Home</Link> </li>
              <li> <Link to="/about" className={`${location.pathname === "/about"? "text-primary" : ""}`}  >About Us</Link></li>
              <li> <Link to="/register" className={`${location.pathname === "/register" ? "text-primary" : ""}`} >Sign Up</Link> </li>
              <li> <Link to="/login" className={`${location.pathname === "/login" ? "text-primary" : ""}`} >LogIn</Link></li>
              <li> <Link to="/getting-started" className={`${location.pathname === "/getting-started" ? "text-primary" : ""}`} >Quick Demo</Link></li>
            </ul>

            <span className={`more_btn_nav d-sm-flex d-lg-none ${isNavCollapsed?"d-none":"d-flex"}`} onClick={handleNavCollapse} >  
                <span> <RxHamburgerMenu className="fs-3"  /> </span>
            </span>

        </Container> 
            <div className="home_nav_container" style={{left:isNavCollapsed?"0%" : "-100%", transitionDelay: isNavCollapsed? "0s": "0.3s"}} >
                  <div className="inner_container" style={{left:isNavCollapsed?"0%" : "-100%", transitionDelay: isNavCollapsed? "0.2s": "0s"}} >
                    <div className='fs-5 fw-bold position-relative my-2 py-2 d-flex ps-3' >
                      <div className='me-1 side_nav_icon' ></div>
                        Friends Ledger 
                      <span style={{cursor:"pointer", height:"100%", backgroundColor:"red"}} onClick={handleNavCollapse} >
                        <AiOutlineClose style={{height:"100%"}} className="fs-3 position-absolute top-0 end-0 me-3 " /></span>
                    </div>

                    <ul className='mobile_nav_outer'>
                      <li> <NavLink to="/" className={`${location.pathname === "/" ? "text-primary" : ""}`} onClick={handleNavCollapse} >Home</NavLink> </li>
                      <li> <NavLink to="/about" className={`${location.pathname === "/about"? "text-primary" : ""}`} onClick={handleNavCollapse} >About Us</NavLink></li>
                      <li> <NavLink to="/register" className={`${location.pathname === "/register" ? "text-primary" : ""}`} onClick={handleNavCollapse} >Sign Up</NavLink> </li>
                      <li> <NavLink to="/login" className={`${location.pathname === "/login" ? "text-primary" : ""}`} onClick={handleNavCollapse} >Log In</NavLink></li>
                      <li> <NavLink to="/getting-started" className={`${location.pathname === "/getting-started" ? "text-primary" : ""}`} onClick={handleNavCollapse} >Quick Demo</NavLink></li>
                    </ul>

                  </div>
                  <div className="close_nav_spacer" onClick={handleNavCollapse} ></div>
            </div>
      </Navbar>


    </>
  )
}

export default MainNavbar