import { useLayoutEffect } from "react";
import "./CSS/About.css"
import { Helmet } from 'react-helmet';
import { useDispatch } from "react-redux";
import {getUser} from "../../store/slices/auth"

const VideoDemos = () => {
    const dispatch = useDispatch()

    useLayoutEffect(() =>{
        dispatch(getUser())
  },[dispatch]) 

  return (
    <div className="pt-lg-5 pt-4  px-lg-5 px-md-4">
        <Helmet>
            <link rel="canonical" href="https://friendsledger.com/getting-started"/>
            <title>Getting-Started</title>
        </Helmet>
        {/* Getting Started */}
         <div className="container-fluid mt-5 about_headings ">
            <h1 className="text-center" > Getting Started </h1>
            <div className="underline_sap" ></div>
        </div>

        <div className="container-fluid row my-5 ps-2 pe-0 steps_blogs steps_blogs_odd">  
            <div className="col-lg-6 col-sm-12 pb-2 d-flex justify-content-center">
                <div className="image_con img_1"></div>
            </div>
            <div className="col-lg-6 col-sm-12 step_info d-flex flex-column justify-content-center">
                <h3 > <span >Step 1: Register</span> </h3>
                <p className="ms-lg-4 fs-5 mt-2"> 
                    Sign up and create your account to start using Friends Ledger.
                </p> 
            </div>
        </div>

        <div className="container-fluid  row my-5 pe-0 steps_blogs steps_blogs_even">  
            <div className="col-lg-6 col-sm-12 d-flex flex-column step_info ps-lg-5 justify-content-center">
                <h3> <span>Step 2: Sign In</span></h3>
                <p className="ms-lg-4 fs-5 mt-2" >
                    Log in to your account to access your Friends Ledger dashboard.
                </p>
            </div>
            <div className="col-lg-6 col-sm-12 pb-2 d-flex justify-content-center">
                <div className="image_con img_2"></div>
            </div>
        </div>
    
        <div className="container-fluid row my-5 steps_blogs pe-0 steps_blogs_odd">  
            <div className="col-lg-6 col-sm-12 pb-2 d-flex justify-content-center">
                <div className="image_con img_3"></div>
            </div>
            <div className="col-lg-6 col-sm-12 step_info d-flex flex-column justify-content-center">
                <h3 > <span>Step 3: Create a New Friends Ledger</span> </h3>
                <p className="ms-lg-4 fs-5 mt-2">
                    Click on the Friends Ledger tab then click the plus button to create a new Friends Ledger, adding details like name and description. For shared ledgers, enter the registered phone number of the user you want to collaborate with.
                </p>
            </div>
        </div>


        <div className="container-fluid row my-5 pe-0 steps_blogs steps_blogs_even">  
            <div className="col-lg-6 col-sm-12 d-flex flex-column step_info ps-lg-5 justify-content-center">
                <h3> <span>Step 4: Add Transactions</span> </h3>
                <p className="ms-lg-4 fs-5 mt-2" >
                    Once you've created a ledger, you can start adding transactions. Utilize the arrow button to specify the direction of the transaction, indicating who owes money to whom.
                </p>
            </div>
            <div className="col-lg-6 col-sm-12 pb-2 d-flex justify-content-center">
                <div className="image_con img_4"></div>
            </div>
        </div>
    
        <div className="container-fluid row my-5 pe-0 steps_blogs steps_blogs_odd">  
            <div className="col-lg-6 col-sm-12 pb-2 d-flex justify-content-center">
                <div className="image_con img_5"></div>
            </div>
            <div className="col-lg-6 col-sm-12 step_info d-flex flex-column justify-content-center">
                <h3 > <span>Step 5: Edit or Share Your Ledger</span> </h3>
                <p className="ms-lg-4 fs-5 mt-2">
                    You can edit the details of your ledger or copy the link of the ledger to share it with others.
                </p>
            </div>
        </div>


        <div className="container-fluid row my-5 pe-0 steps_blogs steps_blogs_even">  
            <div className="col-lg-6 col-sm-12 d-flex flex-column step_info ps-lg-5 justify-content-center">
                <h3> <span>Step 6: Activate or Deactivate</span> </h3>
                <p className="ms-lg-4 fs-5 mt-2" >
                    If a ledger is not in use, you can deactivate it. If you wish to use it again, you can reactivate it whenever you need.
                </p>
            </div>
            <div className="col-lg-6 col-sm-12 pb-2 d-flex justify-content-center">
                <div className="image_con img_6"></div>
            </div>
        </div>
    
        <div className="container-fluid row my-5 pe-0 steps_blogs steps_blogs_odd">  
            <div className="col-lg-6 col-sm-12 pb-2 d-flex justify-content-center">
                <div className="image_con img_7"></div>
            </div>
            <div className="col-lg-6 col-sm-12 step_info d-flex flex-column justify-content-center">
                <h3> <span>Step 7: Delete Ledgers</span>  </h3>
                <p className="ms-lg-4 fs-5 mt-2">
                    You can delete the ledgers when its purpose is fulfilled.
                </p>
            </div>
        </div>

        {/* <footer className="container py-4 my-5 fs-5 d-none ">
            <Link onClick={ () => navigate(-1)} className="col-6 d-none text-decoration-none"> <GoArrowLeft/> Back</Link>
            <Link to="/getting-started" className="col-6 d-none text-decoration-none text-end ">Quick Demo <GoArrowRight  /> </Link>
        </footer> */}

    </div>
  )
}

export default VideoDemos