import React,{useState, useLayoutEffect, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import "./CSS/Profile.css"
import { toast } from 'sonner';
import { FiEdit3 } from "react-icons/fi";
import { TbLogout } from "react-icons/tb";
import { MdDelete ,MdErrorOutline, MdOutlineSmartDisplay } from "react-icons/md";
import { BsFillTelephoneFill } from "react-icons/bs";
// import {  BiSupport } from "react-icons/bi";
import { BiSolidLock } from "react-icons/bi";
import { IoMdArrowForward } from "react-icons/io";
// import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { IoEyeOffOutline,IoEyeOutline } from "react-icons/io5";
import { useSelector, useDispatch } from "react-redux";
import { getUser } from '../../store/slices/auth';
import { getAllUsers } from '../../store/slices/users';
import { LogoutAllModal, LogoutModal } from '../../components/Modals/Modals';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { motion } from 'framer-motion';

import { Tooltip } from 'react-tooltip'
import Spinners from '../../components/Spinners/Spinners';

const Profile = () => {

    // const { pathname } = useLocation();
    const navigate = useNavigate();
    const [showEmail, setShowEmail] = useState(false);
    const [showPhone, setShowPhone] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showDelAcc, setDelAcc] = useState(false);
    const [showLogout, setLogout] = useState(false);
    const [showLogoutAll, setLogoutAll] = useState(false);
    const dispatch = useDispatch()

    const userData = useSelector((state) =>{
        return state.userAuth
    })

    const handleEmailClose = () => setShowEmail(false);
    const handleEmailShow = () => setShowEmail(true);

    const handlePhoneClose = () => setShowPhone(false);
    const handlePhoneShow = () => setShowPhone(true);

    const handlePasswordClose = () => setShowPassword(false);
    const handlePasswordShow = () => setShowPassword(true);

    const handleDelAccClose = () => setDelAcc(false);
    const handleDelAccShow = () => setDelAcc(true);

    const handelLogoutAllClose = () => setLogoutAll(false);
    const handelLogoutAllShow = () => setLogoutAll(true);

    const handelLogoutClose = () => setLogout(false);
    const handelLogoutShow = () => setLogout(true);

    // const animation = {
    //     initial: {opacity: 0, x: -100},
    //     animate: {opacity: 1, x:0},
    //     exit: {opacity:0, x: 100}
    // }


useLayoutEffect(() => {
    if(userData?.user?.length === 0){
        // console.log("dispatch called!!")
        dispatch(getUser())
        dispatch(getAllUsers())
    }
}, [userData,dispatch]);

  return (
    <motion.div 
    // variants={animation}
    initial="initial"
    animate="animate"
    exit="exit"
    transition={{duration: 0.2}}
    >
        <div className="mt-0 main_profile_container">
            <div className="inner_profile_container">
                <div className="  col-sm-12 col-lg-8 position-relative pt-4 profile_info">

                    {/* <div className=" position-absolute top-0  fs-4 fw-bolder text-decoration-none text-dark ms-3 " style={{cursor:"pointer"}}
                    onClick={()=> navigate(-1)}
                    >
                        <MdArrowBack/> 
                    </div> */}

                    <div className="   profile_pic_block" >
                        {/* <img src={"/images/user-logo.png"} alt="Profile Image" className="border"  width={"120px"} height={"120px"} style={{borderRadius: "50%"}} /> */}
                        <img src={`/images/profile_pics/${userData?.user?.Profile_pic}`} alt="Profile " className="border"  width={"120px"} height={"120px"} style={{borderRadius: "50%"}} />

                        <h3 className='py-3'>   {`${userData?.user?.firstName} ${userData?.user?.lastName}`}   <span className='fs-5 text-primary' style={{ cursor:"pointer" }} onClick={()=> navigate(`/profile-edit`)} > <FiEdit3/> </span> </h3>

                    </div>

                    <div className=' pro_opt_con  py-3' >

                        <div className="pro_inner_opt_con">
                            
                            <div className=' bg-light p-3 m-2 mb-sm-0 fs-5 pro_options_settings rounded' >
                                <label >Email</label>
                                <span className='' onClick={handleEmailShow} style={{ cursor:"pointer" }} > <FiEdit3/> </span>
                            </div>

                            <div className=' bg-light p-3 m-2 mb-sm-0 fs-5 pro_options_settings rounded ' >
                                <label >Phone number</label>
                                <span className=' ' onClick={handlePhoneShow} style={{ cursor:"pointer" }} > <FiEdit3/> </span>
                            </div>

                        </div>
                        <div className="pro_inner_opt_con">
                            
                            <div className=' bg-light p-3 m-2 mb-sm-0 fs-5 pro_options_settings rounded' >
                                <label >Password</label>
                                <span className='' onClick={handlePasswordShow} style={{ cursor:"pointer" }} > <FiEdit3/> </span>
                            </div>

                            <div className='bg-light p-3 m-2 mb-sm-0 fs-5 pro_options_settings rounded' >
                                <label >About Us</label>
                                <span onClick={() => navigate("/about")}  style={{ cursor:"pointer" }} > <IoMdArrowForward /> </span>
                            </div>

                        </div>

                        <div className="pro_inner_opt_con">
                            <div className='bg-light p-3 m-2 mb-sm-0 fs-5 pro_options_settings rounded' >
                                <label >Quick Demo</label>
                                <span onClick={() => navigate("/getting-started")}  style={{ cursor:"pointer" }} > <MdOutlineSmartDisplay /> </span>
                            </div>

                            <div className='bg-light p-3 m-2 mb-sm-0 fs-5 pro_options_settings rounded' >
                                <label className=''>Logout</label>
                                <span className='' onClick={handelLogoutShow} style={{ cursor:"pointer" }} > <TbLogout/> </span>
                            </div>
                                                        
                        </div>

                        <div className="pro_inner_opt_con">
                            {/* <div className='bg-light p-3 m-2 mb-sm-0 fs-5 pro_options_settings rounded' >
                                <label className=''>Support</label>
                                <span className='' style={{ cursor:"pointer" }} > <BiSupport/> </span>
                            </div>    */}

                            <div className='bg-light p-3 m-2 mb-sm-0 fs-5 pro_options_settings rounded' >
                                <label className=''>Logout from all devices</label>
                                <span className='' onClick={handelLogoutAllShow} style={{ cursor:"pointer" }} > <TbLogout/> </span>
                            </div>

                            <div className='bg-light p-3 m-2 mb-sm-0 fs-5 pro_options_settings rounded' >
                                <label  className='text-danger' >Delete my account</label>
                                <span className='' onClick={handleDelAccShow} style={{ cursor:"pointer" }} > <MdDelete/> </span>
                            </div>
                           
                        </div>

                        {/* <div className="pro_inner_opt_con">
                            <div className='bg-light p-3 m-2 mb-sm-0 fs-5 pro_options_settings rounded' >
                                <label className=''> Support </label>
                                <span className='text-' onClick={()=>navigate("/support")} style={{ cursor:"pointer" }} > <BiSupport/> </span>
                            </div>
                       
                        </div> */}
                    </div>

                </div>

                 <ChangeEmail closeFun={handleEmailClose} show={showEmail} />
                 <ChangePhone closeFun={handlePhoneClose} show={showPhone} />
                 <ChangePassword closeFun={handlePasswordClose} show={showPassword} />
                 <DeleteAccountPermanently closeFun={handleDelAccClose} show={showDelAcc} />

                {/* logoutModal */}
                 <LogoutModal closeFun={handelLogoutClose} show={showLogout} />
                 <LogoutAllModal closeFun={handelLogoutAllClose} show={showLogoutAll} />


            </div>
        </div>
       
    </motion.div>
  )
}

export default Profile


const  ChangeEmail = ({closeFun, show}) =>{


    const userData = useSelector((state) =>{
      return state.userAuth
  })
    const [editEmail, setEditEmail] = useState({
        Email: userData?.user?.email,
      });
      const [submitBtn, setSubmitBtn] = useState(true);
      const [error, setError] = useState(false);
      const [loading, setLoading] = useState(false);
      const dispatch = useDispatch()


     // handing Email
     const inputEmailEvent = (e) => {
        let { name, value } = e.target;
        // console.log(name, value);
    
        setEditEmail({ ...editEmail, [name]: value });
      };

    const handelClose = () =>{
        // e.preventDefault()
    
        setEditEmail({Email: userData?.user?.email})
        setError(false)
        closeFun()
      }

    const isEmail = (emailVal) => {
        let validRegex = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return emailVal.match(validRegex)
      }; 
    
      // PostEmail
    const PostHandler = async (e) => {
      e.preventDefault();
      setLoading(true);
    
        let { Email } = editEmail;

        HandelOnBlur("Email",Email)
        
        if(!error){
            // console.log(userData?.user?._id, Email)
            try {
                
                const res = await fetch(`${process.env.REACT_APP_BASE_URL}/update-email/${userData?.user?._id}`, {
                  method: "PATCH",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  credentials: "include",
                  body: JSON.stringify({
                     email : Email
                  })
                });
                // console.log(res.status)
                // console.log(res.body)
                if (res.status === 409) {
                //   throw new Error(res.error);
                    toast.error("email already exists",{duration: 1500})
                }else{
                    const data =  await res.json();
                    // console.log("from the checking component==>",data);
                    dispatch(getUser())
                    toast.success(data.message,{duration: 1500})
                    handelClose()
                }
          
              } catch (err) {
                // console.log(err);
                toast.error("Oops some thing went wrong!!..",{duration: 1500})
              //   history.push('/');
              }
            // dispatch(updateEmail(userData?.user?._id, Email))
        }else{
            if(error){
                // document.getElementById("user_email").focus()
                toast.error("invalid email!!..",{duration: 1500})
            }else{
                handelClose()
            }
        }
        setLoading(false)
      }

    const HandelOnBlur = (name, value) =>{
        // alert(`On Blur value=> ${value}, id=> ${id}`)
    
   
        let u_email = document.getElementById("user_email");
    
        //validate email
        if(name === "Email"){
           if (value?.trim() === "") {
            showValidate(u_email)
            setError(true)
          } else if (!isEmail(value?.trim())) {
            showValidate(u_email)
            setError(true)
          } else {
            setError(false)
            hideValidate(u_email);
          }
        }

  };


  useEffect(() => {
    dispatch(getUser())
    setEditEmail({Email: userData?.user?.email})
}, [userData?.user?.email,dispatch]);

  useLayoutEffect(() => {
    //   console.log(userData?.user?.email === editEmail.Email)
    if( userData?.user?.email === editEmail.Email ){
        setSubmitBtn(true)
    }else{
        setSubmitBtn(false)
    }
  }, [editEmail?.Email, userData?.user?.email, setSubmitBtn]);


    return(
        <>
                            
            {
                loading ? <Spinners /> : 
                <>
                    {/* Email Change Modal */}
                    <Modal
                        show={show}
                        onHide={handelClose}
                        backdrop="static"
                        keyboard={false}
                        centered
                    >
                        <Modal.Header closeButton className='border-bottom-0 py-1'>
                        <Modal.Title>Change Email</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="input-group mb-3 fs-5 input_con">
                                <span className="input-group-text">
                                @
                                </span>
                                <input type="email" className="form-control fs-5 rounded-2 "
                                onBlur={() => HandelOnBlur("Email", editEmail.Email)}
                                name="Email"
                                value={editEmail.Email}
                                onChange={inputEmailEvent}
                                placeholder="Email"
                                id="user_email"/>
                                    <span className={`error_icon text-danger fs-5 end-0 me-1 ${!error?"d-none":"" }`} 
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content="please enter your valid email"
                                    data-tooltip-variant="error"
                                    > <MdErrorOutline/> </span>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className='border-top-0 py-1'>
                        <Button variant="secondary" onClick={handelClose}>
                            Close
                        </Button>
                        <button disabled={submitBtn} onClick={PostHandler} className='btn btn-primary'>save</button>
                        </Modal.Footer>
                        <Tooltip id="my-tooltip" className="tooltip_style" />
                    </Modal>

                </>
            }
        </>
    )
}

const  ChangePhone = ({closeFun, show}) =>{


    const userData = useSelector((state) =>{
        return state.userAuth
    })
    const dispatch = useDispatch()
    const [editPhone, setEditPhone] = useState({
        Ph_Number: userData?.user?.number,
      });
      const [submitBtn, setSubmitBtn] = useState(true);
      const [error, setError] = useState(false);
      const [loading, setLoading] = useState(false);

     // handing phone
     const inputEvent = (e) => {
        let { name, value } = e.target;
        // console.log(name, value);
    
        setEditPhone({ ...editPhone, [name]: value });
      };

      const handelClose = () =>{
        // e.preventDefault()
    
        setEditPhone({Ph_Number: userData?.user?.number})
        setError(false)
        closeFun()
      }

      // PostPhone
    const PostHandler = async (e) => {
      e.preventDefault();
      setLoading(true)
    
        let { Ph_Number } = editPhone;

        HandelOnBlur("Ph_Number",Ph_Number)
        
        if(!error){
            try {
                
                const res = await fetch(`${process.env.REACT_APP_BASE_URL}/update-number/${userData?.user?._id}`, {
                  method: "PATCH",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  credentials: "include",
                  body: JSON.stringify({
                     number : Ph_Number
                  })
                });
                if (res.status === 409) {
                //   throw new Error(res.error);
                    toast.error("number already exists",{duration: 1500})
                }else{
                    const data =  await res.json();
                    // console.log("from the checking component==>",data);
                    dispatch(getUser())
                    toast.success(data.message,{duration: 1500})
                    setSubmitBtn(true)
                    handelClose()
                }
          
              } catch (err) {
                // console.log(err);
                toast.error("Oops something went wrong!!..",{duration: 1500})
              //   history.push('/');
              }
        }else{
            if(error){
                toast.error("invalid phone number!!..",{duration: 1500})
                // document.getElementById("ph_no").focus()
            }else{
                handelClose()
            }
        }
        setLoading(false)
      }

    const HandelOnBlur = (name, value) =>{
        // alert(`On Blur value=> ${value}, id=> ${id}`)
    
   
        let ph_no = document.getElementById("ph_no");
    
        //validate Phone
        if(name === "Ph_Number"){
            if (String(value)?.trim() === "") {
            showValidate(ph_no)
            setError(true)
            } else if (String(value)?.trim().length !== 10) {
            showValidate(ph_no)
            setError(true)
            } else {
            hideValidate(ph_no);
            setError(false)
            }

    }

  };

  
  useEffect(() => {
    dispatch(getUser())
    setEditPhone({Ph_Number: userData?.user?.number})
}, [userData?.user?.number,dispatch]);

  useLayoutEffect(() => {
    if(editPhone.Ph_Number !== userData?.user?.number){
        (String(editPhone?.Ph_Number)?.trim())?.length === 10 ?  setSubmitBtn(false) :  setSubmitBtn(true) 
        setSubmitBtn(false)
    }else{
        setSubmitBtn(true)
    }

  }, [editPhone.Ph_Number, userData?.user?.number, submitBtn]);


    return(
        <>
        {
            loading ? <Spinners /> :
            <>
                {/* Phone Change Modal */}
                <Modal
                    show={show}
                    onHide={handelClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton className='border-bottom-0 py-1' >
                    <Modal.Title>Change Phone</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="input-group mb-3 fs-5 input_con">
                            <span className="input-group-text">
                            <BsFillTelephoneFill/>
                            </span>
                            <input type="number" className="form-control fs-5 rounded-2 "
                            onBlur={() => HandelOnBlur("Ph_Number", editPhone.Ph_Number)}
                            name="Ph_Number"
                            value={editPhone.Ph_Number}
                            onChange={inputEvent}
                            placeholder="Phone number"
                            id="ph_no"/>
                            <span className={`error_icon text-danger fs-5 end-0 me-1 ${!error?"d-none":"" }`}
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="please enter your valid phone number"
                            data-tooltip-variant="error"
                            > <MdErrorOutline/> </span>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='border-top-0 py-1'>
                    <Button variant="secondary" onClick={handelClose}>
                        Close
                    </Button>
                    <button disabled={submitBtn} onClick={PostHandler} className='btn btn-primary'>save</button>
                    </Modal.Footer>
                    <Tooltip id="my-tooltip" className="tooltip_style" />
                </Modal>
            
            </>
        }
                            
        </>
    )
}

const  ChangePassword = ({closeFun, show}) =>{

    const userData = useSelector((state) =>{
        return state.userAuth
    })
    const dispatch = useDispatch()
    const [showPass, setShowPass] = useState(false);
    const [showCPass, setShowCPass] = useState(false);
    const [showCurrPass, setShowCurrPass] = useState(false);
    const [loading, setLoading] = useState(false);


    const [editPassword, setEditPassword] = useState({
        CurrPassword : "",
        Password : "",
        C_Password: ""
      });
      const [submitBtn, setSubmitBtn] = useState(true);
      const [error, setError] = useState({
        CurrPassword : false,
        Password : false,
        C_Password: false
      });

     // handing input
     const inputEvent = (e) => {
        let { name, value } = e.target;
        // console.log(name, value);
    
        setEditPassword({ ...editPassword, [name]: value });
      };

      // PostPassword
    const PostHandler = async (e) => {
      e.preventDefault();
      setSubmitBtn(true)
      setLoading(true)
    
        let { CurrPassword, Password, C_Password } = editPassword;
        let  cuurpwd = document?.getElementById("currpwd");
        CurrPassword = CurrPassword?.trim();
        Password = Password?.trim()
        C_Password = C_Password?.trim();
        
        if(CurrPassword === "" && Password === "" && C_Password === ""){
            cuurpwd.focus()
            // HandelOnBlur("CurrPassword",CurrPassword)
        }
        
        if(Password === C_Password && !error.CurrPassword && !error.Password && !error.C_Password ) {
            // console.log(emailError,"<= error status" )
            try {
                
                const res = await fetch(`${process.env.REACT_APP_BASE_URL}/update-pass/${userData?.user?._id}`, {
                  method: "PATCH",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  credentials: "include",
                  body: JSON.stringify({
                    password: Password, curr_password: CurrPassword
                  })
                });
                if (res.status === 400) {
                //   throw new Error(res.error);
                    toast.error("current password won't match!!..",{duration: 1500})
                }else{
                    const data =  await res.json();
                    // console.log("from the checking component==>",data);
                    dispatch(getUser())
                    toast.success(data.message,{duration: 1500})
                    setSubmitBtn(true)
                    closeFun()
                }
          
              } catch (err) {
                // console.log(err);
                toast.error("Oops something went wrong!!..",{duration: 1500})
              //   history.push('/');
              }
            setSubmitBtn(false)
        }else{

            if(error.C_Password || error.CurrPassword || error.Password ){
                toast.error("Invalid Credentials!!..",{duration: 1500})
            }else{
                console.log(error)
                toast.error("Invalid Credentials!!..",{duration: 1500})
                // closeFun()
                setSubmitBtn(false)
            }         
            
        }
        setLoading(false)
      }

 const HandelOnBlur = (name, value) =>{
        // alert(`On Blur value=> ${value}, id=> ${id}`)
    
        let pwd = document?.getElementById("pwd");
        let cpwd = document?.getElementById("cpwd");
        let  cuurpwd = document?.getElementById("currpwd");
    

    //validate Password
    if(name === "CurrPassword"){
        if (value?.trim()?.length < 6){
            setError({...error, [name] : true})
            showValidate(cuurpwd)
            // console.log("hadiong true => ",error)
        }else{
            setError({...error, [name] : false})
            hideValidate(cuurpwd)
            // console.log("hadiong false => ",error)
 
     }
     }

    //validate Password
    if(name === "Password"){
        if (value?.trim() === ""){
         showValidate(pwd)
         setError({...error, [name] : true})
       }else if(value?.trim()?.length < 6) {
        setError({...error, [name] : true})
         showValidate(pwd)
       }else{
        setError({...error, [name] : false})
         hideValidate(pwd)
 
     }
     }
 
     //validate Password
     if(name === "C_Password"){
        if (value?.trim() === "") {
           showValidate(cpwd)
           setError({...error, [name] : true})
         }else if(value?.trim() !== editPassword?.Password?.trim()) {
           showValidate(cpwd)
           showValidate(pwd)
           setError({...error, [name] : true})
         }else{
           hideValidate(cpwd)
           hideValidate(pwd)
           setError({...error, [name] : false})
       }

    }

  };

  useLayoutEffect(() => {

    if(!show){
        setEditPassword({
            CurrPassword : "",
            Password : "",
            C_Password: ""
        })
        setError({
            CurrPassword : false,
            Password : false,
            C_Password: false
        })
    }

    if(editPassword?.CurrPassword !== "" && editPassword?.Password !== "" && editPassword?.C_Password !== "" && editPassword?.Password !== editPassword?.CurrPassword){
        setSubmitBtn(false)
    }else{
        setSubmitBtn(true)
    }

  }, [ setEditPassword, show, editPassword?.CurrPassword,editPassword?.Password,editPassword?.C_Password ]);

  useLayoutEffect(() => {
    if(userData?.user?.length === 0 ){
        dispatch(getUser())
    }

}, [userData,dispatch]);

    return(
        <>

        {
            loading ? <Spinners /> : 
            <>
                {/* Password Change Modal */}
                <Modal
                    show={show}
                    onHide={closeFun}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton className='border-bottom-0 py-1' >
                    <Modal.Title>Change Password</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mb-3 position-relative input_con ">
                            <div className="d-flex">
                            <span className="  d-flex justify-content-center align-items-center rounded-2  border border-end-0 fs-5" style={{background:"#f8f9fa",padding:"12px"}}>
                                <BiSolidLock/>
                                </span>
                                <input type={showCurrPass?"text":"password"} className="form-control fs-5 rounded-2 " 
                                // onBlur={() => HandelOnBlur("CurrPassword", editPassword.CurrPassword)}
                                placeholder="Current Password"
                                name="CurrPassword"
                                value={editPassword.CurrPassword}
                                onChange={inputEvent} 
                                id="currpwd"/>
                                <span className="position-absolute  fs-5  px-2  text-dark"  onClick={()=> showCurrPass? setShowCurrPass(false): setShowCurrPass(true)}
                                style={{
                                top:"10%", right:"2%",cursor: "pointer", zindex:"20"
                                }} > {showCurrPass? <IoEyeOffOutline/> : <IoEyeOutline/>} </span>

                                <span className={`error_icon text-danger fs-5 end-0 me-5 ${!error.CurrPassword?"d-none":"" }`}
                                data-tooltip-id="my-tooltip"
                                data-tooltip-html="Current Password should match </br> with the Existing Password"
                                data-tooltip-variant="error"
                                > <MdErrorOutline/> </span>
                            </div>

                        </div>
                        <div className="mb-3 position-relative input_con ">
                            <div className="d-flex">
                            <span className="  d-flex justify-content-center align-items-center rounded-2  border border-end-0 fs-5" style={{background:"#f8f9fa",padding:"12px"}}>
                                <BiSolidLock/>
                                </span>
                                <input type={showPass?"text":"password"} className="form-control fs-5 rounded-2 " 
                                onBlur={() => HandelOnBlur("Password", editPassword.Password)}
                                placeholder="New Password"
                                name="Password"
                                value={editPassword.Password}
                                onChange={inputEvent} 
                                id="pwd"/>
                                <span className="position-absolute  fs-5  px-2  text-dark"  onClick={()=> showPass? setShowPass(false): setShowPass(true)}
                                style={{
                                top:"10%", right:"2%",cursor: "pointer",zindex:"20"
                                }} > {showPass? <IoEyeOffOutline/> : <IoEyeOutline/>} </span>

                                <span className={`error_icon text-danger fs-5 end-0 me-5 ${!error.Password?"d-none":"" }`}
                                data-tooltip-id="my-tooltip"
                                data-tooltip-html="Enter a combination of at least six numbers, letters </br> and punctuation marks (such as ! and &)"
                                data-tooltip-variant="error"
                                > <MdErrorOutline/> </span>
                            </div>

                        </div>

                        <div className="mb-3 position-relative input_con ">
                            <div className="d-flex">
                            <span className="  d-flex justify-content-center align-items-center rounded-2  border border-end-0 fs-5" style={{background:"#f8f9fa",padding:"12px"}}>
                                <BiSolidLock/>
                                </span>
                                <input type={showCPass?"text":"password"} className="form-control fs-5 rounded-2 " 
                                onBlur={() => HandelOnBlur("C_Password", editPassword.C_Password)}
                                placeholder="Confirm Password"
                                name="C_Password"
                                value={editPassword.C_Password}
                                onChange={inputEvent} 
                                id="cpwd"/>
                                <span className="position-absolute  fs-5  px-2  text-dark"  onClick={()=> showCPass? setShowCPass(false): setShowCPass(true)}
                                style={{
                                top:"10%", right:"2%",cursor: "pointer",zindex:"20"
                                }} > {showCPass? <IoEyeOffOutline/> : <IoEyeOutline/>} </span>
                                <span className={`error_icon text-danger fs-5 end-0 me-5 ${!error.C_Password?"d-none":"" }`}
                                data-tooltip-id="my-tooltip"
                                data-tooltip-html="New password and confirm </br> password should be same"
                                data-tooltip-variant="error"
                                > <MdErrorOutline/> </span>

                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='border-top-0 py-1' >
                    <Button variant="secondary" onClick={closeFun}>
                        Close
                    </Button>
                    <button disabled={submitBtn} onClick={PostHandler} className='btn btn-primary'>save</button>
                    </Modal.Footer>
                    <Tooltip id="my-tooltip" className="tooltip_style" />
                </Modal>
            </>

        }
                            
        </>
    )
}

// Delete Account Permanently
const DeleteAccountPermanently = ({closeFun, show}) =>{

    const navigate = useNavigate()
    const userData = useSelector((state) =>{
        return state.userAuth
    })

    const [submitBtn, setSubmitBtn] = useState(true);
    const [showCurrPass, setShowCurrPass] = useState(false);
    const [loading, setLoading] = useState(false);

    const [accPassword, setAccPassword] = useState({
        CurrPassword : "",
      });

      const [error, setError] = useState({
        CurrPassword : false,
      });

     // handing Email
     const inputEvent = (e) => {
        let { name, value } = e.target;
        // console.log(name, value);
    
        setAccPassword({ ...accPassword, [name]: value });
      };


    const DeleteAccount = async (e) =>{
        e.preventDefault();
        setSubmitBtn(true)
        setLoading(true)

        const {CurrPassword} = accPassword

        try {
                
            const res = await fetch(`${process.env.REACT_APP_BASE_URL}/user/delete-acc/${userData?.user?._id}`, {
              method: "DELETE",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              credentials: "include",
              body: JSON.stringify({
                passDel : CurrPassword
              })
            });
            if (res.status === 400) {
            //   throw new Error(res.error);
                toast.error("invalid Credentials",{duration: 1500})
            }else{
                const data =  await res.json();
                // console.log("from the checking component==>",data);
                // dispatch(getUser())
                toast.success(data.message,{duration: 1500})
                setTimeout(() => {
                    navigate('/')
                    window.location.reload()        
                }, 1000);
                setSubmitBtn(true)
            }
      
          } catch (err) {
            // console.log(err);
            toast.error("Oops something went wrong!!..",{duration: 1500})
          //   history.push('/');
          }

          setSubmitBtn(false)
          setLoading(false)
    }

const HandelOnBlur = (name, value) =>{
        // alert(`On Blur value=> ${value}, id=> ${id}`)

    let  cuurpwd = document.getElementById("currpwd");
    

    //validate Password
    if(name === "CurrPassword"){
        if (value.trim() === ""){
            setError({...error, [name] : true})
            showValidate(cuurpwd)
            // console.log("hadiong true => ",error)
        }else{
            setError({...error, [name] : false})
            hideValidate(cuurpwd)
            // console.log("hadiong false => ",error)
 
     }
     }

  };

  useLayoutEffect(() => {

    if(!show){
        setAccPassword({
            CurrPassword : "",
        })
        setError({CurrPassword : false})
    }

    if(accPassword.CurrPassword !== ""){
        setSubmitBtn(false)
    }else{
        setSubmitBtn(true)
    }

  }, [ setAccPassword, show, accPassword.CurrPassword]);


    return(
        <>

        {
            loading ? <Spinners /> :
             <Modal
                    show={show}
                    onHide={closeFun}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton className='border-bottom-0 py-1' >
                    <Modal.Title>Delete Account</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div className="mb-3 position-relative input_con ">
                            <div className="d-flex">
                            <span className="  d-flex justify-content-center align-items-center rounded-2  border border-end-0 fs-5" style={{background:"#f8f9fa",padding:"12px"}}>
                                <BiSolidLock/>
                                </span>
                                <input type={showCurrPass?"text":"password"} className="form-control fs-5 rounded-2 " 
                                onBlur={() => HandelOnBlur("CurrPassword", accPassword.CurrPassword)}
                                placeholder="Current Password"
                                name="CurrPassword"
                                value={accPassword.CurrPassword}
                                onChange={inputEvent} 
                                id="currpwd"/>
                                <span className="position-absolute  fs-5  px-2  text-dark"  onClick={()=> showCurrPass? setShowCurrPass(false): setShowCurrPass(true)}
                                style={{
                                top:"10%", right:"2%",cursor: "pointer", zindex:"20"
                                }} > {showCurrPass? <IoEyeOffOutline/> : <IoEyeOutline/>} </span>

                                <span className={`error_icon text-danger fs-5 end-0 me-5 ${!error.CurrPassword?"d-none":"" }`}
                                data-tooltip-id="my-tooltip"
                                data-tooltip-html="Current Password should match </br> with the Existing Password"
                                data-tooltip-variant="error"
                                > <MdErrorOutline/> </span>
                            </div>

                        </div>
                    </Modal.Body>
                    <Modal.Footer className='border-top-0 py-1'>
                    <Button variant="primary" onClick={closeFun}>
                        Close
                    </Button>
                    <button disabled={submitBtn} onClick={DeleteAccount} className='btn btn-danger'>Delete</button>
                    </Modal.Footer>
                    <Tooltip id="my-tooltip" className="tooltip_style" />
                </Modal>

        }
        </>
    )
}


const hideValidate = (ele) =>{
    ele.style.border = "1px solid gray";
  }
const showValidate = (ele) =>{
    ele.style.border = "1px solid red";
  }