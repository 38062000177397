// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
// import { getAnalytics } from "firebase/analytics";


const firebaseConfig = {
  apiKey: "AIzaSyDHhs0EQPzpMcXwTixO663jZMbFPHae4CM",
  authDomain: "friends-ledger-6fd53.firebaseapp.com",
  projectId: "friends-ledger-6fd53",
  storageBucket: "friends-ledger-6fd53.appspot.com",
  messagingSenderId: "338332658057",
  appId: "1:338332658057:web:127369a6fe19981ecf9958",
  measurementId: "G-B68DSKXRCF"
}; 

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

// const analytics = getAnalytics(app);
