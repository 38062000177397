import {useState, useCallback, Suspense, useLayoutEffect, useEffect} from "react"
// import { LoadingCards } from "../../components/Cards/Cards";
import { IoMdAdd } from "react-icons/io";
import "./CSS/stickyNodes.css"
import {Themes} from "../../components/Themes/Themes"
import { GiCheckMark } from "react-icons/gi";
import { toast } from 'sonner';
// import { AiOutlineSearch } from "react-icons/ai";
import { NotesCard } from "../../components/Cards/Cards";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useSelector, useDispatch } from "react-redux";
import { getStickyNotes } from "../../store/slices/StickyNotesSlice";
import { getUser } from "../../store/slices/auth";
import { getAllUsers } from "../../store/slices/users";
import { BiSearchAlt2 } from "react-icons/bi"; 
import { TbReload } from "react-icons/tb";
import { RxCross2 } from "react-icons/rx";
import Spinners, { LoadingName } from "../../components/Spinners/Spinners";
import { MdErrorOutline } from "react-icons/md";
import { motion } from "framer-motion";

const StickyNotes = () => {

    const [themeSelected, setThemeSelected] = useState("Theme_1");
    const [show, setShow] = useState(false);
    const [view, setView] = useState(false);
    const [searchData, setSearchData] = useState("");
    const [loading, setLoading] = useState(false);
    const [noteAPIData, setNoteAPIData] = useState(false);
    const [submitBtn, setSubmitBtn] = useState(false);
    const [editNotesOpt, setEditNotesOpt] = useState({id:'', status: false});
    
    const [stickyNotesData, setStickyNotesData] = useState({
        title: '',
        desc: '',
        theme: ''     
    });

    const [stickyNotesDataError, setStickyNotesDataError] = useState({
        title: false,
        desc: false,
        theme: false
    });

    const userData = useSelector((state) =>{
        return state.userAuth
    })

    const stickyNotes = useSelector((state) =>{
        return state.stickyNotes
    })
    const dispatch = useDispatch();
        
    const inputEvent = (e) => {
        let { name, value } = e.target;
        // console.log(name, value); 
        setStickyNotesData({ ...stickyNotesData, [name]: value })
    
    }

    const hideValidate = (ele) =>{
        ele.style.border = "1px solid rgb(206, 206, 206)";
      }
    const showValidate = (ele) =>{
        ele.style.border = "1px solid red";
      }

    const HandelOnBlur = (name, value) =>{
        // alert(`On Blur value=> ${value}, id=> ${id}`)
        let title_input = document.getElementById("title_input");
        
        // validate First Name
        if(name === "title"){
          if (value.trim().length <= 0) {
            showValidate(title_input)
            setStickyNotesDataError({...stickyNotesDataError, [name] : true})
        }else {
            setStickyNotesDataError({...stickyNotesDataError, [name] : false})
            hideValidate(title_input)
          }
    
        }
    
    }

    const PostStickyNotes = async (e) =>{
        e.preventDefault();
        setLoading(true)

        stickyNotesData.theme = themeSelected

        const {title, desc, theme} = stickyNotesData;

        if(title === "" ){
            toast.error("Title can not be blank",{duration: 2000})
            HandelOnBlur("title", title)
        }else{
            let validated = Object.keys(stickyNotesDataError).every(function(k){ return stickyNotesDataError[k] === false });

            if(validated){
                if(editNotesOpt.status){

                    try {
                
                        const res = await fetch(`${process.env.REACT_APP_BASE_URL}/edit-sticky-notes/${editNotesOpt.id}`, {
                          method: "PATCH",
                          headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                          },
                          credentials: "include",
                          body: JSON.stringify({
                            title, desc, theme
                          })
                        });
                        // console.log(res.status)
                        // console.log(res.body) 
                        if (res.status === 400 || res.status === 500) {
                        //   throw new Error(res.error);
                            toast.error("Oops something went wrong",{duration: 2000})
                        }else{
                            const data =  await res.json();
                            toast.success(data.message,{duration: 1500})
                            dispatch(getStickyNotes(userData?.user?._id))
                        }
                  
                      } catch (err) {
                        // console.log(err);
                        toast.error("Oops something went wrong!!..",{duration: 1500})
                      }

                    handleClose()
                    // toast.success("Edited Successfully!!..")

                }else{
                    // console.log("reached here=>",shoppingInfo)
                    let {title,desc, theme,} = stickyNotesData

                    try {
                
                        const res = await fetch(`${process.env.REACT_APP_BASE_URL}/add-sticky-notes`, {
                          method: "POST",
                          headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                          },
                          credentials: "include",
                          body: JSON.stringify({
                            UID: userData?.user?._id, title, desc, theme
                          })
                        });
                        // console.log(res.status)
                        // console.log(res.body) 
                        if (res.status === 500 || res.status === 400) {
                        //   throw new Error(res.error);
                            toast.error("Oops something went wrong",{duration: 1500})
                        }else{
                            const data =  await res.json();
                            toast.success(data.message,{duration: 1500})
                            dispatch(getStickyNotes(userData?.user?._id))
                        }
                  
                      } catch (err) {
                        // console.log(err);
                        toast.error("Oops something went wrong!!..",{duration: 1500})
                      }

                    // dispatch(createShoppingLedger(shoppingInfo))
                    handleClose()
                    // toast.success("Created Successfully!!..")
                }
            }


        }
        setLoading(false)
        
    }

    const EditNoteFun = (id) =>{
  
        let res = stickyNotes?.data?.filter((item) => {
            return item._id === id ;
        });

        let {title,desc,theme} = res[0]
        setNoteAPIData(res[0])
  
        setStickyNotesData({
          title: title,
          desc: desc,
          theme: theme,
      })

      setThemeSelected(theme)
      setEditNotesOpt({id: id, status: true})
      handleShow()
      setSubmitBtn(true)
    }

    // change the status of the submit button on edit  
    useEffect(() => {
        if(editNotesOpt.status){
            if(stickyNotesData.title !== noteAPIData?.title || stickyNotesData.desc !== noteAPIData?.desc || stickyNotesData.theme !== themeSelected){
                setSubmitBtn(false)       
            }else{
                setSubmitBtn(true)       
             }   
         }
    },[themeSelected, stickyNotesData, editNotesOpt.status, noteAPIData]);
    
    const handleClose = () => {

        setStickyNotesData({
            title: '',
            desc: '',
            theme: '' 
        })
        setStickyNotesDataError({
            title: false,
            desc: false,
            theme: false 
        })
        setThemeSelected("Theme_1")
        setShow(false)
        setSubmitBtn(false)
        setTimeout(() => {
            setEditNotesOpt({id: '', status: false})
        }, 1000);
    };

    const handleShow = () => {
        setShow(true)
    };

    const handleViewShow = (id) =>{

        let res = stickyNotes?.data?.filter((item) => {
            return item._id === id ;
        });

        let {title,desc,theme} = res[0]
  
        setStickyNotesData({
          title: title,
          desc: desc,
          theme: theme,
      })

      let themeData = Themes.find((item) => {
        return item.ID === theme;
    });
  
        setThemeSelected(themeData)
        setView(true)
    };

    const handleViewClose = () =>{
        setView(false)
        setTimeout(() => {
            setStickyNotesData({
                title: '',
                desc: '',
                theme: '' 
            })
            setStickyNotesDataError({
                title: false,
                desc: false,
                theme: false 
            })
            setThemeSelected("Theme_1")
            setSubmitBtn(false)
        }, 500);
    };

    
  const animation = {
    initial: {opacity: 0, x: -100},
    animate: {opacity: 1, x:0},
    exit: {opacity:0, x: 100}
  }
  const dataFetch = useCallback(() => {
    dispatch(getUser())
    dispatch(getStickyNotes(userData?.user?._id))
}, [dispatch,userData?.user?._id]);

useLayoutEffect(() => {
    dispatch(getStickyNotes(userData?.user?._id))
}, [dispatch,userData?.user?._id]);

useLayoutEffect(() => {
    if(userData?.user?.length === 0 ){
        dispatch(getUser())
        dispatch(getAllUsers())
    }
}, [userData,dispatch]);
 

  return (
    <motion.div 
    variants={animation}
    initial="initial"
    animate="animate"
    exit="exit"
    transition={{duration: 0.2}}
    style={{height:"100vh"}}
    >
                 {/* add button */}
                 <div  className=" add_btn test_btn z-3 position-fixed bottom-0 end-0 mb-4 me-4  mb-lg-4 me-lg-4 bg-warning rounded-circle"
                   onClick={handleShow} >
                {/* <img src="/images/add_note.png" alt="" width="50px" height="50px" /> */} 
                <IoMdAdd />
            </div>

            {/* Notepad Information */}
            {
                 loading ? <Spinners/> :
                    <Modal
                            show={show}
                            onHide={handleClose}
                            backdrop="static"
                            centered
                            keyboard={false}
                            scrollable
                        >
                            <Modal.Header closeButton className="border-bottom-0 py-1" >
                            <Modal.Title> {editNotesOpt.status ? "Edit Note" : "New Note"}   </Modal.Title>
                            </Modal.Header>
                            <Modal.Body >
                                <form>
                                    <div className="mb-3">
                                        {/* <label htmlFor="recipient-name" className="col-form-label">Title:</label> */}
                                        <div className=" position-relative">
                                            <input type="text" 
                                            className="form-control" 
                                            placeholder="Title" 
                                            name="title"
                                            onBlur={() => HandelOnBlur("title", stickyNotesData.title)}
                                            value={stickyNotesData.title} 
                                            onChange={inputEvent}
                                            id="title_input"
                                            />
                                            <span className={` position-absolute top-0 end-0 me-2 text-danger ${!stickyNotesDataError.title?"d-none":"" } fs-5`}
                                                data-tooltip-id="my-tooltip"
                                                data-tooltip-content="Please enter the title"
                                                data-tooltip-variant="error"
                                            > <MdErrorOutline/> </span>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                    <div className=" position-relative">
                                            <textarea className="form-control" style={{resize:"none", height:"150px"}} 
                                            name="desc"
                                        
                                            value={stickyNotesData.desc} 
                                            onChange={inputEvent}
                                            placeholder="Start writing..."
                                            id="desc_input"></textarea>
                                        </div>
                                    </div>

                                    <div className="  mb-3 ">
                                        <label htmlFor="recipient-name" className="col-form-label">Theme:</label>
                                        <div className="d-flex flex-wrap " >
                                            {
                                                Themes.map((ele) => {
                                                    const {ID,bg,txt_color} = ele
                                                    return(
    
                                                            <div key={ID}  className={` border theme_styles`} 
                                                            style={{backgroundColor: bg, color: txt_color}}
                                                            onClick={()=> setThemeSelected(ID)} > {
                                                                themeSelected === ID ? <GiCheckMark/>: ""
                                                            }  </div> 

                                                    )
                                                })
                                            }
                                            
                                            
                                        </div>
                                    </div>

                                </form>
                            </Modal.Body>
                            <Modal.Footer className="border-top-0 py-1">
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={PostStickyNotes} disabled={submitBtn} > { editNotesOpt.status ? "Save" : "Create" }  </Button>
                            </Modal.Footer>
                    </Modal>
            }    

            {/* View Sticky Notes */}
            <Modal
            show={view}
            onHide={handleViewClose}
            backdrop="static"
            centered
            keyboard={false}
            scrollable
            >
                <Modal.Header closeButton 
                style={{backgroundColor: themeSelected?.bg, color: themeSelected?.txt_color}}
                className="border-bottom-0 pt-3 pb-2" >
                </Modal.Header>
                    <Modal.Title className="py-1 px-3"
                    style={{backgroundColor: themeSelected?.bg, color: themeSelected?.txt_color}}
                     > {stickyNotesData?.title} </Modal.Title>
                <Modal.Body 
                style={{backgroundColor: themeSelected?.bg, color: themeSelected?.txt_color}}
                 >
                    <pre>
                        {stickyNotesData?.desc}
                    </pre>
                </Modal.Body>
            </Modal>

                {/* main body of the Sticky Notes */}
                <div className="mt-0 main_outer_con d-flex position-relative flex-column">
                    
                  {
                        <div className=" search_sec  position-fixed px-3" >
                            <div className=" position-relative search_inner note_search">
                                 <input type="text" className="form-control rounded-pill" 
                                    name="search"
                                    value={searchData} 
                                    onChange={ (e) => setSearchData(e.target.value) }
                                    placeholder="Search by Title"
                                    style={{paddingLeft:"37px", paddingRight:"30px"}}
                                    id="search_btn"
                                />
                                <span className="position-absolute top-0 start-0 ms-2 fs-4  d-flex  "
                                    style={{color: "grey", marginTop:"8px"}} >
                                    <BiSearchAlt2/> 
                                </span>
                                <span 
                                    className={`position-absolute  d-flex mt-2 top-0 end-0 me-2 fs-4 ${searchData.length === 0? 'd-none' : ""} `} 
                                    style={{color: "grey", cursor:"pointer"}} onClick={()=> setSearchData("")}
                                    >
                                     <RxCross2/> 
                                </span>
                              
                            </div>

                                    <button  className="btn bg-info ms-2 bg-light rounded fs-3 d-flex align-items-center border-0 btn-sm"
                                        onClick={dataFetch}
                                        style={{width: "35px"}}> <TbReload/> 
                                    </button>
                        </div>
                }


                    <div className=" d-flex  cards_display flex-wrap ps-lg-4 "  style={{paddingTop: "55px", paddingBottom:"60px"}}>
                    {/* <LoadingCards /> */} 
                    {
                        stickyNotes?.loading ?
                        <LoadingName />
                        // <div className=" d-flex align-items-center justify-content-center" style={{height:"90vh", width:"100vw"}} >
                        //     <p className="text-center text-secondary">Loading...</p> 
                        // </div> 
                        :
                            stickyNotes?.data?.length === 0 ?  
                                <div className="w-100  bg-light d-flex justify-content-center text-secondary align-items-center " 
                                style={{height:"50vh", border:"1px dashed #8080807d", cursor: "pointer"}}
                                onClick={handleShow}
                                > 
                                Click here to create Sticky Notes. </div> 
                            :
                            stickyNotes?.data?.filter((ele)=>{
                                if(searchData.length === 0){
                                    return ele
                                }else{ 

                                    return ele.title.toLowerCase().includes(searchData.toLowerCase()) 
                                }
                            })         
                            ?.map((ele)=>{  

                                let res = Themes.find((item) => {
                                    return item.ID === ele.theme;
                                });

                                return(
                                    <div key={ele?._id}>
                                        <Suspense fallback={<LoadingName />} >   
                                            <NotesCard data={ele} theme={res} id={ele._id}
                                             EditNoteFun={EditNoteFun} viewNotes={handleViewShow} />
                                        </Suspense>
                                    </div>
                                )
                            })
                    }
    
                    </div>

                </div>
    </motion.div>
  )
}

export default StickyNotes