import {useState, useLayoutEffect, useCallback, Suspense, useEffect} from "react"
// import { LedgersCards } from "../../components/Cards/Cards";
import { IoMdAdd } from "react-icons/io";
import "./CSS/LedgerDashboard.css"
import {Themes} from "../../components/Themes/Themes"
import { GiCheckMark } from "react-icons/gi";
// import { AiOutlineSearch } from "react-icons/ai";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import { useSelector } from "react-redux";
import { MdErrorOutline } from "react-icons/md";
import { toast } from 'sonner';
import Spinners, { LoadingName } from "../../components/Spinners/Spinners";

import { getShoppingLedgers } from "../../store/slices/ShoppingLedgerSlice";
import { useSelector, useDispatch } from "react-redux";
import { ShoppingLedgerCards } from "../../components/Cards/Cards";
import { motion } from "framer-motion";
import { RxCross2 } from "react-icons/rx";
import { BiSearchAlt2, BiFilter } from "react-icons/bi"; 
import { TbReload } from "react-icons/tb";
import { getUser } from "../../store/slices/auth";
import { getAllUsers } from "../../store/slices/users";

const ShoppingLedgerDashboard = () => {

    const [themeSelected, setThemeSelected] = useState("Theme_1");
    const [fixedOpt, setFixedOpt] = useState(false); 
    const [show, setShow] = useState(false);
    const [txtDescStatus, setTxtDescStatus] = useState(false);
    const [editLedgerOpt, setEditLedgerOpt] = useState({id:'', status:false})
    const [searchData, setSearchData] = useState("");
    const [sortData, setSortData] = useState("");
    const [loading, setLoading] = useState(false);
    const [ledgerAPIData, setLedgerAPIData] = useState(null);
    const [submitBtn, setSubmitBtn] = useState(false);

    
    const userData = useSelector((state) =>{
        return state.userAuth
    })

    const shoppingLedgers = useSelector((state) =>{
        return state.shoppingLedgers
    })
    const dispatch = useDispatch();


    const [shoppingInfo, setShoppingInfo] = useState({
        title: '',
        desc: '',
        fixed: false,
        amount: '',
        theme: '',
        status: true,
        total_amt: 0,
        balance_amt: 0,
        transactions: []
        
    });

    const [shoppingInfoError, setShoppingInfoError] = useState({
        title: false,
        desc: false,
        amount: false,
        theme: false
    });

    const handleClose = () =>{
        
        setShoppingInfo({
            title: '',
            desc: '',
            fixed: false,
            amount: '',
            theme: '',
            status: true,
            total_amt: 0,
            balance_amt: 0,
            transactions: []
        })

        setShoppingInfoError({
            title: false,
            desc: false,
            amount: false,
            theme: false
        })

        setThemeSelected('Theme_1')
        setFixedOpt(false)
        setTxtDescStatus(false)
        setShow(false)
        setEditLedgerOpt({id: '', status: false})
        setSubmitBtn(false)
    } 

    const handleShow = () => setShow(true);

    const handleFixedChange = event => {

        if(event.target.checked){
            setFixedOpt(true)
            shoppingInfoError.amount = false;
            hideValidate(document.getElementById("initial_amt"))
        }else{
            setFixedOpt(false)
        }

      };
    
    
    const inputEvent = (e) => {
        let { name, value } = e.target;
        // console.log(name, value); 
        setShoppingInfo({ ...shoppingInfo, [name]: value })
    
    }

    const hideValidate = (ele) =>{
        ele.style.border = "1px solid rgb(206, 206, 206)";
      }
    const showValidate = (ele) =>{
        ele.style.border = "1px solid red";
      }

    const HandelOnBlur = (name, value) =>{
        // alert(`On Blur value=> ${value}, id=> ${id}`)
        let title_input = document.getElementById("title_input");
        let desc_input = document.getElementById("desc_input");
        let initial_amt = document.getElementById("initial_amt");   
    
        
        // validate First Name
        if(name === "title"){
          if (value.trim() === "") {
            showValidate(title_input)
            setShoppingInfoError({...shoppingInfoError, [name] : true})
        }else if(value.trim().length > 25){
              showValidate(title_input)
              setShoppingInfoError({...shoppingInfoError, [name] : true})
          }else {
            setShoppingInfoError({...shoppingInfoError, [name] : false})
            hideValidate(title_input)
          }
    
        }
    
        // validate description
        if(name === "desc"){
          if(value.trim().length > 130){
            showValidate(desc_input)
            setShoppingInfoError({...shoppingInfoError, [name] : true})
        }else {
            setShoppingInfoError({...shoppingInfoError, [name] : false})
            hideValidate(desc_input)
          }
    
        }
    
        //validate Amount numbers
        if(name === "amount"){
              if (value.trim() === "") {
                showValidate(initial_amt)
                setShoppingInfoError({...shoppingInfoError, [name] : true})
              } else if (Number(value.trim()) <= 0) {
                showValidate(initial_amt)
                setShoppingInfoError({...shoppingInfoError, [name] : true})
              }else if (String(value.trim()).includes('.')) {
                showValidate(initial_amt)
                setShoppingInfoError({...shoppingInfoError, [name] : true})
              } else {
                hideValidate(initial_amt);
                setShoppingInfoError({...shoppingInfoError, [name] : false})
              }
    
        }
    
    
    
    
    }

    const PostShoppingLedger = async (e) =>{
        e.preventDefault()
        setLoading(true)

        shoppingInfo.theme = themeSelected
        shoppingInfo.fixed = fixedOpt

        let {title,desc, amount, fixed} = shoppingInfo

        if(fixed){
            if(amount === ""){
                HandelOnBlur("amount", amount)
                shoppingInfoError.amount = true
            }else{
                shoppingInfo.balance_amt = amount
            }
        }

        if(title === ""){
            document.getElementById("title_input").focus()
            toast.error("please fill all the fields",{duration: 1500})
        }else if(title === ""){
            HandelOnBlur("title", title)
        }else{
            let validated = Object.keys(shoppingInfoError).every(function(k){ return shoppingInfoError[k] === false });

            if(validated){
                if(editLedgerOpt.status){

                    try {
                
                        const res = await fetch(`${process.env.REACT_APP_BASE_URL}/edit-shopping-ledger/${editLedgerOpt.id}`, {
                          method: "PATCH",
                          headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                          },
                          credentials: "include",
                          body: JSON.stringify({
                            title, desc, theme: shoppingInfo.theme
                          })
                        });
                        // console.log(res.status)
                        // console.log(res.body) 
                        if (res.status === 400 || res.status === 500) {
                        //   throw new Error(res.error);
                            toast.error("Oops something went wrong",{duration: 1500})
                        }else{
                            const data =  await res.json();
                            toast.success(data.message,{duration: 1500})
                            dispatch(getShoppingLedgers(userData?.user?._id))
                        }
                  
                      } catch (err) {
                        console.log(err);
                        toast.error("Oops something went wrong!!..",{duration: 1500})
                      }


                    // dispatch(editShoppingLedgerData([editLedgerOpt.id,data]))
                    handleClose()
                    // toast.success("Edited Successfully!!..")

                }else{
                    // console.log("reached here=>",shoppingInfo)
                    let {title,desc, fixed, amount, theme, status, total_amt, balance_amt,transactions} = shoppingInfo

                    try {
                
                        const res = await fetch(`${process.env.REACT_APP_BASE_URL}/add-shopping-ledger`, {
                          method: "POST",
                          headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                          },
                          credentials: "include",
                          body: JSON.stringify({
                            UID: userData?.user?._id , title,desc,fixed,status, totalAmount:total_amt ,
                            amount,balanceAmount: balance_amt, transactionData:transactions, theme
                          })
                        });
                        // console.log(res.status)
                        // console.log(res.body) 
                        if (res.status === 500 || res.status === 400) {
                        //   throw new Error(res.error);
                            toast.error("Oops something went wrong",{duration: 1500})
                        }else{
                            const data =  await res.json();
                            toast.success(data.message,{duration: 1500})
                            dispatch(getShoppingLedgers(userData?.user?._id))
                        }
                  
                      } catch (err) {
                        console.log(err);
                        toast.error("Oops something went wrong!!..",{duration: 1500})
                      }

                    // dispatch(createShoppingLedger(shoppingInfo))
                    handleClose()
                    // toast.success("Created Successfully!!..")
                }
            }


        }

        // console.log(shoppingInfo)
        // console.log(shoppingInfoError)
        setLoading(false)
    }

    const EditLedger= (id) =>{

        let res = shoppingLedgers?.data?.filter((item) => {
            return item._id === id ;
        });

        let {title,desc,fixed,status,totalAmount,amount,balanceAmount,transactionData,theme} = res[0]
        setLedgerAPIData(res[0])
  
        setShoppingInfo({
          title: title,
          desc: desc,
          theme: theme,
          status: status,
          amount: amount === null? "": amount,
          fixed:fixed,
          total_amt: totalAmount,
          balance_amt: balanceAmount,
          transactions: transactionData
      })
  
      setTxtDescStatus(!status)
      setFixedOpt(fixed)
  
      setThemeSelected(theme)
      setEditLedgerOpt({id: id , status: true})
      handleShow()
  }

      // change the status of the submit button on edit  
      useEffect(() => {
        if(editLedgerOpt.status){
            if(shoppingInfo.title !== ledgerAPIData?.title || shoppingInfo.desc !== ledgerAPIData?.desc || shoppingInfo.theme !== themeSelected){
                setSubmitBtn(false)       
            }else{
                setSubmitBtn(true)       
            }   
        }
    },[themeSelected, shoppingInfo, editLedgerOpt.status, ledgerAPIData]);

  const animation = {
    initial: {opacity: 0, x: -100},
    animate: {opacity: 1, x:0},
    exit: {opacity:0, x: 100}
  }

  
  const dataFetch = useCallback(() => {
    dispatch(getUser())
    dispatch(getShoppingLedgers(userData?.user?._id))
}, [dispatch,userData?.user?._id]);

    useLayoutEffect(() => {
        dispatch(getShoppingLedgers(userData?.user?._id))
        if(localStorage.getItem("ShoppingSort")){
            setSortData(localStorage.getItem("ShoppingSort"));
        }else{
            setSortData('active')
            localStorage.setItem("ShoppingSort","active")
        }
    }, [dispatch,userData?.user?._id]);

    useLayoutEffect(() => {
        if(userData?.user?.length === 0 ){
            dispatch(getUser())
            dispatch(getAllUsers())
        }
    }, [userData,dispatch]);

    const sortSearchData = (value) =>{
        setSortData(value)
        localStorage.setItem("ShoppingSort",value)
    }

  return (
    <motion.div 
    variants={animation}
    initial="initial"
    animate="animate"
    exit="exit"
    transition={{duration: 0.2}}
    style={{height:"100vh"}}
    >

            {/* add button */}
            <div  className=" add_btn test_btn z-3 position-fixed bottom-0 end-0 mb-4 me-4  mb-lg-4 me-lg-4 bg-warning rounded-circle"
                onClick={handleShow}>
                {/* <img src="/images/add_note.png" alt="" width="50px" height="50px" /> */} 
                <IoMdAdd />
            </div>
            {/* <div className="  ">
                <div className="col-lg-6 px-3 bg-info d-flex justify-content-end ">
                        search
                </div>
            </div> */}

        {
            loading ? <Spinners/> :

            <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    centered
                    keyboard={false}
                    scrollable
                >
                    <Modal.Header closeButton className="border-bottom-0 py-1" >
                    <Modal.Title> Shopping Ledger Information</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <form>
                         <div className="mb-3">
                            <label htmlFor="recipient-name" className="col-form-label">Title </label>
                            <div className=" position-relative">
                            <input type="text" className="form-control" 
                                name="title"
                                onBlur={() => HandelOnBlur("title", shoppingInfo.title)}
                                value={shoppingInfo.title} 
                                onChange={inputEvent}
                                id="title_input"
                                disabled={txtDescStatus}
                                maxLength="25"
                                />
                                <span className={` position-absolute top-0 end-0 me-2 text-danger ${!shoppingInfoError.title?"d-none":"" } fs-5`}
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content="Please enter the title in 25 characters"
                                    data-tooltip-variant="error"
                                    > <MdErrorOutline/> </span>
                                </div>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="message-text" className="col-form-label">Description </label>
                                <div className=" position-relative">
                                    <textarea className="form-control" style={{resize:"none"}} 
                                    name="desc"
                                    onBlur={() => HandelOnBlur("desc", shoppingInfo.desc)}
                                    value={shoppingInfo.desc} 
                                    onChange={inputEvent}
                                    disabled={txtDescStatus }
                                    maxLength="130"
                                    id="desc_input"></textarea>
                                    <span className={`position-absolute top-0 end-0 me-2 text-danger ${!shoppingInfoError.desc?"d-none":"" } fs-5`}
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="Please write the description in 130 characters."
                                        data-tooltip-variant="error"
                                    > <MdErrorOutline/> </span>
                                </div>
                            </div>

                            <div className="mb-3" > 
                                <div className="form-check form-switch d-flex justify-content-end">
                                    <input className="form-check-input px-2" disabled={editLedgerOpt.status}  checked={fixedOpt} name="fixedOpt" onChange={handleFixedChange}  type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
                                    <label className="form-check-label px-2"htmlFor="flexSwitchCheckDefault">Fixed Budget </label>
                                </div>
                            </div>

                             <div className={`input-group position-relative ${fixedOpt? 'd-flex': 'd-none'} mb-3 d-flex align-items-center`}>
                                <label className="form-label" style={{width: "30%"}} >Amount </label>
                                <input type="number" className="form-control rounded"
                                onWheel={(e) => e.target.blur()}
                                placeholder="eg:- 500"
                                name="amount"
                                onBlur={() => HandelOnBlur("amount", shoppingInfo.amount)}
                                value={shoppingInfo.amount} 
                                onChange={inputEvent}
                                disabled={editLedgerOpt.status}
                                id="initial_amt"
                                // disabled={editLedgerOpt.status}
                                />
                                <span className="input-group-text">.00</span>
                                <span className={`position-absolute top-0 end-0 me-5 text-danger ${!shoppingInfoError.amount?"d-none":"" } fs-5`}
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content="Please enter amount."
                                    data-tooltip-variant="error"
                                > <MdErrorOutline/> </span>
                            </div>

                            <div className="  mb-3 ">
                                <label htmlFor="recipient-name" className="col-form-label">Theme:</label>
                                <div className="d-flex flex-wrap " >
                                    {
                                        Themes.map((ele) => {
                                            const {ID,bg,txt_color} = ele
                                            return(
                                                    <div key={ID}  className={` border theme_styles`} 
                                                    style={{backgroundColor: bg, color: txt_color}}
                                                    onClick={()=> setThemeSelected(ID)} > {
                                                        themeSelected === ID ? <GiCheckMark/>: ""
                                                    }  </div> 
                                            )
                                        })
                                    }
                                    
                                    
                                </div>
                            </div>
                            </form>
                    </Modal.Body>
                    <Modal.Footer className="border-top-0 py-1">
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" disabled={loading || submitBtn} onClick={PostShoppingLedger}  >  { editLedgerOpt.status ? "Save" : "Create" } </Button>
                    </Modal.Footer>
            </Modal>
        }

                {/* main body of the Shopping Ledger */}
                <div className="mt-0 main_outer_con d-flex position-relative flex-column">

                  {
                        <div className=" search_sec  position-fixed px-3" >
                            <div className=" position-relative search_inner">
                                 <input type="text" className="form-control rounded-pill" 
                                    name="search"
                                    value={searchData} 
                                    onChange={ (e) => setSearchData(e.target.value) }
                                    placeholder="Search by Title"
                                    style={{paddingLeft:"37px", paddingRight:"30px"}}
                                    id="search_btn"
                                />
                                <span className="position-absolute top-0 start-0 ms-2 fs-4  d-flex  "
                                    style={{color: "grey", marginTop:"8px"}} >
                                    <BiSearchAlt2/> 
                                </span>
                                <span 
                                    className={`position-absolute  d-flex mt-2 top-0 end-0 me-2 fs-4 ${searchData.length === 0? 'd-none' : ""} `} 
                                    style={{color: "grey", cursor:"pointer"}} onClick={()=> setSearchData("")}
                                    >
                                     <RxCross2/> 
                                </span>
                              
                            </div>

                                    <div className="btn-group ms-1" style={{width: "30px"}}>
                                        <button className="btn bg-light rounded fs-4 d-flex align-items-center border-0 btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <BiFilter/>
                                        </button>
                                        <ul className="dropdown-menu px-3">
                                            <li className={`dropdown-item ${sortData === "all"? 'text-info' : ""} `} onClick={() => sortSearchData("all")} > All</li>
                                            <li className={`dropdown-item ${sortData === "active"? 'text-info' : ""} `} onClick={() => sortSearchData("active")} > Active </li>
                                            <li className={`dropdown-item ${sortData === "deactive"? 'text-info' : ""} `} onClick={() => sortSearchData("deactive")} > Deactive </li>

                                        </ul>
                                    </div>
                                    <button  className="btn bg-info ms-3 bg-light rounded fs-3 d-flex align-items-center border-0 btn-sm"
                                        onClick={dataFetch}
                                        style={{width: "35px"}}> <TbReload/> 
                                    </button>
                        </div>
                  }

                    <div className=" d-flex  cards_display flex-wrap ps-lg-4 "  style={{paddingTop: "55px", paddingBottom:"60px"}}>
                    {/* <LoadingCards /> */} 
                    {
                        shoppingLedgers?.loading ?
                        <LoadingName />
                        // <div className=" d-flex align-items-center justify-content-center" style={{height:"90vh", width:"100vw"}} >
                        //     <p className="text-center text-secondary">Loading...</p> 
                        // </div> 
                        :
                            shoppingLedgers?.data?.length === 0  ?  
                                <div className="w-100  bg-light d-flex justify-content-center text-secondary align-items-center " 
                                style={{height:"50vh", border:"1px dashed #8080807d", cursor: "pointer"}}
                                onClick={handleShow}
                                > 
                                Click here to create shopping ledger. </div> 
                            :
                            shoppingLedgers?.data?.filter((ele)=>{
                                if(searchData.length === 0){
                                    return ele
                                }else{ 

                                    return ele.title.toLowerCase().includes(searchData.toLowerCase()) 
                                }
                            })
                            .filter((ele)=>{
                                if(sortData === "all"){
                                    return ele
                                }else if(sortData === "active"){
                                    return ele.status === true
                                }else if(sortData === "deactive"){
                                    return ele.status === false
                                }
                            })          
                            .map((ele,ind)=>{  

                                let res = Themes.find((item) => {
                                    return item.ID === ele.theme;
                                });

                                return(
                                    <div key={ind}>
                                        <Suspense fallback={<LoadingName />} >
                                            <ShoppingLedgerCards EditLedgerFun={EditLedger} theme={res} id={ele._id} url={`/shopping-ledger-details/${ele._id}`}
                                            data={ele} />    
                                        </Suspense>
                                    </div>
                                )
                            })
                    }
    
                    </div>
                </div>
    </motion.div>
  )
}

export default ShoppingLedgerDashboard


