import {useLayoutEffect, useEffect, useState, Suspense} from "react"
import { lazily } from 'react-lazily';
import "./CSS/homepage.css"
import { motion } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";
import {getUser} from "../../store/slices/auth"
import { getAllUsers } from "../../store/slices/users";
import Spinners,{LoadingName} from "../../components/Spinners/Spinners";
import { messaging } from "../../firebase"; 
import { getToken } from "firebase/messaging";


const  {MainCards} = lazily( () => import("../../components/Cards/Cards")) 
// import Spinners from "../../components/Spinners/Spinners";
// import Spinners from "../../components/Spinners/Spinners";
// import { useNavigate } from "react-router-dom";
// import { AiOutlineSearch } from "react-icons/ai";


const HomePage = () => {
 
    // const [themeSelected, setThemeSelected] = useState("Theme_1");
    // const navigate = useNavigate()
    // const [members, setMembers] = useState([""])
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);

    const userData = useSelector((state) =>{
        return state.userAuth
    })

    const PostFCMToken = async (id, token) => {
      // console.log("id = ",id+"/n token = ", token)
        try {
          const res = await fetch(`${process.env.REACT_APP_BASE_URL}/fcm-token/${id}`, {
            method: "PATCH",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
              token  
            })
          });
    
          const data = await res.json();

          // console.log("from the checking component==>",data);
    
          if (res.status === 200) {
            return data?.success
            // throw new Error(res.error);
          }else{
            return false;
          }
  
    
        } catch (err) {
          console.log(err);
          return false
        //   history.push('/');
        }
      }
    

    const animation = {
        initial: {opacity: 0, x : -100},
        animate: {opacity: 1, x:0},
        exit: {opacity:0, x: 100}
    }

    useLayoutEffect(() => {
      if(userData?.user?.length === 0 ){
          dispatch(getUser())
          dispatch(getAllUsers())
      }
  }, [userData,dispatch]);


  useEffect(() => {
    if(!userData?.user?._id){
        setLoading(true);
    }else{
        setLoading(false);
    }
  }, [userData?.user?._id]);

  useEffect(() => {
    const requestPermission = async() =>{

      const permission = await Notification.requestPermission()
      // console.log(permission)
      if(permission === "granted"){
        if(!localStorage.getItem("FCM_Token")){
          // let fcmToken = FCMTokenCheck()
          // console.log(fcmToken)
            const notificationToken = await getToken(messaging, {vapidKey: process.env.REACT_APP_VAPIDKEY});
            if(PostFCMToken(userData?.user?._id,notificationToken)){
              localStorage.setItem("FCM_Token", true)
          }
          // console.log("token =>",notificationToken)
        }
      }
    }
    if(userData?.user?._id && !localStorage.getItem("FCM_Token")){
      requestPermission()
    }
  }, [userData?.user?._id]); 


  return (
    <motion.div 
    variants={animation}
    initial="initial"
    animate="animate"
    exit="exit"
    transition={{duration: 0.2}}
    >

        {
            userData?.user?._id && !loading ?
            <>
                {/* main body of the Home */}
                <div className="mt-0 main_outer_con">
                    <div className=" d-flex pt-3 home_cards_display flex-wrap ">
                        <Suspense fallback={<LoadingName/>} >
                            <MainCards  bg="#793FDF" title="Friends Ledger" image_logo="ledger-logo2.png" url="/friends-ledgers" />
                            <MainCards  bg="#F78CA2" title="Shopping Ledger" image_logo="shopping-icon.png" url="/shopping-ledgers" />
                            <MainCards  bg="#6096B4"  title="Bill Tracker" image_logo="daily_icon.png"  url="/billTracker-ledgers" /> 
                            <MainCards  bg="#FFB000" title="Note" image_logo="note-logo-1.ico" url="/sticky-notes" />
                            {/* <MainCards  bg="#6096B4"  title="Daily Expenditure" image_logo="daily_icon.png"  url="" /> */}
                            {/* <MainCards  bg="#F11A7B"  title="TODO" image_logo="todo_icon.png"  url="" /> */}

                            {/* <MainCards bg="#219C90" title="Note" image_logo="note-logo-1.ico" url="/sticky-notes" /> */}
                            {/* <MainCards bg="#F1B4BB" title="Shopping Ledger" image_logo="bags.png" url="/shopping-ledgers" /> */}
                            {/* <MainCards bg="#FCAEAE" title="Friends Ledger" image_logo="ledger-logo2.png" url="/friends-ledgers" /> */}


                            {/* <MainCards bg="#F31559"  title="TODO" image_logo="todo_logo1.png" url="/sticky-notes" /> */}
                            {/* <MainCards bg="#8294C4" title="Ledger" txtcolor="#fff"  image_logo="ledger-logo2.png" url="/ledgers" /> */}
                            {/* <MainCards bg="#CD5C08" title="Ledger" txtcolor="#fff" image_logo="ledger-logo2.png" url="/ledgers" /> */}
                            {/* <MainCards bg="#F11A7B" title="Note" txtcolor="#fff" image_logo="note-logo-1.ico" url="/sticky-notes" /> */}
                            {/* <MainCards bg="#F8DE22" title="Note" txtcolor="#000" image_logo="note-logo-1.ico" url="/sticky-notes" /> */}
                            {/* <MainCards bg="#192655"  title="TODO" txtcolor="#fff" image_logo="todo_logo1.png" url="/sticky-notes" /> */}
                            {/* <MainCards bg="#040D12"  title="TODO" txtcolor="#fff" image_logo="todo_logo.png" url="/sticky-notes" /> */}
                        </Suspense>
                    </div>
                </div>
            </>
            : 
            <Spinners />
        }

    </motion.div>
  )
}

export default HomePage


