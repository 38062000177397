
export default function registerSW(){

    // if ('serviceWorker' in navigator) {
    //     // let swUrl = `${process.env.PUBLIC_URL}/service-worker.js`
    //     // let firebaseUrl = `${process.env.PUBLIC_URL}/firebase-messaging-sw.js`
    //     // navigator.serviceWorker.register(firebaseUrl).then((response) =>{
    //     //     console.log("response : ",response)
    //     //     return response.pushManager.getSubscription()
    //     //     .then(function (subscription) {
    //     //        response.pushManager.subscribe({
    //     //         userVisibleOnly: true,
    //     //         applicationServerKey: process.env.REACT_APP_VAPIDKEY
    //     //       })
    //     //     })
            
    //     // }).catch((error) =>{
    //     //     console.log("error : ",error)
    //     // })

    // }
    
    if('serviceWorker' in navigator && 'PushManager' in window) {
        let firebaseUrl = `${process.env.PUBLIC_URL}/firebase-messaging-sw.js`
        navigator.serviceWorker.register(firebaseUrl,{scope:'firebase-cloud-messaging-push-scope'}).then((response) =>{
            // console.log("response : ",response)
            navigator.serviceWorker.ready.then(function(registration) {
              // console.log("registering")
              registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: process.env.REACT_APP_VAPIDKEY
              })
              .then(function(subscription) {
                // console.log('Push subscription successful:', subscription);
              })
              .catch(function(error) {
                // console.log('Push subscription failed:', error);
              });
            });
            
        }).catch((error) =>{
            // console.log("error : ",error)
        })

    }
    

    // console.log("reached to registrations")
    // if ('serviceWorker' in navigator && 'PushManager' in window) {
    //   navigator.serviceWorker.ready.then(function(registration) {
    //     console.log("registering")
    //     registration.pushManager.subscribe({
    //       userVisibleOnly: true,
    //       applicationServerKey: process.env.REACT_APP_VAPIDKEY
    //     })
    //     .then(function(subscription) {
    //       console.log('Push subscription successful:', subscription);
    //     })
    //     .catch(function(error) {
    //       console.log('Push subscription failed:', error);
    //     });
    //   });
    // }

//     navigator.serviceWorker.ready
//   .then(registration => {
//     return registration.pushManager.getSubscription();
//   })
//   .then(subscription => {
//     if (subscription) {
//       return subscription.unsubscribe();
//     } else {
//       console.warn('No active subscription found.');
//     }
//   })
//   .then(() => {
//     console.log('Unsubscribed from push notifications.');
//   })
//   .catch(error => {
//     console.error('Error unsubscribing from push notifications:', error);
//   });

}

export const unregisterSW = () => {
    // if ("serviceWorker" in navigator) {
    //   navigator.serviceWorker.ready
    //     .then((registration) => {
    //       registration.unregister();
    //     })
    //     .catch((error) => {
    //       console.error(error.message);
    //     });
    // }
    if(window.navigator && navigator.serviceWorker) {
      navigator.serviceWorker.getRegistrations()
      .then(function(registrations) {
        for(let registration of registrations) {
          registration.unregister();
          // console.log(registration)
        }
      });
    }
  };