import {useState, useEffect} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'sonner';
import Spinners from '../../components/Spinners/Spinners';
import "./CSS/login.css"
import { IoEyeOffOutline,IoEyeOutline } from "react-icons/io5";
import { MdErrorOutline} from "react-icons/md";
import { BiSolidLock } from "react-icons/bi";

const ForgotPassword = () => {
 
    const navigate = useNavigate()
    const [userData, setUserData] = useState([]);
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [OTP, setOTP] = useState("");
    const [UI, setUI] = useState({
        find_Acc:true,
        verify_Acc: false,
        match_OTP:false,
        Password_Reset:false
    });

    const [minutes, setMinutes] = useState(1);
    const [seconds, setSeconds] = useState(30);

    const [showPass, setShowPass] = useState(false);
    const [showCPass, setShowCPass] = useState(false);


    const [editPassword, setEditPassword] = useState({
        Password : "",
        C_Password: ""
      });
      const [error, setError] = useState({
        Password : false,
        C_Password: false
      });


    const isemail = (emailVal) => {
        let atSynmol = emailVal.indexOf("@");
        if (atSynmol < 1) return false;
        let dot = emailVal.lastIndexOf(".");
        if (dot <= atSynmol + 2) return false;
        if (dot === emailVal.length - 1) return false;
        return true;
      };

      
    const hideValidate = (ele) =>{
    ele.style.border = "1px solid gray";
    }
    const showValidate = (ele) =>{
    ele.style.border = "1px solid red";
    }

    // handing input
    const inputEvent = (e) => {
        let { name, value } = e.target;
        // console.log(name, value);
    
        setEditPassword({ ...editPassword, [name]: value });
    };

    const PostSearchUser = async (e) =>{
        e.preventDefault();
        setLoading(true)
        let user_email = document.getElementById("InputUserName");

        if(email?.trim() === ""){
            toast.error("Please Enter email ID",{duration: 1500})
            showValidate(user_email)
        }else if (!isemail(email.trim())) {
            toast.error("Please Enter valid email ID",{duration: 1500})
            showValidate(user_email)
        } else {
                hideValidate(user_email);

                try {
                    const res = await fetch(`${process.env.REACT_APP_BASE_URL}/forgot-password-user`, {
                    // const res = await fetch(`http://localhost:5000/check`, {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        email
                      }),
                    });
              
                    const data = await res.json();
              
                    if (res.status !== 200) {
                      // window.alert("Invalid registration ");
                      toast.error(data.error,{duration: 1500});
                      setLoading(false)
                    }else if(res.status === 200) {
                        // console.log("here", data)
                        setUserData(data)
                        setUI({
                            find_Acc:false,
                            verify_Acc: true,
                            match_OTP:false,
                            Password_Reset:false
                        })
                        // console.log(data)
                         setLoading(false)
                    }else{
                        // console.log("reached else block")
                        toast.error("oops something went wrong!..",{duration: 1500})
                    }
                  
                } catch (error) {
                    // console.log(error)
                    navigator.onLine ? toast.error("Oops something went wrong!!..",{duration: 1500}) :
                    toast.warning("No Internet Connection!!..",{duration: 1500})
                    setLoading(false)
                  }
                  
                  // toast.success("email sent")
                }
                
        setLoading(false)
    }

    const PostForgotPassword = async (e) =>{
        e.preventDefault();
        setLoading(true)

            try {
                const res = await fetch(`${process.env.REACT_APP_BASE_URL}/forgot-password`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    email
                  }),
                });
          
                const data = await res.json();
          
                if (res.status === 422) {
                  // window.alert("Invalid registration ");
                  toast.error(data.message,{duration: 1500});
                  setLoading(false)
                }else {
                  toast.success(data.message,{duration: 1500});
                  setUI({
                    find_Acc:false,
                    verify_Acc: false,
                    match_OTP:true,
                    Password_Reset:false
                })
                  setLoading(false)
                  resendOTP()
                }
              
            } catch (error) {
                // console.log(error)
                toast.error("Oops something went wrong!..",{duration: 1500})
                setLoading(false)
            }

        setLoading(false)   
    }

    const PostMatchOTP = async (e) =>{
        e.preventDefault();
        setLoading(true)
        let otp_input = document.getElementById("otp_input");

        if(OTP?.trim() === ""){
            toast.error("Please Enter OTP")
            showValidate(otp_input)
        }else if (String(OTP.trim()) < 6) {
            toast.error("Please Enter valid OTP")
            showValidate(otp_input)
        } else {
            hideValidate(otp_input);
            
                            try {
                                const res = await fetch(`${process.env.REACT_APP_BASE_URL}/check-otp-match`, {
                                  method: "POST",
                                  headers: {
                                    "Content-Type": "application/json",
                                  },
                                  body: JSON.stringify({
                                    email,OTP
                                  }),
                                });
                          
                                const data = await res.json();
                          
                                if (res.status === 422) {
                                  // window.alert("Invalid registration ");
                                  toast.error(data.message,{duration: 1500});
                                  setLoading(false)
                                }else {
                                  toast.success(data.message,{duration: 1500});
                                  setUI({
                                    find_Acc:false,
                                    verify_Acc: false,
                                    match_OTP:false,
                                    Password_Reset:true
                                })
                                  setLoading(false)
                                }
                              
                            } catch (error) {
                                // console.log(error)
                                toast.error("Oops Something went wrong",{duration: 1500})
                                setLoading(false)
                            }
                
                // toast.success("email sent")
            }
            setLoading(false)  
    }

    // Reset Password
    const PostResetPassword = async (e) => {
        e.preventDefault();
        setLoading(true)
      
          let { Password, C_Password } = editPassword;
          Password = Password?.trim()
          C_Password = C_Password?.trim();
          
          if(Password === "" && C_Password === ""){
            document?.getElementById("pwd").focus()
              // HandelOnBlur("CurrPassword",CurrPassword)
          }
          
          if(Password === C_Password  && !error.Password && !error.C_Password ) {
              // console.log(emailError,"<= error status" )
              try {
                  
                  const res = await fetch(`${process.env.REACT_APP_BASE_URL}/change-pass-otp`, {
                    method: "POST",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                    credentials: "include",
                    body: JSON.stringify({
                        password:Password, cpassword: C_Password, email
                    })
                  });
                  // console.log(res)
                  // console.log(res.body)
                  if (res.status === 400) {
                  //   throw new Error(res.error);
                      toast.error("Oops Something went wrong try Again after some time!!..",{duration: 1500})
                  }else{
                      const data =  await res.json();
                      // console.log("from the checking component==>",data);
                      toast.success(data.message,{duration: 1500})
                      setTimeout(() => {
                          setLoading(false)
                          navigate("/")
                      }, 1000);
                  }
            
                } catch (err) {
                  // console.log(err);
                  toast.error("Oops something went wrong!!..",{duration: 1500})
                //   history.push('/');
                }
              setLoading(false)
          }else{
  
              if(error.C_Password ||  error.Password ){
                  toast.error("Invalid Credentials!!..",{duration: 1500})
              }else{
                  // console.log(error)
                  toast.error("Invalid Credentials!!..",{duration: 1500})
                  // closefun()
                  setLoading(false)
              }         
              
          }
          setLoading(false)
      
    }


    const HandelOnBlur = (name, value) =>{
        // alert(`On Blur value=> ${value}, id=> ${id}`)
    
        let pwd = document?.getElementById("pwd");
        let cpwd = document?.getElementById("cpwd");
    


    //validate Password
    if(name === "Password"){
        if (value?.trim() === ""){
         showValidate(pwd)
         setError({...error, [name] : true})
       }else if(value?.trim()?.length < 6) {
        setError({...error, [name] : true})
         showValidate(pwd)
       }else{
        setError({...error, [name] : false})
         hideValidate(pwd)
 
     }
     }
 
     //validate Password
     if(name === "C_Password"){
        if (value?.trim() === "") {
           showValidate(cpwd)
           setError({...error, [name] : true})
         }else if(value?.trim() !== editPassword?.Password?.trim()) {
           showValidate(cpwd)
           showValidate(pwd)
           setError({...error, [name] : true})
         }else{
           hideValidate(cpwd)
           hideValidate(pwd)
           setError({...error, [name] : false})
       }

    }

    };

useEffect(() => {

}, [UI]);

const resendOTP = () => {
    setMinutes(1);
    setSeconds(30);
  };
   

useEffect(() => {
  
    if(UI.match_OTP){
        const interval = setInterval(() => {
            if (seconds > 0) {
              setSeconds(seconds - 1);
            }
        
            if (seconds === 0) {
              if (minutes === 0) {
                clearInterval(interval);
              } else {
                setSeconds(59);
                setMinutes(minutes - 1);
              }
            }
          }, 1000);
          return () => {
            clearInterval(interval);
          };
    }

  }, [seconds,UI.match_OTP,minutes]);

  return (
    <>

    {
        UI.find_Acc?  

        <div className="container  d-flex align-items-center justify-content-center " style={{height:"90vh",}}>
        <div className="card col-lg-6 col-md-8 col-12">
            <div className="card-header bg-white "  >                    
                <h5 className="card-title fs-5">Find Your Account</h5>
            </div>
            <div className="card-body">
                <p className="card-text fs-6">Please enter your email address to search for your account</p>
                
                <div className="input-group mb-3 fs-5">
                    <input type="text" className="form-control fs-5 rounded-2 f_p_input "
                    name="userID"
                    value={email}
                    onChange={(e)=> setEmail(e.target.value.trim())}
                    placeholder="Please Enter Email ID"
                    autoComplete="false"
                    autoCapitalize="false"
                    autoSave="false"
                    id="InputUserName"/>
                </div>
            </div>
            <div className="card-footer bg-white d-flex justify-content-end">
                    <Link onClick={()=> navigate(-1)} className="btn btn-secondary px-3 me-2">Cancel</Link>
                    <button onClick={PostSearchUser} className="btn btn-primary px-3 ">Search</button>
            </div>
        </div>
        </div>
        :

        UI.verify_Acc ?
        <div className="container-fluid  d-flex align-items-center justify-content-center " style={{height:"90vh",}}>
            <div className="card col-lg-6 col-md-8 col-12" >
                <div className="card-header bg-white "  >                    
                    <h5 className="card-title fs-5">Verify Your Account</h5>
                </div>
                <div className="card-body">
                    <div className='rounded-circle mx-auto' style={{width:"120px", height:"120px"}}>
                        <img src={`/images/profile_pics/${userData[0]?.Profile_pic}`} className='rounded-circle border shadow' alt="" style={{width:"120px", height:"120px"}} />
                    </div>
                    <p className="card-text text-center fs-5 mt-2" style={{fontWeight:"500"}}  > {userData[0]?.firstName} {userData[0]?.lastName} </p>

                </div>
                <div className="card-footer bg-white d-flex justify-content-end">
                        <button onClick={()=> {
                             setUI({
                                find_Acc:true,
                                verify_Acc: false,
                                match_OTP:false,
                                Password_Reset:false
                            })
                        }} className="btn btn-secondary px-3 me-2">Cancel</button>
                        <button onClick={PostForgotPassword} className="btn btn-primary px-3 ">Yes I'm</button>
                </div>
            </div>
        </div>
        :

        UI.match_OTP?
        <div className="container  d-flex align-items-center justify-content-center " style={{height:"90vh",}}>
        <div className="card col-lg-6 col-md-8 col-12">
            <div className="card-header bg-white "  >                    
                <h5 className="card-title fs-5">Verify OTP</h5>
            </div>
            <div className="card-body">
                <p className="card-text fs-6">An OTP is sent to your email ID {email.substring(0,2)}{"*".repeat((email.length)-12)}{email.substring(email.indexOf('@'))}  </p>
                
                <div className="input-group mb-3 fs-5">
                    <input type="number" className="form-control fs-5 rounded-2 f_p_input "
                    name="userID"
                    value={OTP}
                    onChange={(e)=> setOTP(e.target.value)}
                    placeholder="Please Enter OTP"
                    autoComplete="false"
                    autoCapitalize="false"
                    autoSave="false"
                    maxLength="6"
                    id="otp_input"/>
                </div>

                <div className='bg- d-flex align-items-center justify-content-between' >
                    
                    <div className='mt-2'>
                        {seconds > 0 || minutes > 0 ? (
                            <p className='bg-'>
                            Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                            {seconds < 10 ? `0${seconds}` : seconds}
                            </p>
                        ) : (
                            <p>Didn't recieve code?</p>
                        )}
                    </div>


                    <button
                            disabled={seconds > 0 || minutes > 0}
                            style={{
                            color: seconds > 0 || minutes > 0 ? "#DFE3E8" : "#39A7FF",
                            }}
                            className='btn btn-link'
                            onClick={PostForgotPassword}
                        >
                            Resend OTP
                    </button>
                </div>

            </div>
            <div className="card-footer bg-white d-flex justify-content-end">
                    <button onClick={()=> {
                        setUI({
                            find_Acc:false,
                            verify_Acc: true,
                            match_OTP:false,
                            Password_Reset:false
                        })
                    }} className="btn btn-secondary px-3 me-2">Cancel</button>
                    <button onClick={PostMatchOTP} className="btn btn-primary px-3 ">Submit</button>
            </div>
        </div>
        </div>
        :

        UI.Password_Reset?
        <div className="container  d-flex align-items-center justify-content-center " style={{height:"90vh",}}>
        <div className="card col-lg-6 col-md-8 col-12">
            <div className="card-header bg-white "  >                    
                <h5 className="card-title fs-5">Reset Password</h5>
            </div>
            <div className="card-body">
                <p className="card-text fs-6"> </p>
                
                    <div className="mb-3 position-relative input_con ">
                            <div className="d-flex">
                            <span className="  d-flex justify-content-center align-items-center rounded-2  border border-end-0 fs-5" style={{background:"#f8f9fa",padding:"12px"}}>
                                <BiSolidLock/>
                                </span>
                                <input type={showPass?"text":"password"} className="form-control f_p_input fs-5 rounded-2 " 
                                onBlur={() => HandelOnBlur("Password", editPassword.Password)}
                                placeholder="New Password"
                                name="Password"
                                value={editPassword.Password}
                                onChange={inputEvent} 
                                id="pwd"/>
                                <span className="position-absolute  fs-5  px-2  text-dark"  onClick={()=> showPass? setShowPass(false): setShowPass(true)}
                                style={{
                                top:"10%", right:"2%",cursor: "pointer",zindex:"20"
                                }} > {showPass? <IoEyeOffOutline/> : <IoEyeOutline/>} </span>

                                <span className={`error_icon text-danger fs-5 end-0 me-5 ${!error.Password?"d-none":"" }`}
                                data-tooltip-id="my-tooltip"
                                data-tooltip-html="Enter a combination of at least six numbers, letters </br> and punctuation marks (such as ! and &)"
                                data-tooltip-variant="error"
                                > <MdErrorOutline/> </span>
                            </div>

                        </div>

                        <div className="mb-3 position-relative input_con ">
                            <div className="d-flex">
                            <span className="  d-flex justify-content-center align-items-center rounded-2  border border-end-0 fs-5" style={{background:"#f8f9fa",padding:"12px"}}>
                                <BiSolidLock/>
                                </span>
                                <input type={showCPass?"text":"password"} className="form-control f_p_input fs-5 rounded-2 " 
                                onBlur={() => HandelOnBlur("C_Password", editPassword.C_Password)}
                                placeholder="Confirm Password"
                                name="C_Password"
                                value={editPassword.C_Password}
                                onChange={inputEvent} 
                                id="cpwd"/>
                                <span className="position-absolute  fs-5  px-2  text-dark"  onClick={()=> showCPass? setShowCPass(false): setShowCPass(true)}
                                style={{
                                top:"10%", right:"2%",cursor: "pointer",zindex:"20"
                                }} > {showCPass? <IoEyeOffOutline/> : <IoEyeOutline/>} </span>
                                <span className={`error_icon text-danger fs-5 end-0 me-5 ${!error.C_Password?"d-none":"" }`}
                                data-tooltip-id="my-tooltip"
                                data-tooltip-html="New password and confirm </br> password should be same"
                                data-tooltip-variant="error"
                                > <MdErrorOutline/> </span>

                            </div>
                        </div>
            </div>
            <div className="card-footer bg-white d-flex justify-content-end">
                    <button onClick={()=> navigate('/')} className="btn btn-secondary px-3 me-2">Cancel</button>
                    <button onClick={PostResetPassword} className="btn btn-primary px-3 ">Save</button>
            </div>
        </div>
        </div>
        :
        ""

    }
        {
            loading? <Spinners/> : ""
        }
    </>
  )
}

export default ForgotPassword