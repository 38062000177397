import Spinner from 'react-bootstrap/Spinner';
import "./CSS/Spinners.css"

const Spinners = () => {
  return( 
    <>
        <div className='position-fixed z-5 w-100 h-100 top-0 start-0 d-flex justify-content-center align-items-center '
        style={{cursor: "progress",backgroundColor: "rgba(0,0,0,0.1)", zIndex: "550"  }}
        >
            <Spinner animation="border" variant="primary"  />
        </div>
    </>
  
  )
}

export default Spinners   

export const LoadingName = () =>{

  return(
    <>
      <div className=' w-100 d-flex align-items-center justify-content-center' style={{height:'75vh'}}  >
      <span className="loader"></span>
      </div>
    </>
  )
}