import React,{useState, useEffect} from "react";
import "./CSS/Register.css"
import { toast } from 'sonner';
import { useNavigate } from "react-router-dom";
// import HomeNavbar from "../../components/NavbarContainer/HomeNavbar";
// import { loginFunc } from "../../services/Apis";
import { BsFillTelephoneFill, BsFillCameraFill } from "react-icons/bs";
import { BiSolidLock } from "react-icons/bi";
import {profileAvatar} from "../../components/ProfilePictures/ProfilesPicture"
import { GiCheckMark } from "react-icons/gi";
import { MdErrorOutline } from "react-icons/md";
import { IoEyeOffOutline,IoEyeOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Modal from 'react-bootstrap/Modal';
import { Tooltip } from 'react-tooltip'
import Spinners from "../../components/Spinners/Spinners";
import { motion } from "framer-motion";
import { Helmet } from 'react-helmet';


// import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";

const Register = () => {

  const navigate = useNavigate();

  const [showPass, setShowPass] = useState(false);
  const [showCPass, setShowCPass] = useState(false);
  const [dob, setDob] = useState(null);
  const [DOBError, setDOBError] = useState(false);
  const [genderError, setGenderError] = useState(false);
  const [show, setShow] = useState(false);
  const [signUpBtn, setSignUpBtn] = useState(false);
  const [avatar, setAvatar] = useState("default.png");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let [userRegisterData, setUserRegisterData] = useState({
    firstName:"",
    lastName:"",
    email:"",
    number:"",
    Password:"",
    C_Password:"",
    Profile_pic:"",
    DOB: "",
    gender: "",

});

  let [ErrorStatus, setErrorStatus] = useState({
    firstName:false,
    lastName:false,
    email:false,
    number:false,
    Password:false,
    C_Password:false,
    DOB:"",
    gender:"",
});

const inputEvent = (e) => {
    let { name, value } = e.target;
    // console.log(name, value);

    setUserRegisterData({ ...userRegisterData, [name]: value })

    if(name === "gender"){
      setErrorStatus({...ErrorStatus, gender: false})
      setGenderError(false)
    }

}

const hideValidate = (ele) =>{
  ele.style.border = "1px solid gray";
}
const showValidate = (ele) =>{
  ele.style.border = "1px solid red";
}

const isEmail = (emailVal) => {
  let validRegex = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailVal.match(validRegex)
};

const RegisterUser = async (e) => {
    e.preventDefault();
    setSignUpBtn(true)
     
    dob !== null ? userRegisterData.DOB = dob.$D+"/"+(dob.$M+1)+"/"+dob.$y : userRegisterData.DOB = null
    userRegisterData.Profile_pic = avatar

    let { firstName,lastName,email, number,Password,C_Password,Profile_pic,  gender, DOB } = userRegisterData;
    // console.log(typeof(dob),dob)

    firstName = firstName.trim()
    lastName = lastName.trim()
    email = email.trim()
    number = number.trim()
    Password = Password.trim()
    C_Password = C_Password.trim()

    // validation
    if(firstName === "" &&  lastName === "" && email === "" && number === "" && Password === "" && C_Password === "" && gender === "" && DOB === null ){
    
      document.getElementById("f_name").focus()
      toast.error("please fill all the fields.",{duration: 1500})
      setSignUpBtn(false)

    }else if(firstName === "" ||  lastName === "" || email === "" || number === "" || Password === "" || C_Password === "" || gender === "" || DOB === null){

       HandelOnBlur("firstName", firstName)
      HandelOnBlur("lastName", lastName)
      HandelOnBlur("email", email)
      HandelOnBlur("number", number)
      HandelOnBlur("Password", Password)
      HandelOnBlur("C_Password", C_Password)

      ///validate Gender
      if (gender === "") {
        setGenderError(true)
      }else{
        setGenderError(false)
      }

      // validate DOB
      if (DOB === null) {
        setDOBError(true)
      } else {
        setDOBError(false);
      }

      toast.error("Invalid Credentials!!..",{duration: 1500})
      setSignUpBtn(false)

    }else{
      HandelOnBlur("firstName", firstName)
      HandelOnBlur("lastName", lastName)
      HandelOnBlur("email", email)
      HandelOnBlur("number", number)
      HandelOnBlur("Password", Password)
      HandelOnBlur("C_Password", C_Password)

      ///validate Gender
      if (gender === "") {
        setGenderError(true)
      }else{
        setGenderError(false)
      }

      // validate DOB
      if (DOB === null) {
        setDOBError(true)
      } else {
        setDOBError(false);
      }
      // console.log(ErrorStatus)
      let validated = Object.keys(ErrorStatus).every(function(k){ return ErrorStatus[k] === false });

      if(validated){
        let u_email = document.getElementById("user_email");
        let ph_no = document.getElementById("ph_no");
          try {
              const res = await fetch(`${process.env.REACT_APP_BASE_URL}/register`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                credentials:"include",
                body: JSON.stringify({
                  firstName,lastName,email, number,password:Password, gender, DOB, Profile_pic
                }),
              });
        
              const data = await res.json();
        
              if (res.status === 422 || !data) {
                // window.alert("Invalid registration ");
                toast.error("Invalid registration",{duration: 1500});
                setSignUpBtn(false)
                // console.log("Invalid registration ");
              }else if(res.status === 409){
                if(data.data === "email"){
                  showValidate(u_email)
                }
                if(data.data === "number"){
                  showValidate(ph_no)
                }
                toast.error(data.error,{duration: 1500});
                setSignUpBtn(false)
              } 
              else if(res.status === 201){
                toast.success(data?.message,{duration: 1500});
                setTimeout(() => {
                setSignUpBtn(false)
                    navigate("/");
                  }, 2000); 
                setSignUpBtn(false) 
              }
            
          } catch (error) {
              // console.log(error)
              navigator.onLine ? toast.error("Oops something went wrong!!..",{duration: 1500}) :
              toast.warning("No Internet Connection!!..",{duration: 1500})
              setSignUpBtn(false)
          }
                
        }else{
        toast.error("Invalid Credentials!!..",{duration: 1500})
        setSignUpBtn(false)
      }
    }
}

const HandelOnBlur = (name, value) =>{
    // alert(`On Blur value=> ${value}, id=> ${id}`)

    let f_name = document.getElementById("f_name");
    let l_name = document.getElementById("l_name");
    let u_email = document.getElementById("user_email");
    let ph_no = document.getElementById("ph_no");
    let pwd = document.getElementById("pwd");
    let cpwd = document.getElementById("cpwd");

    
    // validate First Name
    if(name === "firstName"){
      if (value.trim() === "") {
        showValidate(f_name)
        setErrorStatus({...ErrorStatus, [name] : true})
        

      }else {
        setErrorStatus({...ErrorStatus, [name] : false})
        hideValidate(f_name)
      }

    }

    // validate First Name
    if(name === "lastName"){
      if (value.trim() === "") {
        showValidate(l_name)
        setErrorStatus({...ErrorStatus, [name] : true})
      }else {
        setErrorStatus({...ErrorStatus, [name] : false})
        hideValidate(l_name)
      }

    }

    //validate email
    if(name === "email"){
       if (value.trim() === "") {
        showValidate(u_email)
        setErrorStatus({...ErrorStatus, [name] : true})
      } else if (!isEmail(value.trim())) {
        showValidate(u_email)
        setErrorStatus({...ErrorStatus, [name] : true})
      } else {
        setErrorStatus({...ErrorStatus, [name] : false})
        hideValidate(u_email);
      }

    }

    //validate Phone
    if(name === "number"){
          if (value.trim() === "") {
            showValidate(ph_no)
            setErrorStatus({...ErrorStatus, [name] : true})
          } else if (value.trim().length !== 10) {
            showValidate(ph_no)
            setErrorStatus({...ErrorStatus, [name] : true})
          } else {
            hideValidate(ph_no);
            setErrorStatus({...ErrorStatus, [name] : false})
          }

    }


    //validate Password
    if(name === "Password"){
       if (value.trim() === ""){
        showValidate(pwd)
        setErrorStatus({...ErrorStatus, [name] : true})
      }else if(value.trim().length < 6) {
        setErrorStatus({...ErrorStatus, [name] : true})
        showValidate(pwd)
      }else{
        setErrorStatus({...ErrorStatus, [name] : false})
        hideValidate(pwd)

    }
    }

    //validate Password
    if(name === "C_Password"){
       if (value.trim() === "") {
          showValidate(cpwd)
          setErrorStatus({...ErrorStatus, [name] : true})
        }else if(value.trim() !== userRegisterData.Password.trim()) {
          showValidate(cpwd)
          showValidate(pwd)
          setErrorStatus({...ErrorStatus, [name] : true})
        }else{
          hideValidate(cpwd)
          hideValidate(pwd)
          setErrorStatus({...ErrorStatus, [name] : false})
      }
    }

    //validate DOB
    if(name === "DOB"){
      console.log("onblur test")

      if (value === null) {
        setDOBError(true)
      } else {
        setDOBError(false);
      }

    }



}

// const animation = {
//   initial: {opacity: 0, x: -100},
//   animate: {opacity: 1, x:0},
//   exit: {opacity:0, x: 100}
// }

useEffect(() => {   
}, [userRegisterData.Profile_pic,ErrorStatus]);

  return (
    <motion.div
    // variants={animation}
    initial="initial"
    animate="animate"
    exit="exit"
    transition={{duration: 0.2}}
    >

      <Helmet>
        <link rel="canonical" href="https://friendsledger.com/register"/>
        <title>Register</title>
      </Helmet>
      <div className='row container-fluid m-0  p-0 overflow-scroll '  >

        <div className=" col-sm-12 " style={{height:"100%",width:"100%",padding:"0px", marginTop:"60px"}}>

        <form className='col-sm-6 col-lg-6 pb-3  px-4 fs-4  rounded-4 m-auto  ' >
          <div className="mb-3 d-flex justify-content-center   " >
            {
              <div className=" rounded-circle profile_pic_con position-relative" style={{height: "120px", width:"120px" }} >
                {/* <img className=" " src={imgPreview? imgPreview :"/images/user-logo.png"} alt="Profile "  width={"120px"} height={"120px"} style={{borderRadius: "50%"}} />   */}
                <img className=" " src={avatar !== ""? "/images/profile_pics/"+avatar :"/images/profile_pics/default.png"} alt="Profile "  width={"120px"} height={"120px"} style={{borderRadius: "50%"}} />  

                  <div className="position-absolute overlay_profile_pic "  onClick={handleShow} >
                     <BsFillCameraFill className="me-2"/> Image
                  </div>
              </div>
            }
            
            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              centered
              keyboard={false}
              scrollable
            >
              <Modal.Header closeButton className="border-bottom-0 pb-0" >
                {/* <Modal.Title>Modal title</Modal.Title> */}
              </Modal.Header>
              <Modal.Body >

                <div className="avatar_options">
                  {
                    
                    profileAvatar.map(ele =>{
                        const {ID, imageName} = ele
                      return(
                          <div key={ID} className="avatar_pic " onClick={()=>{ 
                            setAvatar(imageName) 
                            handleClose()
                            } }  >
                              <img src={`/images/profile_pics/${imageName}`} width="50px" height="50px" alt="" />
                              {
                                avatar === imageName?
                                <div className="selected_avatar"  ><GiCheckMark/></div>
                                : ""
                              }
                          </div>
                      )
                    })

                  }

                </div>

              </Modal.Body>
            </Modal>
           
          </div>

          <div className="row  ">
            <div className="col-sm-12 mb-3 col-lg-6 input_con">
              <input type="text" className="form-control fs-5 rounded-2" 
              onBlur={() => HandelOnBlur("firstName", userRegisterData.firstName)}
              name="firstName"
              value={userRegisterData.firstName}
              onChange={inputEvent}
              autoComplete="false"
              id="f_name"
              placeholder="First name" aria-label="First name" required />
              <span  className={`error_icon text-danger ${!ErrorStatus.firstName?"d-none":"" } fs-5`}
              data-tooltip-id="my-tooltip"
              data-tooltip-content="What's your name?"
              data-tooltip-variant="error"
              > <MdErrorOutline/> </span>
            </div>
            <div className="col-sm-12 mb-3 col-lg-6 input_con">
              <input type="text" className="form-control fs-5 rounded-2" 
              name="lastName"
              onBlur={() => HandelOnBlur("lastName", userRegisterData.lastName)}
              value={userRegisterData.lastName} 
              onChange={inputEvent}
              autoComplete="false"
              id="l_name"
              placeholder="Last name" aria-label="Last name"/>
               <span className={`error_icon text-danger ${!ErrorStatus.lastName?"d-none":"" } fs-5`}
                data-tooltip-id="my-tooltip"
                data-tooltip-content="What's your name?"
                data-tooltip-variant="error"
               > <MdErrorOutline/> </span>
            </div>
          </div>

          <div className="input-group mb-3 fs-5 input_con">
            <span className="input-group-text">
              @
            </span>
              <input type="email" className="form-control fs-5 rounded-2 "
              onBlur={() => HandelOnBlur("email", userRegisterData.email)}
              name="email"
               value={userRegisterData.email}
               onChange={inputEvent}
               placeholder="email"
               autoComplete="false"
               id="user_email"/>
                <span className={`error_icon text-danger fs-5 end-0 me-1 ${!ErrorStatus.email?"d-none":"" }`} 
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="please enter your valid email"
                  data-tooltip-variant="error"
                > <MdErrorOutline/> </span>
          </div>

          <div className="input-group mb-3 fs-5 input_con">
            <span className="input-group-text">
              <BsFillTelephoneFill/>
            </span>
              <input type="text" className="form-control fs-5 rounded-2 "
               onBlur={() => HandelOnBlur("number", userRegisterData.number)}
              name="number"
               value={userRegisterData.number}
               onChange={inputEvent}
               autoComplete="false"
               placeholder="Phone number"
               id="ph_no"/>
               <span className={`error_icon text-danger fs-5 end-0 me-1 ${!ErrorStatus.number?"d-none":"" }`}
               data-tooltip-id="my-tooltip"
               data-tooltip-content="please enter your valid phone number"
               data-tooltip-variant="error"
               > <MdErrorOutline/> </span>
          </div>

          <div className="mb-3 position-relative input_con ">
            <div className="d-flex">
              <span className="  d-flex justify-content-center align-items-center rounded-2  border border-end-0 fs-5" style={{background:"#f8f9fa",padding:"12px"}}>
                  <BiSolidLock/>
                </span>
                <input type={showPass?"text":"password"} className="form-control fs-5 rounded-2 " 
                 onBlur={() => HandelOnBlur("Password", userRegisterData.Password)}
                placeholder="New Password"
                name="Password"
                value={userRegisterData.Password}
                onChange={inputEvent} 
                autoComplete="false"
                id="pwd"/>
                <span className="position-absolute  fs-5  px-2  text-dark"  onClick={()=> showPass? setShowPass(false): setShowPass(true)}
                style={{
                  top:"10%", right:"2%",cursor: "pointer",zindex:"20"
                }} > {showPass? <IoEyeOffOutline/> : <IoEyeOutline/>} </span>

                <span className={`error_icon text-danger fs-5 end-0 me-5 ${!ErrorStatus.Password?"d-none":"" }`}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-html="Enter a combination of at least six numbers, letters </br> and punctuation marks (such as ! and &)"
                  data-tooltip-variant="error"
                > <MdErrorOutline/> </span>
            </div>

          </div>

          <div className="mb-3 position-relative input_con ">
            <div className="d-flex">
              <span className="  d-flex justify-content-center align-items-center rounded-2  border border-end-0 fs-5" style={{background:"#f8f9fa",padding:"12px"}}>
                  <BiSolidLock/>
                </span>
                <input type={showCPass?"text":"password"} className="form-control fs-5 rounded-2 " 
                 onBlur={() => HandelOnBlur("C_Password", userRegisterData.C_Password)}
                placeholder="Confirm Password"
                name="C_Password"
                value={userRegisterData.C_Password}
                onChange={inputEvent} 
                autoComplete="false"
                id="cpwd"/>
                <span className="position-absolute  fs-5  px-2  text-dark"  onClick={()=> showCPass? setShowCPass(false): setShowCPass(true)}
                style={{
                  top:"10%", right:"2%",cursor: "pointer",zindex:"20"
                }} > {showCPass? <IoEyeOffOutline/> : <IoEyeOutline/>} </span>
                <span className={`error_icon text-danger fs-5 end-0 me-5 ${!ErrorStatus.C_Password?"d-none":"" }`}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="New password and confirm password should be same"
                  data-tooltip-variant="error"
                > <MdErrorOutline/> </span>

            </div>
          </div>

          {/* Gender */}
          <div className="mb-3">
          <label  className="form-label fs-5 pe-5 input_con">Gender
          <span className={`error_icon text-danger fs-5 ${genderError?"":"d-none"} `}  style={{top:"-5%"}}
            data-tooltip-id="my-tooltip"
            data-tooltip-content="Please choose a gender."
            data-tooltip-variant="error"
          > <MdErrorOutline/> </span>
          </label>
          
          <div className="gender_container d-flex justify-content-between align-items-center flex-lg-row ">

            <div className={` ${genderError? "border-danger" : ""} inner_gen_con rounded form-check border fs-5 p-0 px-3  d-flex justify-content-between align-items-center py-1`}
            style={{width:"200px"}}
            >
              <label className="form-check-label " htmlFor="male-radio">
                Male
              </label>
              <input className="form-check-input "  onChange={inputEvent} type="radio" value="Male" name="gender" id="male-radio"/>
            </div>
            <div className={`${genderError? "border-danger" : ""} inner_gen_con rounded form-check border fs-5 p-0 px-3  d-flex justify-content-between py-1 align-items-center`}
            style={{width:"200px"}}
            >
              <label className="form-check-label " htmlFor="female-radio">
                Female
              </label>
              <input className="form-check-input " onChange={inputEvent} type="radio" value="Female" name="gender" id="female-radio" />
            </div>
            <div className={` ${genderError? "border-danger" : ""} inner_gen_con rounded form-check border fs-5 p-0 px-3  d-flex justify-content-between py-1 align-items-center`}
            style={{width:"200px"}}
            >
              <label className="form-check-label " htmlFor="others-radio">
                Others
              </label>
              <input className="form-check-input " onChange={inputEvent} type="radio" value="Others" name="gender" id="others-radio" />
            </div>

          </div>
          </div>
          {/* DOB */}
          <div className="mb-3   ">
          <label  className="form-label fs-5 input_con pe-5">Date of Birth
            <span className={`error_icon text-danger fs-5 ${DOBError? "" : "d-none"} `}  style={{top:"-5%"}}
            data-tooltip-id="my-tooltip"
            data-tooltip-content="please provide your correct birth date"
            data-tooltip-variant="error"
            > <MdErrorOutline/> </span>
          </label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
            <DatePicker
              className="col-lg-12 "
              onChange={(newValue) => {
                setDob(newValue)
                setDOBError(false)
                ErrorStatus.DOB=false
              }} 
              
              value={dob}
              openTo="year"
              views={['year', 'month', 'day']}
              format="DD-MM-YYYY"
              disableFuture
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: `${DOBError? 'red':'grey'}`,
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgb(206, 206, 206)',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'rgb(206, 206, 206)',
                  },
                },
              }}
              slotProps={{ textField: { size: 'small' } }}
            />
            </DemoContainer>
          </LocalizationProvider>
          </div>

          <div className="d-grid mb-2">
            <button type="submit" onClick={RegisterUser} className={`btn btn-success rounded-2 fs-5 ${signUpBtn? "disabled" : ""} `}>Sign Up</button>
          </div>
          <p className="fs-6 text-center mt-3" > Already have an account?  <Link className="text-primary text-decoration-none" to="/">Sign In</Link> </p>
        </form>

        </div>
            {
              signUpBtn?<Spinners />:""
            }
      </div>
      <Tooltip id="my-tooltip" className="tooltip_style" />
    </motion.div>
  )
}

export default Register
