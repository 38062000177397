import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getStickyNotes = createAsyncThunk("getStickyNotes", async (id) =>{
    try {
        // console.log("shopping ledger called", id)
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}/sticky-notes-data/${id}`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`
          },
          credentials: "include",
        });
  
        if (!res.status === 200) {
        //   throw new Error(res.error);
            return res.error
        }else{
          return await res.json();
        }
        
        // console.log(data)
        // console.log("from the checking component==>",data);
  
  
  
      } catch (err) {
        console.log("error => ",err);
        return err
      //   history.push('/');
      }
})

const stickyNotesSlice = createSlice({
    name: "StickyNotes",
    initialState: {
        loading: false,
        data:[],
        error:''
        },

    extraReducers: (builder) => {
        builder
          .addCase(getStickyNotes?.pending, (state) => {
            state.loading = true;
          })
          .addCase(getStickyNotes?.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
          })
          .addCase(getStickyNotes?.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
          });
      },

})

// console.log(shoppingLedgerSlice.actions);

export default  stickyNotesSlice.reducer

// export const { } = stickyNotesSlice.actions  